/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Spinner, Modal, Button } from 'react-bootstrap';
//import PageHeader from 'components/common/PageHeader';
import 'pages/patient/report-browser.scss';
import { Helmet } from 'react-helmet';
//import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import userService from 'services/user-service';
import OrderService from 'services/order-service';
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import NYBloodCenterLogo from 'pages/orders/NewYorkBloodCenterLogo.png';
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import Enum from 'common/enum';
import Select from 'react-select';
import orderService from 'services/order-service';


const SangerDraftReport = (oid) => {
  let params = useParams();
  var profile = JSON.parse(localStorage.getItem(Enum.CACHED_USER_PROFILE));
  //const orderId = params.orderId;
  const [formData, setFormData] = useState ({
    formCaseHistory: '',
    formDetectedVariants: '',
    formInferredGenotype:'',
    formPredictedPhenotype:'',
    formComments:''
  });
  const [orderDetails, setOrderDetails] = useState([]);
  const [sangerTestResults, setSangerTestResults] = useState([]);
  const [orderId, setOrderId] = useState(oid ? params.orderId : oid);
  const [orderStatus, setOrderStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [approvedBy, setApprovedBy] = useState();
  const [approvedSign, setApprovedSign] = useState();
  const [approvedTitle, setApprovedTitle] = useState();
  const [file, setFile] = useState();
  const [users, setUsers] = useState([]);
  const [validatePreparedBy, setValidatePreparedBy] = useState(true);
  const [hasOrderId, setHasOrderId] = useState(true);
  const [interpretation, setInterpretation] = useState();
  //const [autosave, setAutosave] = React.useState(false);
  const [changes, setChanges] = React.useState(false);
  const [sampleID1, setSampleID1] = React.useState();
  const [sampleID2, setSampleID2] = React.useState();
  const [recieptDate, setRecieptDate] = React.useState(new Date());
  const [patientDOB, setPatientDOB] = React.useState(new Date());
  const [raceEthnicity, setRaceEthnicity] = React.useState();
  const [serviceRequested, setServiceRequested] = React.useState();
  const [testPerformed, setTestPerformed] = React.useState();
  const [isApproved, setIsApproved] = React.useState(true);
  const [showConfirmTarget, setShowConfirmTarget] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [divisionName, setDivisionName] = useState('');
  const [divisionAddress, setDivisionAddress] = useState('');
  const [divisionDirector, setDivisionDirector] = useState('');
  const [divisionCLIANumber, setDivisionCLIANumber] = useState('');
  const [divisionEmail, setDivisionEmail] = useState('');
  const [fixedComments, setFixedComments] = useState([]);
  const [selectedFixedComments, setSelectedFixedComments] = useState([]);
  const [fixedGenotype, setFixedGenotype] = useState([]);
  const [selectedFixedGenotype, setSelectedFixedGenotype] = useState([]);
  const [fixedPhenotype, setFixedPhenotype] = useState([]);
  const [selectedFixedPhenotype, setSelectedFixedPhenotype] = useState([]);
  useEffect(() => {
    if(orderId)
    {      
      onClickGetTestResult();
    }
    userService.getAllActiveUsersAsync().then((results) =>{
      
      results.forEach(item => {
        renameKey(item, 'userId', 'value');
        renameKey(item, 'fullName', 'label');
      });
      setUsers(results);
    });
    OrderService.getTestingDivisions().then((results)=>{
      results.forEach(item => {
        renameKey(item, 'testingDivisionId', 'value');
        renameKey(item, 'divisionCode', 'label');
      });
      setDivisions(results);
    });
    console.log(changes);
  }, []);

//   useEffect(() => {
//     const autosave = setInterval(function() {
//       //onClickPrepare();
//       setAutosave(true);
//     }, 60 * 10000); // runs every minute
//     return () => {
//         setAutosave(false); // turn autosave off
//         clearInterval(autosave); // clear autosave on dismount
//     };
//   }, []);

// useEffect(() => {
//     if (autosave) {
//       onClickPrepareAuto();
//         setAutosave(false); // toggle autosave off
//     }
// }, [autosave, changes, onClickPrepare]);

  const onChangeTestingDivision = (e) => {
    var division = divisions.filter(a => a.value == e.value);
    
    if(division.length > 0)
    {
      setSelectedDivision(division[0]);

      setDivisionName(division[0].divisionName);
      setDivisionAddress(division[0].divisionAddress);
      setDivisionDirector(division[0].divisionDirector);
      setDivisionCLIANumber(division[0].divisionCLIANumber);
      setDivisionEmail(division[0].divisionEmail);
    }
};

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  const onClickSave= () => {
    //orderDetails.orderId
    var name = sampleID1.split(' ');
    var firstName = '';
    var middleName = '';
    var lastName ='';
    if(name.length > 2)
    {
      firstName = name[0];
      middleName = name[1];
      lastName = name[2];
    }
    else
    {
      firstName = name[0];
      lastName = name[1];
    }
    var sangerTestResultSave = {
        sangerInterpretId: sangerTestResults.sangerInterpretId,
        orderId : orderDetails.orderId,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        sampleID2 : sampleID2,
        recieptDate : recieptDate,
        patientDOB : patientDOB,
        raceEthnicity : raceEthnicity,
        serviceRequested : serviceRequested,
        testPerformed : testPerformed,
        manumber: sangerTestResults.manumber, 
        finalCaseHistory: formData.formCaseHistory,
        finalDetectedVariants: formData.formDetectedVariants,
        finalInferredGenotype: formData.formInferredGenotype,
        finalPredictedPhenotype: formData.formPredictedPhenotype,
        finalComments: formData.formComments
    };

    OrderService.UpdateSangerTestReult(sangerTestResultSave);
  };

  const onChangeOrderId= (e) => {
    setOrderId(e.target.value);
  };

  const onClickGetTestResult = () => {
    if (orderId) {
      setHasOrderId(true);
    } else {
      setHasOrderId(false);
      return false;
    }
    setIsLoading(true);
    OrderService.FetchOrderDetailsById(orderId).then((order)=> {
      setOrderId(order.orderId);
      setOrderDetails(order);
      setSampleID1((order.patientFirstName ? order.patientFirstName : '') + (order.patientMiddleName ? order.patientMiddleName : ' ') + (order.patientLastName ? order.patientLastName : ''));
      setSampleID2(order.mrNumber);
      setRecieptDate(order.createdOn);
      setPatientDOB(order.patientDOB);
      setServiceRequested(order.testRequest);
      setTestPerformed(order.testPerformed);
      setRaceEthnicity(order.patientEthnicity);
      setOrderStatus(order.orderStatusId);
    
    var interpretationPromise = OrderService.getSangerInterpretation(order.orderId);
    var testResultPromise = OrderService.fetchSangerTestResultDetails(order.orderId);
    var genDetailsPromise = orderService.getSangerResult(order.orderId);
    Promise.all([interpretationPromise, testResultPromise, genDetailsPromise]).then(([interpretationH, testResult, genDetails]) => {

      var division = divisions.filter(a => a.value == interpretationH.testingDivisionId);

      genDetails.vCom.forEach((item) => {
        setFixedComments(current => [...current, {'label' : item, 'value' : item}])
        //renameKey(item, index, 'value');
        //renameKey(item, 'vCom', 'label');
      });
      //setFixedComments(genDetails.vCom);

      genDetails.vDNA.forEach((item) => {
        setFixedGenotype(current => [...current, {'label' : item, 'value' : item}])
      });

      genDetails.vPro.forEach((item) => {
        setFixedPhenotype(current => [...current, {'label' : item, 'value' : item}])
      });

      if(division.length > 0)
      {
        setSelectedDivision(division[0]);
        setDivisionName(division[0].divisionName);
        setDivisionAddress(division[0].divisionAddress);
        setDivisionDirector(division[0].divisionDirector);
        setDivisionCLIANumber(division[0].divisionCLIANumber);
        setDivisionEmail(division[0].divisionEmail);
      }
      
      setInterpretation(interpretationH);
      setSangerTestResults(testResult);
      setFormData({
        formCaseHistory: testResult.finalCaseHistory,
        formDetectedVariants: testResult.finalDetectedVariants,
        formInferredGenotype:testResult.finalInferredGenotype,
        formPredictedPhenotype:testResult.finalPredictedPhenotype,
        formComments:testResult.finalComments
      });

      var user = users.find(item => item.value === interpretationH.reportPreparedBy);
      setSelectedUser(user);

      if(interpretationH.reportPreparedBy != null)
      {
      userService.getIGSignature(interpretationH.reportPreparedBy)
      .then(blob => {       
        if (blob) { 
          setFile(URL.createObjectURL(blob));
        }
      });     
    }
    if(order.orderStatusId == 4)
    {     
      userService.getIGSignature(interpretationH.reportApprovedBy)
        .then(blob => {       
          if (blob) { 
            setApprovedSign(URL.createObjectURL(blob));
          }
      });

      var approverUser = users.find(item => item.value === interpretationH.reportApprovedBy);
      setApprovedBy(approverUser && approverUser.label);
      setApprovedTitle(approverUser && approverUser.title);
    }
      console.log(testResult);
      setIsLoading(false);
  }).catch(error => {

    console.log(error);
    setOrderStatus(0);
    setHasOrderId(false);
    setIsLoading(false);
  });

  });
  };

  const onChangePreparedBy = (e) => {    
    //userService.getUser(e.value).then(setSelectedUser);    
    var user = users.find(item => item.value === e.value);
    setSelectedUser(user);
    
    userService.getIGSignature(e.value)
      .then(blob => {       
        if (blob) { 
          setFile(URL.createObjectURL(blob));
        }
    });
  };

  const onClickDraftApprove= () => {
    setShowConfirmTarget(true);
  }
  const onClickApprove= () => {
    if (selectedUser) {
      setValidatePreparedBy(true);
    } else {
      setValidatePreparedBy(false);
      return false;
    }
    //orderDetails.orderId
    //var testResultTest = { OrderId : orderDetails.orderId, TestName: '', TestResult: '',  Comments : comments || genTypeResults[0].comments , Notes : notes || genTypeResults[0].notes};
    OrderService.updateApproveSangerTestResult(orderId, selectedUser.value, profile.userId)
    .then(response => {
      setOrderStatus(response.result.orderStatus);
      userService.getIGSignature(response.result.reportApprovedBy)
        .then(blob => {       
          if (blob) { 
            setApprovedSign(URL.createObjectURL(blob));
          }
      });
      var approverUser = users.find(item => item.value === response.result.reportApprovedBy);
      setApprovedBy(approverUser && approverUser.label);
      setApprovedTitle(approverUser && approverUser.title);
    })
    .then(() => {      
      toast(ConfirmationMessage(`Sanger Test Result`, `Draft Report Approved Successfully!`, "bg-success", "faCheckCircle"), {
        autoClose: true,
        //className: 'transparent',
        //style: {{background-color: transparent !important}},
        position: 'bottom-right',
        hideProgressBar: true,
        closeButton: false,
      });
    });
    // .then(() => {
    //   setInterpretation({ heainterpretId:0,
    //     orderId:0,0
    //     reportPreparedBy:null,
    //     reportApprovedBy:null});
    // });
  };

  const onSampleId1Change = (e) => {
    setSampleID1(e.target.value)
    setChanges(true);
  };
  const onSampleId2Change = (e) => {
    setSampleID2(e.target.value)
    setChanges(true);
  };
  const onRecieptDateChange = (e) => {
    setRecieptDate(e.target.value)
    setChanges(true);
  };
  const onPatientDOBChange = (e) => {
    setPatientDOB(e.target.value)
    setChanges(true);
  };
  const onRaceEthnicityChange = (e) => {
    setRaceEthnicity(e.target.value)
    setChanges(true);
  };
  const onServiceRequestedChange = (e) => {
    setServiceRequested(e.target.value)
    setChanges(true);
  };
  const onTestPerformedChange = (e) => {
    setTestPerformed(e.target.value)
    setChanges(true);
  };

  const onClickPrepare= () => {
    if (selectedUser) {
      setValidatePreparedBy(true);
    } else {
      setValidatePreparedBy(false);
      return false;
    }

    onClickSave();
    //orderDetails.orderId
    //var testResultTest = { OrderId : orderDetails.orderId, TestName: '', TestResult: '',  Comments : comments || genTypeResults[0].comments , Notes : notes || genTypeResults[0].notes};
    OrderService.updatePreparedBySangerTestResult(orderId, selectedUser.value, selectedDivision.value).then(() => {      
      toast(ConfirmationMessage(`Sanger Test Result`, `Draft Report Saved Successfully!`, "bg-success", "faCheckCircle"), {
        autoClose: true,
        //className: 'transparent',
        //style: {{background-color: transparent !important}},
        position: 'bottom-right',
        hideProgressBar: true,
        closeButton: false,
      });
    })
    setIsApproved(false);
  };

  const onClickTargetCancel = () => setShowConfirmTarget(false);
  const onClickTargetOk = () => { setShowConfirmTarget(false); onClickApprove();}
  // const onClickPrepareAuto= () => {
  //   onClickSave();
  //   //orderDetails.orderId
  //   //var testResultTest = { OrderId : orderDetails.orderId, TestName: '', TestResult: '',  Comments : comments || genTypeResults[0].comments , Notes : notes || genTypeResults[0].notes};
  //   OrderService.updatePreparedBySangerTestResult(orderId, selectedUser.value).then(() => {      
  //     toast(ConfirmationMessage(`Sanger Test Result`, `Draft Report Saved Successfully!`, "bg-success", "faCheckCircle"), {
  //       autoClose: true,
  //       //className: 'transparent',
  //       //style: {{background-color: transparent !important}},
  //       position: 'bottom-right',
  //       hideProgressBar: true,
  //       closeButton: false,
  //     });
  //   })
  // };

  return (
    <>
       <Helmet>
        <title>Sanger Draft Report</title>
      </Helmet>
     {/* <PageHeader title="DraftReport" className="mb-2" col="12">
      <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                Genotype Report
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
      </PageHeader> */}
      <Card className="h-100">
      <Card.Header>
      <Row className="mb-2 g-2">
      <Form.Group as={Col} lg={4} controlId="orderId">
        {/* <Form.Label>
        Order Id:
        </Form.Label> */}
        <Form.Control
            className={hasOrderId?'':'validation-error'}
            type="text"
            placeholder="Order Id"
            //value={orderId}
            //name="patientLastName"
            onChange={(e) => onChangeOrderId(e)}
        />
        {!hasOrderId && (
                    <span className="validation-error-msg">
                      Please enter the valid Order Id.
                    </span>
                  )}
        </Form.Group>
        <Form.Group as={Col} lg={4} controlId="reportDate">
        <div className="text-start">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={() => onClickGetTestResult()}
                value="GetTestResult"
                //type="submit"
                //disabled={disable}
              >
                <FontAwesomeIcon /> Get Test Result
              </button>
            </div>
        </Form.Group>
        {/* <Form.Group as={Col} lg={4} controlId="interpretation">
        <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={onClickInterpretation}
                value="Save"
                //type="submit"
                //disabled={disable}
              >
                <FontAwesomeIcon /> Start Interpretation
              </button>
            </div>

        </Form.Group> */}
        </Row>
        {isLoading ? <div className='text-center'><Spinner animation="border" /></div> : <div></div>} 
      <Row className="mb-2 g-2">
      <Form.Group as={Col} lg={8} controlId="patientFirstName">
        <Form.Label> <span style={{ fontSize: '1.5rem' }}>Sanger Sequencing Report </span> </Form.Label>
      </Form.Group>
      <Form.Group as={Col} lg={4} controlId="patientFirstName">
          <div className="text-end">
            <img src={NYBloodCenterLogo} alt="Logo" />
          </div>
      </Form.Group>
      
    </Row>
      <Row className="mb-2 g-2">
        <Form.Group as={Col} lg={8} controlId="patientFirstName">
        <Form.Label>
        Institution:
        </Form.Label>
        <Form.Control
            disabled={true}
            type="text"
            placeholder="Institution"
            value={orderDetails.institution}
            
        />
        </Form.Group>
        <Form.Group as={Col} lg={4} controlId="collectionDate">
        <Form.Label>
        Collection Date:
        </Form.Label>
        <Form.Control
            disabled={true}
            type="text"
            placeholder="Collection Date"
            value={orderDetails.collectionDate ? moment(orderDetails.collectionDate).format('MM-DD-YYYY') : ''}
            
        />
        </Form.Group>
    </Row>
        <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="address">
            <Form.Label>
            Address:
            </Form.Label>
            <Form.Control
                disabled={true}
                type="text"
                placeholder="Address"
                value={orderDetails.address}
                //name="patientFirstName"
                //onChange={(e) => onFirstNameChange(e)}
            />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="receiptDate">
            <Form.Label>
            Receipt Date:
            </Form.Label>
            <Form.Control
                disabled={false}
                type="text"
                placeholder="MM-DD-YYYY"
                value={recieptDate ? moment(recieptDate).format('MM-DD-YYYY') : ''}
                //name="patientLastName"
                onChange={(e) => onRecieptDateChange(e)}
            />
            </Form.Group>
        </Row>

        <Row className="mb-2 g-2">
        <Form.Group as={Col} lg={8} controlId="sampleID1">
        <Form.Label>
        SampleID#1:
        </Form.Label>
        <Form.Control
            disabled={false}
            type="text"
            placeholder="SampleID1"
            value={sampleID1}
            //name="patientFirstName"
            onChange={(e) => onSampleId1Change(e)}
        />
        </Form.Group>
        <Form.Group as={Col} lg={4} controlId="order">
        <Form.Label>
        Order#:
        </Form.Label>
        <Form.Control
            disabled={true}
            type="text"
            placeholder="Order"
            value={orderDetails.bloodHubOrderId}
            //name="patientFirstName"
            //onChange={(e) => onFirstNameChange(e)}
        />
        </Form.Group>
    </Row>

    <Row className="mb-2 g-2">
    <Form.Group as={Col} lg={4} controlId="sampleID2">
        <Form.Label>
        SampleID#2:
        </Form.Label>
        <Form.Control
            disabled={false}
            type="text"
            placeholder="SampleID2"
            value={sampleID2}
            //name="patientLastName"
            onChange={(e) => onSampleId2Change(e)}
        />
        </Form.Group>
        <Form.Group as={Col} lg={4}>
          <Form.Label>
          Testing Division:
          </Form.Label>
          <Select
            //className={validatePreparedBy?'':'validation-error'}
            closeMenuOnSelect={true}
            options={divisions}
            placeholder='Select...'
            //isDisabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
            //classNamePrefix="react-select"
            value={selectedDivision}
            onChange={e => onChangeTestingDivision(e)}
          />
          {/* {!validatePreparedBy && (
            <span className="validation-error-msg">
              Please select preparedBy.
            </span>
          )} */}
          </Form.Group>
        <Form.Group as={Col} lg={4} controlId="ma">
        <Form.Label>
        MA#:
        </Form.Label>
        <Form.Control
            disabled={true}
            type="text"
            placeholder="MA"
            value={orderDetails.maNumber}
            //name="patientFirstName"
            //onChange={(e) => onFirstNameChange(e)}
        />
        </Form.Group>
    </Row>

    <Row className="mb-2 g-2">
        <Form.Group as={Col} lg={4} controlId="raceEthnicity">
        <Form.Label>
        Race/Ethnicity:
        </Form.Label>
        <Form.Control
            disabled={false}
            type="text"
            placeholder="Race/Ethnicity"
            value={raceEthnicity}
            //name="patientFirstName"
            onChange={(e) => onRaceEthnicityChange(e)}
        />
        </Form.Group>
        <Form.Group as={Col} lg={4} controlId="gender">
        <Form.Label>
        Gender:
        </Form.Label>
        <Form.Control
            disabled={true}
            type="text"
            placeholder="Gender"
            value={orderDetails.patientGender}
            //name="patientFirstName"
            //onChange={(e) => onFirstNameChange(e)}
        />
        </Form.Group>
        <Form.Group as={Col} lg={4} controlId="dob">
        <Form.Label>
        DOB:
        </Form.Label>
        <Form.Control
            disabled={false}
            type="text"
            placeholder="MM-DD-YYYY"
            value={patientDOB ? moment(patientDOB).format('MM-DD-YYYY') : ''}
            //name="patientFirstName"
            onChange={(e) => onPatientDOBChange(e)}
        />
        </Form.Group>
    </Row>
    <Row className="mb-2 g-2">
              <Form.Label>Case History:</Form.Label>
              {/* <textarea
                value={notes}
                onChange={() => {onNotesChange}}
              /> */}
               <Form.Control
                  //isInvalid={!validateName}
                  as="textarea" rows="3"
                 // type="text"
                  //placeholder="Test Performed"
                  //defaultValue={sangerTestResults.length > 0 ? sangerTestResults[0].notes : ''}
                  value={formData.formCaseHistory}
                  disabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                  onChange={(e)=>{setFormData({...formData, formCaseHistory: e.target.value});setChanges(true);}}
              />
            </Row>

    <Row className="mb-2 g-2">
    <Form.Group as={Col} lg={8} controlId="testRequested">
        <Form.Label>
        Service Requested:
        </Form.Label>
        <Form.Control
            disabled={false}
            type="text"
            placeholder="Service Requested"
            value={serviceRequested}
            //name="patientLastName"
            onChange={(e) => onServiceRequestedChange(e)}
        />
        </Form.Group>
        </Row>
        <Row className="mb-2 g-2">
        <Form.Group as={Col} lg={8} controlId="testPerformed">
        <Form.Label>
        Test(s) Performed:
        </Form.Label>
        <Form.Control
            disabled={false}
            type="text"
            placeholder="Test Performed"
            value={testPerformed}
            //name="patientFirstName"
            onChange={(e) => onTestPerformedChange(e)}
        />
        </Form.Group>
    </Row>
    <Form.Label>
        RESULTS:
    </Form.Label>
    <Row className="mb-2 g-2">
              <Form.Label>Detected variants:</Form.Label>
              {/* <textarea
                value={comments}
                onChange={(e) => onCommentsChange(e)}
              /> */}
              <Form.Control
                  //isInvalid={!validateName}
                  as="textarea" rows="3"
                  type="text"
                  //placeholder="Test Performed"
                  //defaultValue={sangerTestResults.length > 0 ? sangerTestResults[0].comments : ''}
                  value={formData.formDetectedVariants}
                  disabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                  onChange={(e)=>{setFormData({...formData, formDetectedVariants: e.target.value});setChanges(true);}}
              />
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={6}>
                <Form.Label>
                Fixed Genotype:
                </Form.Label>
                <Select
                  //className={validatePreparedBy?'':'validation-error'}
                  isMulti
                  closeMenuOnSelect={false}
                  options={fixedGenotype}
                  placeholder='Select...'
                  //isDisabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                  //classNamePrefix="react-select"
                  value={selectedFixedGenotype}
                  //onChange={e => onChangeFixedComments(e)}
                  onChange={(value)=>{
                    setSelectedFixedGenotype(value);
                    setFormData({...formData, formInferredGenotype: value.map(function(data) {
                              return (
                                data.value
                              )
                            })});
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6}>
              <Form.Label>Inferred Genotype:</Form.Label>
              {/* <textarea
                value={comments}
                onChange={(e) => onCommentsChange(e)}
              /> */}
              <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  //placeholder="Test Performed"
                  //defaultValue={sangerTestResults.length > 0 ? sangerTestResults[0].notes : ''}
                  value={formData.formInferredGenotype}
                  disabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                  onChange={(e)=>{setFormData({...formData, formInferredGenotype: e.target.value});setChanges(true);}}
              />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={6}>
                <Form.Label>
                Fixed Phenotype:
                </Form.Label>
                <Select
                  //className={validatePreparedBy?'':'validation-error'}
                  isMulti
                  closeMenuOnSelect={false}
                  options={fixedPhenotype}
                  placeholder='Select...'
                  //isDisabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                  //classNamePrefix="react-select"
                  value={selectedFixedPhenotype}
                  //onChange={e => onChangeFixedComments(e)}
                  onChange={(value)=>{setSelectedFixedPhenotype(value);
                    setFormData({...formData, formPredictedPhenotype: value.map(function(data) {
                              return (
                                data.value
                              )
                            })});
                          }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6}>
              <Form.Label>Predict. Phenotype:</Form.Label>
              {/* <textarea
                value={notes}
                onChange={() => {onNotesChange}}
              /> */}
               <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  //placeholder="Test Performed"
                  //defaultValue={sangerTestResults.length > 0 ? sangerTestResults[0].notes : ''}
                  value={formData.formPredictedPhenotype}
                  disabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                  onChange={(e)=>{setFormData({...formData, formPredictedPhenotype: e.target.value});setChanges(true);}}
              />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={12}>
            <Form.Label>
            Fixed Comments:
            </Form.Label>
            <Select
              //className={validatePreparedBy?'':'validation-error'}
              closeMenuOnSelect={true}
              options={fixedComments}
              placeholder='Select...'
              //isDisabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
              //classNamePrefix="react-select"
              value={selectedFixedComments}
              //onChange={e => onChangeFixedComments(e)}
              onChange={(e)=>{setSelectedFixedComments({'label' : e.value, 'value' : e.value}); setFormData({...formData, formComments: e.value});}}
            />
            </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Label>Comments:</Form.Label>
              {/* <textarea
                value={comments}
                onChange={(e) => onCommentsChange(e)}
              /> */}
              <Form.Control
                  //isInvalid={!validateName}
                  as="textarea" rows="3"
                  type="text"
                  //placeholder="Test Performed"
                  //defaultValue={sangerTestResults.length > 0 ? sangerTestResults[0].comments : ''}
                  value={formData.formComments}
                  //name="patientFirstName"                  
                  disabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                  onChange={(e)=>{setFormData({...formData, formComments: e.target.value});}}
              />
            </Row>
           

            
            {/* <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={() => onClickSave()}
                value="Save"
                //type="submit"
                disabled={orderStatus == 4 ? true : false}
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
            </div> */}

            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4}>
                  <Form.Label>
                  Prepared By:
                  </Form.Label>
                  <Select
                    className={validatePreparedBy?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={users}
                    placeholder='Select...'
                    isDisabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                    //classNamePrefix="react-select"
                    value={orderStatus == 4 && interpretation ? users.filter(a => a.value == interpretation.reportPreparedBy) : selectedUser}
                    onChange={e => onChangePreparedBy(e)}
                  />
                  {!validatePreparedBy && (
                    <span className="validation-error-msg">
                      Please select preparedBy.
                    </span>
                  )}                
                  </Form.Group>
                  <Form.Group as={Col} lg={4}></Form.Group>
                  <Form.Group as={Col} lg={4}>  
                  <Form.Label>
                  Approved By:
                  </Form.Label>
                  {/* <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="MA"
                      value={genTypeResults.length > 0 ? genTypeResults[0].manumber : 0}
                      //name="patientFirstName"
                      //onChange={(e) => onFirstNameChange(e)}
                  /> */}
                  </Form.Group>
              </Row>
            <Row className="mb-2 mt-2 g-2">
                <Col lg={8} >
                {/* <img src={signature} alt="Logo" /> */}
                <img src={file}  style={{maxWidth: '400px', maxHeight: '80px'}} />
                </Col>
                {/* <Col lg={4} >
                </Col> */}
                <Col lg={4}>
                <img src={approvedSign}  style={{maxWidth: '400px', maxHeight: '80px'}} />
                </Col>

            </Row>
            <Row className="mb-2 g-2">
                <Col lg={8} >
                <Form.Label>{selectedUser && selectedUser.label}</Form.Label>
                </Col>
                <Col lg={4}>
                <Form.Label>{approvedBy}</Form.Label>
                </Col>

            </Row>
            <Row className="mb-2 g-2">
                <Col lg={8} >
                <Form.Label>{selectedUser && selectedUser.title}</Form.Label>
                </Col>
                <Col lg={4}>
                <Form.Label>{approvedTitle}</Form.Label>
                </Col>
            </Row>
            <Row className="mb-2 g-2">
                <p>
                    <small><i>This is a laboratory-developed test validated under CLIA standards. This test has not been licensed by the Food and Drug Administration. There are situations where the phenotype predicted by this test may not correspond to the actual red cell phenotype. Specifically, uninterrogated genetic variation that alters the phenotype and genetic variation that inteferes with the course of the assay. In persons with a history of transplantation results obtained from hematopoietic cells may differ from those obtained from other tissues. The Genomics Laboratory is certified under CLIA to perform high-complexity testing, and has been approved by the New York State Department of Health to perform testing under its Clinical Laboratory Permit.</i></small>
                </p>

            </Row>
            <Row className="mb-2 g-2">
                <Col lg={4} >
                <Form.Label><small><i>Testing performed by:<br/>
                {divisionName}<br/>
                {divisionAddress}<br/>
                Director: {divisionDirector}<br/>
                CLIA #: {divisionCLIANumber}  {divisionEmail}</i></small></Form.Label>
                </Col>
                <Col lg={4} >
                </Col>
                <Col lg={4}>

                </Col>
            </Row>
            <div className="text-end">
            <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={() => onClickPrepare()}
                value="Save"
                //type="submit"
                disabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
              >
                <FontAwesomeIcon icon={faSave} /> Save Draft Report
              </button>       
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={() => onClickDraftApprove()}
                value="Save"
                //type="submit"
                hidden ={isApproved}
                disabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
              >
                <FontAwesomeIcon icon={faSave} /> Approve
              </button>
            </div>
  
    </Card.Header>
</Card>
      <Modal show={showConfirmTarget} onHide={onClickTargetCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickTargetCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to approve the draft report?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickTargetOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickTargetCancel} >Cancel</Button> 
        </Modal.Footer>
        </Modal>
    </>
  );
};


export default SangerDraftReport;
