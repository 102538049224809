/* eslint-disable prettier/prettier */
//Report Viewer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-designer.min';
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.compatibility.min.css';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reportdesigner.compatibility.min.css';

//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';

import boldReportService from '../../services/boldReport-service';
import { Card } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
//import { useParams } from 'react-router-dom';
import './BoldReport.css';
import orderService from 'services/order-service';
import $ from 'jquery';

const BoldReports = (prop) => {
 // let params = useParams();
 // const scenarioId = prop.wifaParamId;//1;//params.id;
 // const wifaParam = prop.wifaParam;
  const [boldReportToken, setBoldReportToken] = useState('');
  const [reportHead, setReportHead] = useState('');

  var orderDetails = [];
  
  useEffect(() => {
      boldReportService.fetchBoldReportToken()
        .then((boldReportTokenResponse) => {
          setBoldReportToken(boldReportTokenResponse.result);
        })
      if (prop.reportPathIGApp === '/IGApp/IGApp_HEA_Report') {
        setReportHead('HEA Final');
      } else if (prop.reportPathIGApp === '/IGApp/IGApp_Sanger_Report') {
        setReportHead('Sanger Final');
      } else if (prop.reportPathIGApp === '/IGApp/IGApp_Reflex_HEA_Report') {
        setReportHead('HEA_Reflex Final');
      } else {
        setReportHead('');
      }
    
  }, []);

  //function loadData() {
    // Fetch data or perform other loading logic here
    // var reportviewerObj = $("#reportviewer-container").data("boldReportViewer");
    // var params = reportviewerObj && reportviewerObj.getParameters(); 
    // var parameters = params && params[0].values[0];
    //var orderId = 48950;
    //orderService.FetchOrderDetailsById(orderId).then(setOrderDetails);
 //}



  const reportServiceUrl =
    'https://hemetrics-report.azurewebsites.net/reporting/reportservice/api/Viewer';
  const reportServerUrl =
    'https://hemetrics-report.azurewebsites.net/reporting/api/site/hemprod';
   const reportPathIGApp = prop.reportPathIGApp;
    // var parameters = [{
    //   Name: 'DictionaryId',
    //   Values: 2
    //   }];
  function onExportItemClick(args) {
    //args.cancel = true;

    //var orderId = $("#reportviewer-container").data("boldReportViewer").getParameters()[0].values[0];
    //var reportviewerObj = $("#reportviewer-container").data("boldReportViewer");
    //var reportviewerObj = $("#reportviewer-container").data("boldReportViewer"); 
    //console.log(reportviewerObj.getParameters());
    //var orderId = reportviewerObj.getParameters()[0].values[0];
       
    // args.fileName= 'HEA Draft ';
    // orderService.FetchOrderDetailsById(orderId).then((orderDetails) => {
    //   //setOrderDetails(orderDetails);
    //   args.fileName='HEA Draft ' + orderDetails.maNumber + ' ' + orderDetails.bloodHubOrderId + ' ' + orderDetails.patientFirstName + ' ' + orderDetails.patientLastName;
    //   //orderService.exportBoldReport(orderId);
    // });
    args.fileName= reportHead + orderDetails.maNumber + ' ' + orderDetails.bloodHubOrderId + ' ' + orderDetails.patientFirstName + ' ' + orderDetails.patientLastName;
  }

  function viewReportClick() {
    //Perform your custom success message here
    //console.log("Ajax request success!!!");
    var reportviewerObj = $("#reportviewer-container").data("boldReportViewer"); 
    //console.log(reportviewerObj.getParameters());

    if(reportviewerObj && reportviewerObj.getParameters()[0] && reportviewerObj.getParameters()[0].values != null){
      var orderId = reportviewerObj.getParameters()[0].values[0];
      
      orderService.FetchOrderDetailsById(orderId).then((orderDetail) => {
        //setOrderDetails(orderDetail);
        orderDetails = orderDetail;
        //args.fileName='HEA Draft ' + orderDetails.maNumber + ' ' + orderDetails.bloodHubOrderId + ' ' + orderDetails.patientFirstName + ' ' + orderDetails.patientLastName;
        //orderService.exportBoldReport(orderId);
      });
  }
}

  

  return (
    <>
      <div>
        {boldReportToken && (
          <Card className="h-100">
            <Card.Body className="p-0">
              {/* eslint-disable-next-line */}
             <BoldReportViewerComponent
                id="reportviewer-container"
                reportServiceUrl={reportServiceUrl}
                reportServerUrl={reportServerUrl}
                serviceAuthorizationToken={boldReportToken}
                reportPath={reportPathIGApp}
               // parameters = {parameters}
                exportItemClick={onExportItemClick}
                viewReportClick = {viewReportClick}
              />
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};

export default BoldReports;
