/* eslint-disable prettier/prettier */
import React, { useContext, useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import ProfileDropdown from '../../../components/navbar/top/ProfileDropdown';
// import NotificationDropdown from '../../../components/navbar/top/NotificationDropdown';
import AppContext from '../../../context/Context';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { useMsal } from "@azure/msal-react";
const TopNavRightSideNavItem = (props) => {
  const {
    config: { navbarPosition },
  } = useContext(AppContext);

  const className =  navbarPosition === 'vertical' || props.isSmallScreen? "navbar-nav-icons ms-auto flex-row align-items-center" :  "navbar-nav-icons flex-row align-items-center";

  const {instance} = useMsal();
  const [remaining, setRemaining] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime()/1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })
  const onIdle = () => {
    console.log('fires after 10 minutes');
    console.log(remaining);
    //insert any custom logout logic here
    sessionStorage.clear();
    // instance.logout();

    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    }).then(() => {
      console.log("Logout successful");
    });
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 15 * 60 * 1000, //10 minute idle timeout
    //throttle: 500
  })

  return (          
    <Nav navbar className={className} as="ul">      
      {/* <NotificationDropdown /> */}
      {/* <div>{remaining} seconds remaining</div> */}
      <ProfileDropdown />
    </Nav>
  );
};

TopNavRightSideNavItem.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired
};

export default TopNavRightSideNavItem;
