/* eslint-disable prettier/prettier */
import BaseService from './base-service';

class OrderService extends BaseService {
    PerformTestResultsInterpretation = (orderId) => {
        return this._apiGet({
            path: `/orders/performTestResultsInterpretation/${orderId}`
        });
    }
    fetchHEATestResultDetails = (orderId) => {
        return this._apiGet({
            path: `/orders/getHEATestResultDetails/${orderId}`
        });
    }
    FetchOrderDetailsById = (orderId) => {
        return this._apiGet({
            path: `/orders/getOrderDetailsById/${orderId}`
        });
    }

    FetchOrderDetailsPageById = (orderId) => {
        return this._apiGet({
            path: `/orders/getOrderDetailsPageById/${orderId}`
        });
    }

    UpdateGenotypeTestReult = (testResultInput) => {
        return this._apiPost({
            path: `/orders/updateGenotypeTestReult`,
            data: testResultInput
        });
    }

    fetchSangerTestResultDetails = (orderId) => {
        return this._apiGet({
            path: `/orders/getSangerTestResultDetails/${orderId}`
        });
    }

    saveTestResults = (testResults, reportType) => {
        return this._apiPost({
            path: '/orders/saveTestResult',
            data: {
                testResults: testResults,
                reportTypeId: reportType
            }
        });
    }

    saveSangerSampleResult = (testResults) => {
        return this._apiPost({
            path: '/orders/saveSangerSampleResult',
            data: {
                SangerTestResultSampleResult: testResults
            }
        });
    }


    saveSangerMutationReport = (testResults) => {
        return this._apiPost({
            path: '/orders/saveSangerMutationReport',
            data: {
                SangerTestMutationResultSampleResult: testResults
            }
        });
    }

    getSameDayHistory = () => {
        return this._apiGet({
            path: `/orders/getSameDayHistory`
        });
    }

    getSameDayHistoryRecords = () => {
        return this._apiGet({
            path: `/orders/getSameDayHistoryRecords`
        });
    }

    UpdateSangerTestReult = (testResultInput) => {
        return this._apiPut({
            path: `/orders/updateSangerTestReult`,
            data: testResultInput
        });
    }

    performSangerTestResults = (orderId) => {
        return this._apiPost({
            path: `/orders/performSangerTestResults/${orderId}`
        });
    }

    fetchOrderStatusList = () => {
        return this._apiGet({
            path: `/orders/getOrderStatusList`
        });
    }

    updateOrderDetails = (order) => {
        return this._apiPut({
            path: `/orders/updateOrderDetails`,
            data: order
        });
    }
    UpdateComments = (testResultModelList) => {
            return this._apiPut({
            path: `/orders/updatecomments`,
            data: { TestResults: testResultModelList } 
        });
    }

    updateApproveHEATestResult = (orderId, preparedBy, approvedBy) => {
        return this._apiPost({
            path: `/orders/updateApproveHEATestResult/${orderId}/${preparedBy}/${approvedBy}`
        });
    }

    updatePreparedByHEATestResult = (orderId, preparedBy, testingDivisionId) => {
        return this._apiPost({
            path: `/orders/updatePreparedByHEATestResult/${orderId}/${preparedBy}/${testingDivisionId}`
        });
    }

    getHeainterpretation = (orderId) => {
        return this._apiGet({
            path: `/orders/getHeainterpretation/${orderId}`
        });
    }

    updateApproveSangerTestResult = (orderId, preparedBy, approvedBy) => {
        return this._apiPost({
            path: `/orders/updateApproveSangerTestResult/${orderId}/${preparedBy}/${approvedBy}`
        });
    }

    updatePreparedBySangerTestResult = (orderId, preparedBy, testingDivisionId) => {
        return this._apiPost({
            path: `/orders/updatePreparedBySangerTestResult/${orderId}/${preparedBy}/${testingDivisionId}`
        });
    }

    getSangerInterpretation = (orderId) => {
        return this._apiGet({
            path: `/orders/getSangerInterpretation/${orderId}`
        });
    }

    getTestingDivisions = () => {
        return this._apiGet({
            path: `/orders/getTestingDivisions`
        });
    }

    deleteOrderById = (orderId) => {
        return this._apiDelete({
            path: `/orders/deleteOrderById/${orderId}`
        });
    }

    getEthnicity = () => {
        return this._apiGet({
            path: `/orders/getEthnicity`
        });
    }

    getReferralReason = () => {
        return this._apiGet({
            path: `/orders/getReferralReason`
        });
    }

    exportBoldReport = (orderId) => {
        return this._apiPost({
            path: `/orders/exportBoldReport/${orderId}`
        });
    }

    getSangerResult = (orderId) => {
        return this._apiGet({
            path: `/orders/getSangerResult/${orderId}`
        });
    }
    
}
export default new OrderService();