/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Breadcrumb, Spinner, Button, Modal } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import { settings } from '../../config';
import { getItemFromStore } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';
import 'pages/patient/report-browser.scss';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import service from 'services/dataEntry-service';
import DateFormat from '../../components/common/date-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import orderService from 'services/order-service';

//const DATE_FORMAT = 'MM-DD-YYYY';
const Orders = () => {
  const [patientsDetails, setPatientsDetails] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  const [isLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [bloodHubOrderId, setBloodHubOrderId] = useState(0);

  const columns = [
 
    {
      accessor: 'orderId',
      Header: 'OrderId',
      Cell: rowData => (
        <a
          href={`${window.location.origin}/orders/orderDetailsTab/${rowData.row.original.orderId}`}
          className="text-primary fw-semi-bold"
        >
          {rowData.row.original.orderId}
        </a>
      )
    },
    {
      accessor: 'bloodHubOrderId',
      Header: 'BloodHubOrderId',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    // {
    //   accessor: 'customOrderId',
    //   Header: 'CustomOrderId',
    //   headerProps: {
    //     className: 'text-start'
    //   },
    //   cellProps: {
    //     className: 'text-start'
    //   }
    // },
    {
      accessor: 'mrnumber',
      Header: 'MRNumber',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'testRequest',
      Header: 'Test Request',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start cell-description'
      }
    },
    {
      accessor: 'sampleSource',
      Header: 'Sample Source',
      Cell: rowData => (
        <DateFormat format="MM-DD-YYYY" date={rowData.row.original.patientDOB} />
      )
    },
    {
      accessor: 'orderStatus',
      Header: 'Order Status',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'collectionDate',
      Header: 'Collection Date',
      Cell: rowData => (
        <DateFormat format="MM-DD-YYYY" date={rowData.row.original.sampleDate} />
      )
    },
    {
      accessor: 'Delete',
      Header: '',
      Cell: rowData => (
        // <Link to={`/release/editor/${rowData.row.original.releaseId}`}>
        //   <FontAwesomeIcon icon={faEdit} />
        // </Link>
        <Button type="button" variant="outline-primary" className="me-1 mb-1" size="sm"  
        onClick={() => onDeleteClick(
            rowData.row.original.orderId,
            rowData.row.original.bloodHubOrderId
        )}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      )
    }
  ];

  
  useEffect(() => {
    service.getIncompleteOrderList()
    .then(orderList => {
        setPatientsDetails(orderList);
    })   
  }, []);

  function onDeleteClick(orderId, bloodHubOrderId) {
    setShowConfirm(true);
    setOrderId(orderId);
    setBloodHubOrderId(bloodHubOrderId);
  }
  
  const onClickCancel = () => setShowConfirm(false);
  const onClickOk = () => { setShowConfirm(false); deleteInstitution(orderId); }

  function deleteInstitution(orderId) {
    orderService.deleteOrderById(orderId)
    .then(() => {
        //setPatientsDetails(institutionList);
        service.getIncompleteOrderList(setPatientsDetails).then(()=>{

          toast(ConfirmationMessage("Order Deleted", "The order is deleted successfully!", "bg-success", "faCheckCircle"), {
            autoClose: true,
            //className: 'transparent',
            //style: {{background-color: transparent !important}},
            position: 'bottom-right',
            hideProgressBar: true,
            closeButton: false,
          });
        });
    })  
  }
  // const onClickReport = () => {
  //   var orderId = 48950;
  //   OrderService.PerformTestResultsInterpretation(orderId)
  //   .then(() => {
  //     window.location.href = '/orders/draftReport';
  //   })
    
  // };

  const reportBrowserdesc = "Patient Search";

  return (
    <>
      <Helmet>
        <title>Open Orders</title>
      </Helmet>
      <PageHeader title="Open Orders" className="mb-2" col="12">
      <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                Orders
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                header = "Enterprise Data Governance"
                body = {reportBrowserdesc}
              />
          </Col>
          </Row>
      </PageHeader>
    
      <AdvanceTableWrapper
        columns={columns}
        data={patientsDetails}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">        
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                
              </Col>
            </Row>
          </Card.Header>
          {
          isLoading ? <div className='text-center'><Spinner animation="border" /></div> :        
            <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            </Card.Body>
          }
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={patientsDetails.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
        <br />
      </AdvanceTableWrapper>

      <Modal show={showConfirm} onHide={onClickCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Delete Blood Hub Order Id : <span className="text-danger">{bloodHubOrderId} </span></Modal.Title>
          <FalconCloseButton onClick={onClickCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete it?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickCancel} >Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Orders;
