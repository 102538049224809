/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import 'pages/patient/report-browser.scss';
import { Helmet } from 'react-helmet';
//import OrderService from 'services/order-service';
import vitalantPath from './vitalantPath.png';
import nybcPath from './NewYorkBloodCenterLogo.png';
import immucor from './immucor.png';
import moment from 'moment-timezone';
import './report.scss';
//import { faSave } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';



const PhenReport = ({ orderDetails, phenTypeResults, handleMultipleFinalCommentsChange, finalComments}) => {

  // Adding useState for managing the finalComments
  // const [finalComments, setFinalComments] = useState([]);
  // const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });


  // const handleMultipleFinalCommentsChange = (update) => {
  //   const { index, value, testResultId, testName, testResult } = update;

  //   // Make a copy of the current state
  //   const updatedFinalComments = [...finalComments];

  //   // Find the comment by index
  //   const foundComment = updatedFinalComments.find(comment => comment.index === index);

  //   if (foundComment) {
  //     // Update its value
  //     const currentIndex = updatedFinalComments.findIndex(comment => comment.index === index);
  //     updatedFinalComments[currentIndex].value = value;
  //     updatedFinalComments[currentIndex].FinalComments = value;
  //   } else {
  //     // If not found, push a new comment

  //     updatedFinalComments.push({ index: index, value: value, FinalComments: value, TestResultId: testResultId, TestName: testName, TestResult: testResult });
  //   }

  //   // Set the state with the updated list
  //   setFinalComments(updatedFinalComments);
  // };

  // // Function to handle saving the changes
  // const handleSave = async () => {
  //   try {
  //     const response = await OrderService.UpdateComments(finalComments);
  //     console.log(response);

  //     // Check if status code is in the success range (200-299)
  //     if (response) {
  //       setAlertMessage({ type: 'success', message: 'All comments updated successfully' });
  //     } else {
  //       setAlertMessage({ type: 'danger', message: 'Failed to update the comments.' });
  //     }
  //   } catch (error) {
  //     // Handle any errors that might occur during the request
  //     setAlertMessage({ type: 'danger', message: 'Error while updating comments. Please try again.' });
  //   }
  // };




  // const closeAlert = () => {
  //   setAlertMessage({ type: '', message: '' });
  // };


  const columns = [

    {
      Header: 'Blood Group',
      Cell: rowData => (
        <p>
          {rowData.row.original.testName == 'c' ? 'RH' :
            rowData.row.original.testName == 'C' ? 'RH' :
              rowData.row.original.testName == 'e' ? 'RH' :
                rowData.row.original.testName == 'E' ? 'RH' :
                  rowData.row.original.testName == 'V' ? 'RH' :
                    rowData.row.original.testName == 'VS' ? 'RH' :
                      rowData.row.original.testName == 'K' ? 'KEL' :
                        rowData.row.original.testName == 'k' ? 'KEL' :
                          rowData.row.original.testName == 'Kpa' ? 'KEL' :
                            rowData.row.original.testName == 'Kpb' ? 'KEL' :
                              rowData.row.original.testName == 'Jsa' ? 'KEL' :
                                rowData.row.original.testName == 'Jsb' ? 'KEL' :
                                  rowData.row.original.testName == 'Fya' ? 'Duffy' :
                                    rowData.row.original.testName == 'Fyb' ? 'Duffy' :
                                      rowData.row.original.testName == 'Jka' ? 'Kidd' :
                                        rowData.row.original.testName == 'Jkb' ? 'Kidd' :
                                          rowData.row.original.testName == 'M' ? 'MNS' :
                                            rowData.row.original.testName == 'N' ? 'MNS' :
                                              rowData.row.original.testName == 'S' ? 'MNS' :
                                                rowData.row.original.testName == 's' ? 'MNS' :
                                                  rowData.row.original.testName == 'U' ? 'MNS' :
                                                    rowData.row.original.testName == 'Lua' ? 'Lutheran' :
                                                      rowData.row.original.testName == 'Lub' ? 'Lutheran' :
                                                        rowData.row.original.testName == 'Dia' ? 'Diego' :
                                                          rowData.row.original.testName == 'Dib' ? 'Diego' :
                                                            rowData.row.original.testName == 'Coa' ? 'Colton' :
                                                              rowData.row.original.testName == 'Cob' ? 'Colton' :
                                                                rowData.row.original.testName == 'Doa' ? 'Dombrock' :
                                                                  rowData.row.original.testName == 'Dob' ? 'Dombrock' :
                                                                    rowData.row.original.testName == 'Hy' ? 'Dombrock' :
                                                                      rowData.row.original.testName == 'Joa' ? 'Dombrock' :
                                                                        rowData.row.original.testName == 'LWa' ? 'Landsteiner-Wiener' :
                                                                          rowData.row.original.testName == 'LWb' ? 'Landsteiner-Wiener' :
                                                                            rowData.row.original.testName == 'Sc1' ? 'Scianna' :
                                                                              rowData.row.original.testName == 'Sc2' ? 'Scianna' : ''}
        </p>
      )
    },
    {
      accessor: 'testName',
      Header: 'Antigen',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'testResult',
      Header: 'Result',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'comments',
      Header: 'Comments',
      Cell: rowData => (
        <Form.Group>
          <Form.Control
            type="text"
            value={rowData.row.original.comments}
            readOnly
          />
        </Form.Group>
      )
    },
    {
      accessor: 'finalComments',
      Header: 'Final Comments',
      Cell: rowData => {
        // Initialize local state with the value from the finalComments array or the original value.
        const testResultId = rowData.row.original.testResultId;
        const testName = rowData.row.original.testName;
        const testResult = rowData.row.original.testResult;

        // Initialize local state with the value from the finalComments array or the original value.
        const [localValue, setLocalValue] = useState(() => {
          const existingComment = finalComments.find(item => item.index === rowData.row.index);
          return existingComment ? existingComment.value : rowData.row.original.finalComments;
        });

        const handleInputChange = e => {
          setLocalValue(e.target.value);
        };

        const handleInputBlur = () => {
          handleMultipleFinalCommentsChange({ value: localValue, index: rowData.row.index, testResultId: testResultId, testName: testName, testResult: testResult });
        };

        return (
          <Form.Group>
            <Form.Control
              type="text"
              value={localValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />
          </Form.Group>
        );
      }
    }


  ];

  return (
    <>
      {/* <Row className="mb-2 g-2">
        <Form.Group as={Col} lg={12} >
          {alertMessage.message && (
            <Col md={12} className="mb-3">
              <div
                className={`alert alert-${alertMessage.type} d-flex justify-content-between align-items-center`}
                role="alert"
              >
                {alertMessage.message}
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeAlert}
                  aria-label="Close"
                ></button>
              </div>
            </Col>
          )}

        </Form.Group>
      </Row> */}

      <Helmet>
        <title>Draft Report</title>
      </Helmet>
      <Card className="h-100">
        <Card.Header>
          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8}>
              {orderDetails.institution && orderDetails.institution.toLowerCase().includes("vitalant") ? (
                <img src={vitalantPath} alt="Logo" />
              ) : (
                <img src={nybcPath} alt="Logo" />
              )}
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="reportDate">

            </Form.Group>
          </Row>
          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="patientFirstName">
              <Form.Label>
                Institution:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Institution"
                value={orderDetails.institution}
                disabled={true}
              />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="collectionDate">
              <Form.Label>
                Report Date:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Report Date"
                value={orderDetails.createdOn ? moment(orderDetails.createdOn).format('MM-DD-YYYY') : ''}
                disabled={true}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="sampleID1">
              <Form.Label>
                SampleID#1:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="SampleID1"
                value={orderDetails.patientFirstName ? (orderDetails.patientFirstName + ' ' + orderDetails.patientMiddleName + ' ' + orderDetails.patientLastName) : ''}
                disabled={true}
              />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="order">
              <Form.Label>
                Order#:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Order"
                value={orderDetails.bloodHubOrderId}
                disabled={true}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="sampleID2">
              <Form.Label>
                DOB:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="DOB"
                value={orderDetails.patientDOB ? moment(orderDetails.patientDOB).format('MM-DD-YYYY') : ''}
                disabled={true}
              />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="ma">
              <Form.Label>
                MA#:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="MA"
                value={phenTypeResults.length > 0 ? phenTypeResults[0].manumber : 0}
                disabled={true}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2 g-1">
            <Col lg={5} className='legend'>
              <Row className="mb-2 g-2">
                <Col lg={6}>
                  LEGEND:
                </Col>
                <Col lg={6} className='text-end' >
                  HEAN1896_1
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6}>
                  CV (Coeficient of Variation):
                </Col>
                <Col lg={6} >
                  CV of intensities above recommended maximum.
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6} >
                  HB (High Background):
                </Col>
                <Col lg={6} >
                  Signal intensity above recommended maximum.
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6} >
                  IC (Indeterminate Call):
                </Col>
                <Col lg={6} >
                  Algorithm unable to confidently predict result.
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6} >
                  LS (Low Signal):
                </Col>
                <Col lg={6} >
                  Signal intensity below recommended maximum <br />
                  (except for s/S in certain circumstances).
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6} >
                  NTD (No Typing Determined):
                </Col>
                <Col lg={6} >
                  Typing was not able to be determined.
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6} >
                  PV:
                </Col>
                <Col lg={6} >
                  Possible Variant.
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6} >
                  (+)*:
                </Col>
                <Col lg={6} >
                  Possible (C)ces haplotype.<br />
                  Additional testing recommended for C / RH2.
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6} >
                  var:
                </Col>
                <Col lg={6} >
                  U variant detected.
                </Col>
              </Row>

              <Row className="mb-2 g-2">
                <Col lg={6} >
                  w:
                </Col>
                <Col lg={6} >
                  Weak expression
                </Col>
              </Row>

              <Row className="mb-2 g-2 legendBottom">
                <Col lg={6} >
                  (0)*:
                </Col>
                <Col lg={6} >
                  GATA silencing present.
                </Col>
              </Row>

            </Col>

            <Col lg={1}></Col>

            <Col lg={6}>
              <AdvanceTableWrapper
                columns={columns}
                data={phenTypeResults}
                //sortable
                pagination
                perPage={35}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle"
                  tableProps={{
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </AdvanceTableWrapper>
            </Col>
          </Row>

          <Row className="mb-2 g-2">
            <Col lg={8} >
              <img src={immucor} alt="Logo" />
            </Col>
            {/* <Col lg={4} >
        </Col> */}
            {/* <Col lg={4}>
              <Form.Label>Final Signature</Form.Label>
            </Col> */}

          </Row>
          
          {/* <div className="text-end">
            <button
              className="btn btn-outline-primary me-1 mb-1"
              onClick={handleSave} // Linking the save button to the handleSave function
              disabled = {interpretation.approvedBy != null ? true : false}
            >
              <FontAwesomeIcon icon={faSave} /> Save Phenotype Test Result
            </button>
          </div> */}
        </Card.Header>
      </Card>

    </>
  );
};


PhenReport.propTypes = {
  orderDetails: PropTypes.arrayOf(PropTypes.object),
  phenTypeResults: PropTypes.arrayOf(PropTypes.object),
  interpretation: PropTypes.arrayOf(PropTypes.object),
  handleMultipleFinalCommentsChange: PropTypes.arrayOf(PropTypes.object),
  finalComments: PropTypes.arrayOf(PropTypes.object)
};
export default PhenReport;
