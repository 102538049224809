/* eslint-disable prettier/prettier */
import { LogLevel } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: { 
      clientId: process.env.REACT_APP_IGAPP_APP_ID, 
      authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_AZURE_TENANT_ID,
      redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const APIConfig = {
   // scopes: ['api://0ec2dfd9-9372-427f-8351-b60f1e9930ac/user_impersonation']  //igapp-local
    scopes: ['api://c6cf3c24-17ef-4b66-8d1d-0e2f0da90932/user_impersonation'] //igapp-qa
};

export const graphConfig = {
  scopes: ['people.read user.readbasic.all user.read'],
  baseURL: 'https://graph.microsoft.com/v1.0'
};
export const emailAPIConfig = {
  scopes: ['api://deda5990-536c-45db-855d-0ad97185a559/email.send'],
  //baseURL: 'http://localhost:63804'
  baseURL: 'https://email-qa-api.azurewebsites.net'
};

export const notificationAPIConfig = {
  scopes: ['api://b19ceeed-63e5-4677-a263-afbe5c9b1372/user_impersonation'], // notification scope
  // baseURL: 'http://localhost:63804'  // not being used?
  //baseURL: 'https://nybce-application-notification-qa-api.azurewebsites.net'  // from environment variable
};
