import React, { useContext } from 'react';
import { Form, Row } from 'react-bootstrap';
// import cardImg from 'assets/img/generic/card.png';
// import transparentImg from 'assets/img/generic/default.png';
import arrowsH from 'assets/img/icons/arrows-h.svg';
import paragraph from 'assets/img/icons/paragraph.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AppContext from '../../../context/Context';
//import RadioItem from '../../../components/settings-panel/RadioItem';
import { Helmet } from 'react-helmet';

const Settings = () => {
  const {
    config: {
      isFluid,
      // isRTL,
      //isDark,
      navbarPosition,
      // navbarStyle,
      gridRowsCount
    },
    setConfig
  } = useContext(AppContext);

  // const [navbars] = useState([
  //   {
  //     name: 'transparent',
  //     image: transparentImg
  //   },
  //   // {
  //   //   name: 'inverted',
  //   //   image: invertedImg
  //   // },
  //   {
  //     name: 'card',
  //     image: cardImg
  //   }
  //   // {
  //   //   name: 'vibrant',
  //   //   image: vibrantImg
  //   // }
  // ]);

  const handleChange = e => {
    setConfig('gridRowsCount', e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <h5>
        <FontAwesomeIcon icon="palette" className="me-2 fs-0" />
        Settings
      </h5>
      <p className="mb-0 fs--1 opacity-75">Set your own customized style </p>
      <br />
      <Flex justifyContent="between">
        <img src={arrowsH} alt="" width={20} className="me-2 h-100" />
        <div className="flex-1">
          <h5 className="fs-0">Fluid Layout</h5>
          <p className="fs--1 mb-0">Toggle container layout system</p>
        </div>
        <Form.Check
          type="switch"
          id="fluid-mode-switch"
          checked={isFluid}
          onChange={({ target }) => setConfig('isFluid', target.checked)}
        />
      </Flex>
      <hr />

      <Flex>
        <img src={paragraph} alt="" width={20} className="me-2 h-100" />
        <div>
          <Flex alignItems="center" tag="h5" className="fs-0">
            Navigation Position
          </Flex>
          <p className="fs--1 mb-2">
            Select a suitable navigation system for your web application
          </p>
          {/* <Form.Check
            type="radio"
            id="verticalNav-radio"
            label="Vertical"
            name="NavBarPositionRadioButton"
            checked={navbarPosition === 'vertical'}
            onChange={() => setConfig('navbarPosition', 'vertical')}
            inline
          /> */}
          <Form.Check
            type="radio"
            id="topNav-radio"
            label="Top"
            name="NavBarPositionRadioButton"
            checked={navbarPosition === 'top'}
            onChange={() => setConfig('navbarPosition', 'top')}
            inline
          />
        </div>
      </Flex>

      <hr />
      {/* <h5 className="fs-0 d-flex align-items-center">Vertical Navbar Style </h5>
      <p className="fs--1">Switch between styles for your vertical navbar</p>
      <ButtonGroup className="btn-group-navbar-style">
        {navbars.slice(0, 2).map(item => (
          <RadioItem
            key={item.name}
            name="navbar-style"
            label={item.name}
            active={navbarStyle === item.name}
            onChange={() => setConfig('navbarStyle', item.name)}
            image={item.image}
          />
        ))}
      </ButtonGroup>
      <ButtonGroup className="btn-group-navbar-style">
        {navbars.slice(2, 4).map(item => (
          <RadioItem
            key={item.name}
            name="navbar-style"
            label={item.name}
            active={navbarStyle === item.name}
            onChange={() => setConfig('navbarStyle', item.name)}
            image={item.image}
          />
        ))}
      </ButtonGroup> */}

      <hr />

      <h5 className="fs-0 d-flex align-items-center">Data Grid Row Count </h5>
      <Row className="mb-3 g-3">
        <p className="fs--1">
          Setting of rows count of per page of all data grids in the application
        </p>

        <Form.Control
          type="text"
          placeholder="Rows Count"
          value={gridRowsCount}
          name="gridRowCount"
          onChange={handleChange}
        />
      </Row>

      <hr />
    </>
  );
};

export default Settings;
