/* eslint-disable prettier/prettier */
const Enum = {
    CACHED_USER_PROFILE: 'userProfile',
    CACHED_DASHBOARDMENU: 'dashboard-menu',
    CACHED_ANALYTICSMENU: 'analytics-menu',
    CACHED_VERTICAL_FULLMENU: 'fullMenu',
    CACHED_CATALOG: 'catalog',
    CACHED_SEARCH: 'search',
    CACHED_RECENTLY_BROWSED: 'recently-browsed',
    CACHED_ADVANCED_SEARCH: 'advanced-search',
    CACHED_DATA_GLOSSARY: 'data-glossary',
    CACHED_FIXED_MENU: 'fixed-menu',
    CACHED_MICROSOFT_GRAPH_TOKEN: 'microsoftGraphToken',
    // CACHED_GLOBAL_USER_ID: 'globalUserId',
    CACHED_URL: "cachedUrl",
    CACHED_HOME_ACTIVE_DONORS: "activeDonors",
    CACHED_HOME_BOOKINGS: "bookingsNext7Days",
    CACHED_CATALOG_ID: "catalogIds",
    CACHED_CATALOG_TYPES: "catalogTypes",
    CACHED_CATALOG_HOSTS: "catalogHosts"    
  }
export default Enum
  