/* eslint-disable prettier/prettier */
import BaseService from './base-service';

class BoldReportService extends BaseService {
  getBloodHubDetails = orderId => {
    return this._apiGet({
      path: `/patients/getBloodHubDetails/${orderId}`
    });
  };

  saveOrderDetails = (order) => {
    return this._apiPost({
      path: `/patients/saveOrderDetails`,
      data: order
    })
  }

  getIncompleteOrderList = () => {
    return this._apiGet({
      path: `/patients/getIncompleteOrderList`
    });
  };

  fetchTestCategoryList = () => {
    return this._apiGet({
      path: `/patients/getTestCategoryList`
    });
  };

  fetchBloodCenterCode = () => {
    return this._apiGet({
      path: `/patients/getBloodCenterList`
    });
  };
}
export default new BoldReportService();