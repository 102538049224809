/* eslint-disable prettier/prettier */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { APIConfig } from "./authConfig";
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
// import Enum from 'common/enum';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
export const Login = () => {
  const { instance, inProgress } = useMsal();
  if (inProgress === InteractionStatus.None) {
        instance.loginRedirect(APIConfig);
      }
      return <div className='align-items-center text-center'></div> ;
}


const App = () => {
  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Router basename={process.env.PUBLIC_URL}>
            <Layout />
        </Router>
      </MsalAuthenticationTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};
export default App;


