/* eslint-disable prettier/prettier */
// import ticket from '../data/image/ticket.png';
// import addSite from '../data/image/addSite.png';
export const faqAccordions = [
  {
    id: 0,
    title: 'What is IGApp?',
    description:
      "<p><span lang='EN-US'>IGApp is New York Blood Center Enterprise's data analytics platform to cleanse,define and consolidate core enterprise data across all affiliates and businessdomains.</span></p><p><span lang='EN-US'>Executive Summary</span></p><ul type='disc'> <li><span lang='EN-US'>Enable seamless, cross-business domain data collaboration</span></li> <li><span lang='EN-US'>Enable tactical and strategic decision making on trusted data</span></li> <li><span lang='EN-US'>Create competitive advantage from data insights and identify areas of improvements and opportunities</span></li> <li><span lang='EN-US'>Collaborate with external data vendors with greater efficiency and agility</span></li></ul><p><span lang='EN-US'>Core Themes</span></p><ul type='disc'> <li><span lang='EN-US'>Implement solutions with an iterative approach that immediately addresses business needs</span></li> <li><span lang='EN-US'>Establish a cleansed, integrated, and mastered enterprise data warehouse repository</span></li> <li><span lang='EN-US'>Foster relevant analytics (Decision Science) and business intelligence capabilities such as:</span></li> <ul type='circle'><li><span lang='EN-US'>Predictive and prescriptive analytics (next best action, conversion…)</span></li>  <li><span lang='EN-US'>Textual / Descriptive Analytics, data mining</span></li>  <li><span lang='EN-US'>Establish an enterprise-wide Data Governance and Data Stewardship Program</span></li> </ul></ul>"
  },
  {
    id: 1,
    title: 'How is IGApp developed?',
    description:
      "<div><p>IGApp is developed in-house at New York Blood Center utilizing the Azure framework, Microsoft's cloud services platform.</p></div>"
  },
  {
    id: 2,
    title: 'Do I need to request access to IGApp?',
    description:
      "<div><p>No, all active employees have access to IGApp. However, reports and dashboards with sensitive data are not accessible by default. Individual access is determined on a case-by-case basis with management approval as required. If there is a report or dashboard that needs to be added to an individual’s access, a ServiceNow ticket should be submitted.</p></div>"
  },
  {
    id: 5,
    title: 'How do I change the position of the Navigation bar?',
    description:
      "<div><p>By default, the navigation position is set to Top. This position can be changed to Top by clicking on Settings from the homepage and selecting the position of the Navigation System to Top. From this page, you may also switch between different styles of displayed Vertical Navbar.</p></div>"
  },
  {
    id: 6,
    title: 'What do I do if the alignment of IGApp portal does not work on my mobile?',
    description:
      "<div><p>IGApp portal is best viewed in landscape mode on smaller mobile devices such as iPhone. On a desktop, a minimum resolution 1920x1080 is required.</p></div>"
  },
  {
    id: 8,
    title: 'How do I return to the homepage within IGApp?',
    description:
      "<div><p>Click on the 'IGApp' logo that is displayed on top left side of the window.</p></div>"
  },
  {
    id: 9,
    title: 'How do I download/export a report?',
    description:
      "<div><p><span lang='EN-US'>Data can be exported to excel/csv from report views.</span></p><p><span lang='EN-US'> </span><span lang='EN-US'>Dashboards:</span></p><ul><li><span lang='EN-US'>Open the dashboard from Hemetrics     portal.</span></li><li><span lang='EN-US'>Click on the icon (excel, image,     PDF, CVS, PPT) displayed on bottom right of the screen.</span></li><li><span lang='EN-US'>For PDF and PPT, select the     applicable parameters and click Download.</span></li></ul><p><span lang='EN-US'>Reports:</span></p><ul><li><span lang='EN-US'>Open the report from Hemetrics portal.</span></li><li><span lang='EN-US'>Click on the export icon in the top left of the screen.</span></li><li><span lang='EN-US'>Select the desired format (excel, image,PDF, CVS, PPT, …). </span></li></ul></div>"
  }
];
