/* eslint-disable prettier/prettier */
import BaseService from './base-service';

class CommonService extends BaseService {
    getInstitution = () => {
        return this._apiGet({
          path: `/common/getInstitution`
        });
      };

      saveInstitutionDetails = (institution) => {
        return this._apiPost({
          path: `/common/saveInstitutionDetails`,
          data: institution
        })
      }

      deleteInstitution = (institutionId) => {
        return this._apiDelete({
            path: `/common/deleteInstitution/${institutionId}`
        });
    }

    addComment = (comment) => {
      return this._apiPost({
        path: `/common/addComment`,
        data: comment
      })
    }

    getComments = (orderId) => {
      return this._apiGet({
        path: `/common/getComments/${orderId}`
      });
    };
    
    deleteComment = (commentId) => {
      return this._apiDelete({
          path: `/common/deleteComment/${commentId}`
      });
  }

}
export default new CommonService();