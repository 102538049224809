/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tab, Tabs, Form, Spinner, Modal, Button } from 'react-bootstrap';
//import PageHeader from 'components/common/PageHeader';
import 'pages/patient/report-browser.scss';
import { Helmet } from 'react-helmet';
//import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
// import vitalantPath from './vitalantPath.png';
// import signature from './signature.png';
import OrderService from 'services/order-service';
//import GenReport from './genReport';
import PhenReport from './reflexPhenReport';
import { useParams } from "react-router-dom";
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import userService from 'services/user-service';
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import Enum from 'common/enum';
import orderService from 'services/order-service';

const DraftReport = (oid) => {
  let params = useParams();
  //const orderId = params.orderId;
  var profile = JSON.parse(localStorage.getItem(Enum.CACHED_USER_PROFILE));
  //const [patientsDetails, setPatientsDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  //const [testResultDetails, setTestResultDetails] = useState([]);
  const [genTypeResults, setGenTypeResults] = useState([]);
  const [phenTypeResults, setPhenTypeResults] = useState([]);
  const [orderId, setOrderId] = useState(oid ? params.orderId : oid);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [approvedBy, setApprovedBy] = useState();
  const [approvedSign, setApprovedSign] = useState();
  const [approvedTitle, setApprovedTitle] = useState();
  const [file, setFile] = useState();
  const [users, setUsers] = useState([]);
  const [interpretation, setInterpretation] = useState({
    heainterpretId:0,
    orderId:null,
    preparedBy:null,
    approvedBy:null
  });
  const [validatePreparedBy, setValidatePreparedBy] = useState(true);
  const [hasOrderId, setHasOrderId] = useState(true);
  const [comments] = useState();
  const [notes] = useState();
  const [orderStatus, setOrderStatus] = useState(0);
  const [finalComments, setFinalComments] = useState([]);
  //const [autosave, setAutosave] = React.useState(false);
  const [changes] = React.useState(false);
  const [isApproved, setIsApproved] = React.useState(true);
  const [showConfirmTarget, setShowConfirmTarget] = useState(false);
  const [sampleID1, setSampleID1] = React.useState();
  const [sampleID2, setSampleID2] = React.useState();
  const [recieptDate, setRecieptDate] = React.useState(new Date());
  const [patientDOB, setPatientDOB] = React.useState(new Date());
  const [raceEthnicity, setRaceEthnicity] = React.useState();
  const [testRequested, setTestRequested] = React.useState();
  const [testPerformed, setTestPerformed] = React.useState();
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState();
  const [divisionName, setDivisionName] = useState('');
  const [divisionAddress, setDivisionAddress] = useState('');
  const [divisionDirector, setDivisionDirector] = useState('');
  const [divisionCLIANumber, setDivisionCLIANumber] = useState('');
  const [divisionEmail, setDivisionEmail] = useState('');
  const [validateDivision, setValidateDivision] = useState(true);
  useEffect(() => {   
    userService.getAllActiveUsersAsync().then((results) =>{
      
      results.forEach(item => {
        renameKey(item, 'userId', 'value');
        renameKey(item, 'fullName', 'label');
      });
      setUsers(results);
      if(orderId)
      {      
        //OrderService.PerformTestResultsInterpretation(orderId);
        onClickGetTestResult(results);
      }
      console.log(changes);
    });
    OrderService.getTestingDivisions().then((results)=>{
      results.forEach(item => {
        renameKey(item, 'testingDivisionId', 'value');
        renameKey(item, 'divisionCode', 'label');
      });
      setDivisions(results);
    });
  }, []);

  const onChangeTestingDivision = (e) => {
    var division = divisions.filter(a => a.value == e.value);
    
    if(division.length > 0)
    {
      setSelectedDivision(division[0]);

      setDivisionName(division[0].divisionName);
      setDivisionAddress(division[0].divisionAddress);
      setDivisionDirector(division[0].divisionDirector);
      setDivisionCLIANumber(division[0].divisionCLIANumber);
      setDivisionEmail(division[0].divisionEmail);

      setValidateDivision(true);
    }
  };

  // const onSampleId1Change = (e) => {
  //   setSampleID1(e.target.value)
  //   setChanges(true);
  // };
  // const onSampleId2Change = (e) => {
  //   setSampleID2(e.target.value)
  //   setChanges(true);
  // };
  // const onRecieptDateChange = (e) => {
  //   setRecieptDate(e.target.value)
  //   setChanges(true);
  // };
  // const onPatientDOBChange = (e) => {
  //   setPatientDOB(e.target.value)
  //   setChanges(true);
  // };
  // const onRaceEthnicityChange = (e) => {
  //   setRaceEthnicity(e.target.value)
  //   setChanges(true);
  // };
  // const onTestRequestedChange = (e) => {
  //   setTestRequested(e.target.value)
  //   setChanges(true);
  // };
  // const onTestPerformedChange = (e) => {
  //   setTestPerformed(e.target.value)
  //   setChanges(true);
  // };

  const onChangeOrderId= (e) => {
    setOrderId(e.target.value);
  };

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  const onClickGetTestResult = (results) => {
    if (orderId) {
      setHasOrderId(true);
    } else {
      setHasOrderId(false);
      return false;
    }
    setIsLoading(true);
    OrderService.FetchOrderDetailsById(orderId).then((orderResult) => {
      setOrderId(orderResult.orderId);
      setOrderDetails(orderResult);
      setOrderStatus(orderResult.orderStatusId);
      setSampleID1((orderResult.patientFirstName ? orderResult.patientFirstName : '') + (orderResult.patientMiddleName ? ' ' + orderResult.patientMiddleName + ' ' : ' ') + (orderResult.patientLastName ? orderResult.patientLastName : ''));
      setSampleID2(orderResult.mrNumber);
      setRecieptDate(orderResult.receiptDate);
      setPatientDOB(orderResult.patientDOB);
      setTestPerformed(orderResult.testPerformed);
      setTestRequested(orderResult.testRequest);
      setRaceEthnicity(orderResult.patientEthnicity);
      var interpretationPromise = OrderService.getHeainterpretation(orderResult.orderId);
      var testResultPromise = OrderService.fetchHEATestResultDetails(orderResult.orderId);
      Promise.all([interpretationPromise, testResultPromise]).then(([interpretationH, testResult]) => {        
        
        var division = divisions.filter(a => a.value == interpretationH.testingDivisionId);
        if(division.length > 0)
        {
          setSelectedDivision(division[0]);
          setDivisionName(division[0].divisionName);
          setDivisionAddress(division[0].divisionAddress);
          setDivisionDirector(division[0].divisionDirector);
          setDivisionCLIANumber(division[0].divisionCLIANumber);
          setDivisionEmail(division[0].divisionEmail);
        }
        setInterpretation(interpretationH);

        if(interpretationH.testingDivisionId)
        {
          setValidateDivision(true);
        }
        else
        {
          setValidateDivision(false);
        }
        var preparedUser = null;
        if(interpretationH.preparedBy == 0)
        {
          preparedUser = users.find(item => item.value === profile.userId);
        }
        else
        {
          preparedUser = users.find(item => item.value === interpretationH.preparedBy);
        }
        //var preparedUser = users.find(item => item.value === interpretationH.preparedBy || profile.userId);
        var approverUser = users.find(item => item.value === interpretationH.approvedBy);

        if(users.length == 0)
        {        
          
          preparedUser = results.find(item => item.value === interpretationH.preparedBy || profile.userId);
          approverUser = results.find(item => item.value === interpretationH.approvedBy);
          
        }
        
        setSelectedUser(preparedUser);

        if(interpretationH.preparedBy == 0)
        {
          userService.getIGSignature(profile.userId)
          .then(blob => {       
            if (blob) { 
              setFile(URL.createObjectURL(blob));
            }
          });     
      }
      else
      {
        userService.getIGSignature(interpretationH.preparedBy)
        .then(blob => {       
          if (blob) { 
            setFile(URL.createObjectURL(blob));
          }
        });   
      }
      if(orderResult.orderStatusId == 4)
      {     
        userService.getIGSignature(interpretationH.approvedBy)
          .then(blob => {       
            if (blob) { 
              setApprovedSign(URL.createObjectURL(blob));
            }
        });
      
        
        setApprovedBy(approverUser && approverUser.label);
        setApprovedTitle(approverUser && approverUser.title);
      }
        setGenTypeResults(testResult.filter(a => a.reportTypeId == 1));
        setPhenTypeResults(testResult.filter(a => a.reportTypeId == 2));
        setIsLoading(false);
    }).catch(error => {

      console.log(error);
      setOrderStatus(0);
      setHasOrderId(false);
      setIsLoading(false);
    });
    }).catch(error => {

    console.log(error);
    setOrderStatus(0);
    setHasOrderId(false);
    setIsLoading(false);
  });
  };

  const onChangePreparedBy = (e) => {    
    //userService.getUser(e.value).then(setSelectedUser);
    var user = users.find(item => item.value === e.value);
    setSelectedUser(user);
    
    userService.getIGSignature(e.value)
      .then(blob => {       
        if (blob) { 
          setFile(URL.createObjectURL(blob));
        }
    });
  };

  const onClickApprove= () => {
    if (selectedUser) {
      setValidatePreparedBy(true);
    } else {
      setValidatePreparedBy(false);
      return false;
    }
    if (selectedDivision) {
      setValidateDivision(true);
    } else {
      setValidateDivision(false);
      return false;
    }

    setShowConfirmTarget(true);
    //orderDetails.orderId
    //var testResultTest = { OrderId : orderDetails.orderId, TestName: '', TestResult: '',  Comments : comments || genTypeResults[0].comments , Notes : notes || genTypeResults[0].notes};
    OrderService.updateApproveHEATestResult(orderId, selectedUser.value, profile.userId)
    .then(response => {
      setOrderStatus(response.result.orderStatus);
      userService.getIGSignature(response.result.approvedBy)
        .then(blob => {       
          if (blob) { 
            setApprovedSign(URL.createObjectURL(blob));
          }
      });
      var approverUser = users.find(item => item.value === response.result.approvedBy);
      setApprovedBy(approverUser && approverUser.label);
      setApprovedTitle(approverUser && approverUser.title);
    })
    .then(() => {      
      toast(ConfirmationMessage(`HEA Test Result`, `Draft Report Approved Successfully!`, "bg-success", "faCheckCircle"), {
        autoClose: true,
        //className: 'transparent',
        //style: {{background-color: transparent !important}},
        position: 'bottom-right',
        hideProgressBar: true,
        closeButton: false,
      });
    })
  };

  const onClickPrepare= () => {
    if (selectedUser) {
      setValidatePreparedBy(true);
    } else {
      setValidatePreparedBy(false);
      return false;
    }
    if (selectedDivision) {
      setValidateDivision(true);
    } else {
      setValidateDivision(false);
      return false;
    }
    onClickSave();
    handleSave();
    //var testResultTest = { OrderId : orderDetails.orderId, TestName: '', TestResult: '',  Comments : comments || genTypeResults[0].comments , Notes : notes || genTypeResults[0].notes};
    OrderService.updatePreparedByHEATestResult(orderId, selectedUser.value, selectedDivision.value).then(() => {      
      toast(ConfirmationMessage(`HEA Test Result`, `Draft Report Saved Successfully!`, "bg-success", "faCheckCircle"), {
        autoClose: true,
        //className: 'transparent',
        //style: {{background-color: transparent !important}},
        position: 'bottom-right',
        hideProgressBar: true,
        closeButton: false,
      });
    })

    setIsApproved(false);
  };

  // const onCommentsChange = (e) => {
  //   setComments(e.target.value)
  //   setChanges(true);
  // };

  // const onNotesChange = (e) => {
  //   setNotes(e.target.value);
  //   setChanges(true);
  // };

  const onClickSave= () => {
    //orderDetails.orderId
    var name = sampleID1.split(' ');
    var firstName = '';
    var middleName = '';
    var lastName ='';
    if(name.length > 2)
    {
      firstName = name[0];
      middleName = name[1];
      lastName = name[2];
    }
    else
    {
      firstName = name[0];
      lastName = name[1];
    }
    var testResultTest = { OrderId : orderDetails.orderId,
      TestName: '',
      TestResult: '',
      Comments : comments || genTypeResults[0].comments ,
      Notes : notes || genTypeResults[0].notes,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      sampleID2: sampleID2,
      recieptDate: recieptDate,
      raceEthnicity: raceEthnicity,
      testRequested: testRequested,
      testPerformed: testPerformed,
      patientDOB:patientDOB
    };
    orderService.UpdateGenotypeTestReult(testResultTest);
  };

  const handleMultipleFinalCommentsChange = (update) => {
    const { index, value, testResultId, testName, testResult } = update;

    // Make a copy of the current state
    const updatedFinalComments = [...finalComments];

    // Find the comment by index
    const foundComment = updatedFinalComments.find(comment => comment.index === index);

    if (foundComment) {
      // Update its value
      const currentIndex = updatedFinalComments.findIndex(comment => comment.index === index);
      updatedFinalComments[currentIndex].value = value;
      updatedFinalComments[currentIndex].FinalComments = value;
    } else {
      // If not found, push a new comment

      updatedFinalComments.push({ index: index, value: value, FinalComments: value, TestResultId: testResultId, TestName: testName, TestResult: testResult });
    }

    // Set the state with the updated list
    setFinalComments(updatedFinalComments);
  };

  // Function to handle saving the changes
  const handleSave = async () => {
      const response = await OrderService.UpdateComments(finalComments);
      console.log(response);
  };

  const onClickTargetCancel = () => setShowConfirmTarget(false);
  const onClickTargetOk = () => { setShowConfirmTarget(false); onClickApprove();}
  function onClickDraftApprove () {
    setShowConfirmTarget(true);
  }

  return (
    <>
       <Helmet>
        <title>HEA Reflex Draft Report</title>
      </Helmet>
      <Card className="h-100">
      <Card.Header>
      <Row className="mb-2 g-2">
      <Form.Group as={Col} lg={4} controlId="orderId">
        <Form.Control
            className={hasOrderId?'':'validation-error'}
            type="text"
            placeholder="Order Id"
            //value={orderId}
            //name="patientLastName"
            onChange={(e) => onChangeOrderId(e)}
        />
        {!hasOrderId && (
                    <span className="validation-error-msg">
                      Please enter the valid Order Id.
                    </span>
                  )}
        </Form.Group>
        <Form.Group as={Col} lg={4} controlId="reportDate">
        <div className="text-start">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={() => onClickGetTestResult()}
                value="GetTestResult"
                //type="submit"
                //disabled={disable}
              >
                <FontAwesomeIcon /> Get Test Result
              </button>
            </div>
        </Form.Group>
        </Row>
        
        {isLoading ? <div className='text-center'><Spinner animation="border" /></div> : <div></div>}
      <Tabs variant='pills' defaultActiveKey="phenotype" id="uncontrolled-tab-example" className='mb-3'>
          {/* <Tab eventKey="genotype" title="Genotype Report" className='border p-3'>
          <GenReport orderDetails= {orderDetails}
           genTypeResults={genTypeResults}
           comments={comments}
           onCommentsChange={onCommentsChange}
           notes={notes}
           onNotesChange={onNotesChange}
           sampleId1={sampleID1}
           onSampleId1Change={onSampleId1Change}
           onSampleId2Change={onSampleId2Change}
           onRecieptDateChange={onRecieptDateChange}
           onPatientDOBChange={onPatientDOBChange}
           onRaceEthnicityChange={onRaceEthnicityChange}
           onTestRequestedChange={onTestRequestedChange}
           onTestPerformedChange={onTestPerformedChange}
           testRequested={testRequested}
           testPerformed={testPerformed}
           orderStatus={orderStatus}
           divisions={divisions}
           selectedDivision={selectedDivision}
           onChangeTestingDivision={onChangeTestingDivision}
           validateDivision={validateDivision}/> 
          </Tab> */}
          <Tab eventKey="phenotype" title="Phenotype Report" className='border p-3'>
          <PhenReport orderDetails= {orderDetails} 
                      phenTypeResults={phenTypeResults} 
                      interpretation={interpretation} 
                      handleMultipleFinalCommentsChange={handleMultipleFinalCommentsChange} 
                      finalComments={finalComments}
                      divisions={divisions}
                      selectedDivision={selectedDivision}
                      onChangeTestingDivision={onChangeTestingDivision}
                      validateDivision={validateDivision}/>
          </Tab>
      </Tabs>
      <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4}>
                  <Form.Label>
                  Prepared By:
                  </Form.Label>
                  <Select
                    className={validatePreparedBy?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={users}
                    placeholder='Select...'
                    isDisabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                    //classNamePrefix="react-select"
                    value={orderStatus == 4 ? users.filter(a => a.value == interpretation.preparedBy) : selectedUser}
                    onChange={e => onChangePreparedBy(e)}
                  />
                  {!validatePreparedBy && (
                    <span className="validation-error-msg">
                      Please select preparedBy.
                    </span>
                  )}                
                  </Form.Group>
                  <Form.Group as={Col} lg={4}></Form.Group>
                  <Form.Group as={Col} lg={4}>  
                  <Form.Label>
                  Approved By:
                  </Form.Label>
                  {/* <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="MA"
                      value={genTypeResults.length > 0 ? genTypeResults[0].manumber : 0}
                      //name="patientFirstName"
                      //onChange={(e) => onFirstNameChange(e)}
                  /> */}
                  </Form.Group>
              </Row>
            <Row className="mb-2 mt-2 g-2">
                <Col lg={8} >
                {/* <img src={signature} alt="Logo" /> */}
                <img src={file}  style={{maxWidth: '400px', maxHeight: '80px'}} />
                </Col>
                {/* <Col lg={4} >
                </Col> */}
                <Col lg={4}>
                <img src={approvedSign}  style={{maxWidth: '400px', maxHeight: '80px'}} />
                </Col>

            </Row>
            <Row className="mb-2 g-2">
                <Col lg={8} >
                <Form.Label>{selectedUser && selectedUser.label}</Form.Label>
                </Col>
                <Col lg={4}>
                <Form.Label>{approvedBy}</Form.Label>
                </Col>

            </Row>
            <Row className="mb-2 g-2">
                <Col lg={8} >
                <Form.Label>{selectedUser && selectedUser.title}</Form.Label>
                </Col>
                <Col lg={4}>
                <Form.Label>{approvedTitle}</Form.Label>
                </Col>
            </Row>
            <Row className="mb-2 g-2">
                <p>
                    <small><i>This PreciseType Human Erythrocyte Antigen test has been licensed by the Food and Drug Administration to predict the blood group antigen profile in a patient or donor. There are situations where testing of DNA may not reflect the red cell phenotype. Specifically, nucleotide changes that inactivate gene expression or rare variant alleles may not be detected.  Results obtained from DNA isolated from leukocytes or other hematopoietic cells may differ from DNA isolated from other tissues in persons with a history of transplantation.</i></small>
                </p>

            </Row>
            <Row className="mb-2 g-2">
                <Col lg={4} >
                <Form.Label><small><i>Testing performed by:<br/>
                {divisionName}<br/>
                {divisionAddress}<br/>
                Director: {divisionDirector}<br/>
                CLIA #: {divisionCLIANumber}  {divisionEmail}</i></small></Form.Label>
                </Col>
                <Col lg={4} >
                </Col>
                <Col lg={4}>

                </Col>
            </Row>
            <div className="text-end">
            <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={() => onClickPrepare()}
                value="Save"
                //type="submit"
                disabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
              >
                <FontAwesomeIcon icon={faSave} /> Save Draft Report
              </button>       
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={() => onClickDraftApprove()}
                value="Save"
                //type="submit"
                hidden ={isApproved}
                disabled={(orderStatus == 4  || orderStatus == 0) ? true : false}
              >
                <FontAwesomeIcon icon={faSave} /> Approve
              </button>
            </div>
    </Card.Header>
</Card>
     
      <Modal show={showConfirmTarget} onHide={onClickTargetCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickTargetCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to approve the draft report?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickTargetOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickTargetCancel} >Cancel</Button> 
        </Modal.Footer>
        </Modal>
    </>
  );
};


export default DraftReport;
