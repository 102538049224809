import {
  graphConfig,
  emailAPIConfig,
  APIConfig,
  notificationAPIConfig
} from 'authConfig';
import { msalInstance } from 'authConfig';

export const getJwtToken = () => {
  return new Promise((resolve, reject) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return reject('No account found.');
    }
    const request = {
      scopes: APIConfig.scopes,
      account: activeAccount || accounts[0]
    };
    return msalInstance
      .acquireTokenSilent(request)
      .then(accessTokenResponse => {
        return resolve(accessTokenResponse.accessToken);
      });
  });
};

export const getMicrosoftGraphToken = () => {
  return new Promise((resolve, reject) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return reject('No account found. Redirecting to login...');
    }
    const request = {
      scopes: graphConfig.scopes,
      account: activeAccount || accounts[0]
    };
    return msalInstance
      .acquireTokenSilent(request)
      .then(accessTokenResponse => {
        return resolve(accessTokenResponse.accessToken);
      });
  });
};

export const getEmailAPIToken = () => {
  return new Promise((resolve, reject) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return reject('No account found.');
    }
    const request = {
      scopes: emailAPIConfig.scopes,
      account: activeAccount || accounts[0]
    };
    return msalInstance
      .acquireTokenSilent(request)
      .then(accessTokenResponse => {
        return resolve(accessTokenResponse.accessToken);
      });
  });
};
export const getNotificationAPIToken = () => {
  return new Promise((resolve, reject) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return reject('No account found.');
    }
    const request = {
      scopes: notificationAPIConfig.scopes,
      account: activeAccount || accounts[0]
    };
    return msalInstance
      .acquireTokenSilent(request)
      .then(accessTokenResponse => {
        return resolve(accessTokenResponse.accessToken);
      });
  });
};
export default [
  getJwtToken,
  getEmailAPIToken,
  getMicrosoftGraphToken,
  getNotificationAPIToken
];
