/* eslint-disable prettier/prettier */
import BaseService from './base-service';

class PatientService extends BaseService {
    fetchPatientDetails = (PatientSearch) => {
        return this._apiPost({
            path: `/patients/getPatientDetails`,
            data: PatientSearch
        });
    }

    // saveTestResults = (testResults, reportType) => {
    //     return this._apiPost({
    //         path: '/orders/saveTestResult',
    //         data: { 
    //             testResults: testResults, 
    //             reportTypeId: reportType 
    //         }
    //     });
    // }
    
}
export default new PatientService();