/* eslint-disable prettier/prettier */
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import Notification from 'pages/notification/Notification';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import  UserBrowser  from '../../src/pages/admin/user-browser';
import Starter from 'pages/Starter';
import Profile from 'pages/user/profile/Profile';
import BoldReports from 'pages/BoldReports/BoldReports';
import Settings from 'pages/user/settings/Settings';
import FaqAccordion from 'pages/faq/faq-accordion/FaqAccordion';
import Error404 from '../components/errors/Error404';
import Error500 from '../components/errors/Error500';
import Error501x from '../components/errors/Error501x';
import TableauLicenseError from '../components/errors/TableauLicenseError';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
import Logout from 'components/authentication/card/Logout';
import CardLockScreen from 'components/authentication/card/LockScreen';
import Home from 'pages/home/home';
import ReportBrowser from 'pages/reports/report-browser';
import SplitLogin from 'components/authentication/split/Login';
import SplitLogout from 'components/authentication/split/Logout';
import SplitLockScreen from 'components/authentication/split/LockScreen';
import HemetricsSop from 'pages/sop/hemetricsSop';
import CustomerMDMSop from 'pages/sop/customerMDMSop';
import BloodLossSop from 'pages/sop/bloodLossSop';
import DataGovernanceSop from 'pages/sop/dataGovernanceSOP';
import TableauIPhoneInstructionsSop from 'pages/sop/TableauInstructionOnIPhone';
import AppContext from 'context/Context';
import UserEditor from 'pages/admin/user-editor';
import UserRolesBrowser from 'pages/admin/user-role-browser';
import UserRoleEditor from 'pages/admin/user-role-editor';
import UserRoleNew from 'pages/admin/user-role-new';
import ManageInstitution from 'pages/admin/manage-institution';
import ReleaseBrowser from 'pages/release/releaseBrowser';
import ReleaseEditor from 'pages/release/releaseEditor';
import ReleaseNew from 'pages/release/releaseNew';
import ReleaseView from 'pages/release/releaseView';
/* eslint-disable-next-line */
import NotificationDetail from 'pages/notification/NotificationDetail';
import AdvancedSearch from 'pages/search/advanced-search';
import PatientSearch from 'pages/patient/patientSearch';
import DataEntryForm from 'pages/dataEntry/dataEntryForm';
import Orders from 'pages/orders/orders';
import OrderDetailsTab from 'pages/orders/orderDetailsTab';
import DraftReport from 'pages/orders/draftReport';
import ReflexDraftReport from 'pages/orders/reflexDraftReport';
import PhenReport from 'pages/orders/phenReport';
import UploadTestResult from 'pages/orders/uploadTestResult';
import RecentAccessionedRecords from 'pages/orders/recentAccessionedRecords';
import SameDayHistory from 'pages/orders/sameDayHistory';
import HEAReport from 'pages/BoldReports/hea_genotype_report';
import HEAReflexReport from 'pages/BoldReports/heaReflex_final_report';
import GenReport from 'pages/orders/genReport';
import SangerDraftReport from 'pages/sangerSequencing/sangerDraftReport';
import SangerReport from 'pages/BoldReports/sanger_final_report';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);
  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>            
        <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />          
          <Route path="errors/501x" element={<Error501x />} />
          <Route path="errors/TableauLicenseError" element={<TableauLicenseError />} />
        </Route>
        
        {/*- ------------- simple ---------------------------  */}
        <Route element={<AuthSimpleLayout />}>
              <Route
            path="authentication/simple/logout"
            element={<SimpleLogout />}
          />
        
          <Route
            path="authentication/simple/lock-screen"
            element={<SimpleLockScreen />}
          />
        </Route>

     
        <Route path="authentication/card/logout" element={<Logout />} />
     
        <Route
          path="authentication/card/lock-screen"
          element={<CardLockScreen />}
        />

        {/*- ------------- Split ---------------------------  */}

        <Route path="authentication/split/login" element={<SplitLogin />} />
        <Route path="authentication/split/logout" element={<SplitLogout />} />
       
        <Route
          path="authentication/split/lock-screen"
          element={<SplitLockScreen />}
        />
        <Route path="signout-oidc" element={<Navigate replace to="/" />} />   
        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Home page*/}
           <Route path="/" element={<Home />} /> 
         <Route path="advanced-search" element={<AdvancedSearch />} />
         
         <Route path="/patient/patientSearch" element={<PatientSearch />} />
         <Route path="/dataEntry/dataEntryForm" element={<DataEntryForm />} />
         <Route path="/orders/orders" element={<Orders />} />
         <Route path="/orders/orderDetailsTab/:orderId" element={<OrderDetailsTab />} />
         <Route path="/orders/draftReport" element={<DraftReport />} />
         <Route path="/orders/reflexDraftReport" element={<ReflexDraftReport />} />
         <Route path="/orders/draftReport/:orderId" element={<DraftReport />} />
         <Route path="/orders/genReport" element={<GenReport />} />
         <Route path="/orders/phenReport" element={<PhenReport />} />
         <Route path="/orders/uploadTestResult" element={<UploadTestResult />} />
         <Route path="/orders/recentAccessionedRecords" element={<RecentAccessionedRecords />} />
         <Route path="/orders/sameDayHistory" element={<SameDayHistory />} />
         <Route path="/BoldReports/hea_genotype_report" element={<HEAReport />} />
         <Route path="/BoldReports/heaReflex_final_report" element={<HEAReflexReport />} />
         <Route path="/BoldReports/sanger_final_report" element={<SangerReport />} />
         <Route path="/sangerSequencing/sangerDraftReport" element={<SangerDraftReport />} />

         <Route path="/report/browser" element={<ReportBrowser />} />
          <Route path="pages/starter" element={<Starter />} />
          <Route path="user/profile" element={<Profile />} />
          <Route path="user/settings" element={<Settings />} />
          <Route path="boldReports/:id" element={<BoldReports />} />
          
          <Route path="faq" element={<FaqAccordion />} />
          <Route path="hemetricsSop" element={<HemetricsSop />} />
          <Route path="customerMDMSop" element={<CustomerMDMSop />} />
          <Route path="bloodLossSop" element={<BloodLossSop />} />
          <Route path="dataGovernanceSop" element={<DataGovernanceSop />} />
          <Route path="tableauIPhoneInstructionsSop" element={<TableauIPhoneInstructionsSop />} />
          <Route         path="admin/UserBrowser"        element={<UserBrowser  />}      />

          <Route path="/admin/UserBrowser/:userId" element={<UserEditor  />} />
          <Route    path="/admin/UserRoles"      element={<UserRolesBrowser  />}     />
         <Route path="/admin/UserRoles/:roleId"     element={<UserRoleEditor  />}   />
          <Route  path="/admin/NewUserRoles/" element={<UserRoleNew  />}  />
          <Route  path="/admin/ManageInstitution/" element={<ManageInstitution  />}  />
          {/* Relase Manager*/}
          <Route
                    path="/release/browser/"
                    element={<ReleaseBrowser  />}                 
                    />
          <Route
                    path="/release/editor/:releaseId"
                    element={<ReleaseEditor />}                 
                    />
          <Route
                    path="/release/new/"
                    element={<ReleaseNew />}                 
                    />
          <Route
                    path="/release/view/:releaseId"
                    element={<ReleaseView />}                 
                    />        
          {/* Notification*/}
            <Route
            path="/notification/Notification/"
            element={<Notification />}
          />
          <Route
            path="/notification/NotificationView/:messageId"
            element={<NotificationDetail />}
          />   
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      {/* <SettingsToggle /> */}
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
