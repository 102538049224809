/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import DNAImage from 'assets/img/gallery/dnaImage.jpeg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDatabase, faCircleQuestion, faBookOpenReader, faArrowsSplitUpAndLeft } from '@fortawesome/free-solid-svg-icons';
// import { faWikipediaW  } from "@fortawesome/free-brands-svg-icons";
// import {userProfile} from 'common/user';
const WelcomeCard = () => {
//  const profile = userProfile(); //JSON.parse(localStorage.getItem('userProfile'));
 // var myDate = new Date();
  // var hrs = myDate.getHours();
  
  // const [firstName, setFirstName] = useState('');
  //eslint-disable-next-line
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const detectSize = () => { setScreenWidth(window.innerWidth); }

  useEffect(() => {
    // profile.then(x=>{
    //   setFirstName(x.firstName);
    // });
  }, []);
  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
   
  }, []);

  return (
    <Card className="h-md-100">
      {/* <Background image={bg2} className="p-card bg-card" /> */}
      <Card.Header className="pb-2">
        <div className="position-relative z-index-2">
          <div>
            {/* <h3 className="text-primary mb-1">{hrs < 12 ? 'Good Morning' : (hrs >= 12 && hrs <= 17) ? 'Good Afternoon' : (hrs >= 17 && hrs <= 24) ? 'Good Evening' : 'Good Morning'}, {firstName}</h3>
            <br/> */}
            <h5>Welcome to ImmunoGeno Application!</h5>
          </div>
          {/* <br/> */}
          <div>
            <p> IGApp is New York Blood Center Enterprise's platform for Immunohematology, Genomics and Frozen blood Lab to automate test results management for scanned units from BloodHub and other sources. </p>
          </div> 
          <div>
            <h6> OverView</h6>
              <ul>
                <li> Secured app with Microsoft latest Authentication model to verify Active Directory Authentication. </li>
              </ul>
          </div>
          
          <div>
            <h6> Technology </h6>
              <ul>
                <li> Used latest javascript framework React.js for creating the dynamic user interface. </li>
                <li> Used most recent C#.Net framework (.NET 7) for writing the business logic. </li>
                <li> Used Microsoft SQL Server for storing data. </li>
                <li> All the components of the app has been deployed to the cloud environment (Microsoft Azure). </li>
              </ul>
          </div>

          <Image
                  src={DNAImage}
                  fluid
                  width={700}
                 // rounded
                 // onClick={() => setImgIndex(0)}
                />
          {/* <Row className="py-1">        
            <Col>
              <FontAwesomeIcon icon={faBookOpenReader} className="me-1" />
              <a href="https://nybc.sharepoint.com/sites/NYBCeEADS/SitePages/Hemetrics-101.aspx" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                ImmunoGenoApp 101
                </a>
              <br/>
              <small className='text-nowrap'>Learn all the basics of the portal</small>
            </Col>
            <Col>
            <FontAwesomeIcon icon={faDatabase} className="me-1" />
              <a href="https://nybc.sharepoint.com/sites/NYBCeEADS/SitePages/Enterprise-Data-Governance.aspx" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                Immunohematology and Genomics
              </a>
              <br/>
              <small className='text-nowrap'>Learn about the approach and methodology</small>
            </Col>    
            <Col>
            <FontAwesomeIcon icon={faArrowsSplitUpAndLeft} className="me-1" />
              <a href="https://nybc.sharepoint.com/sites/NYBCeEADS/Lists/Analytics%20Use%20Cases/AllItems.aspx?viewid=11383780%2D5c2a%2D4ece%2D9bbf%2D482a3897d064" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                  IGApp Tests
              </a>
              <br/>
              <small className='text-nowrap'>View current queue</small>
            </Col>
            <Col>
            <FontAwesomeIcon icon={faCircleQuestion} className="me-1" />
              <Link to="/faq" className="fw-semi-bold fs--1 text-nowrap">
                Help
              </Link>
              <br/>
              <small className='text-nowrap'>Read frequently asked questions</small>
          </Col>
          </Row>                */}
        </div>
      </Card.Header>

    </Card>
  );
};

export default WelcomeCard;
