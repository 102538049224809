/* eslint-disable prettier/prettier */
import React, { useState, useRef } from 'react';
import {
  Breadcrumb,
  Col,
  Row,
  Form,
  Button,
  Table,
  Card
} from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import * as XLSX from 'xlsx';
import './UploadTestResult.css';
import orderService from 'services/order-service';

const UploadTestResult = () => {
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [structuredData, setStructuredData] = useState([]);
  const [reportType, setReportType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSangerOption, setSelectedSangerOption] = useState("");
  const [mutationFileContent, setMutationFileContent] = useState(null);
  const [sampleTestFileContent, setSampleTestFileContent] = useState(null);
  const [jsonData, setJsonData] = useState([]);






  const fileInputRef = useRef(null);

  //for mutation txt file upload
  function handleMutationTxtUpload(event) {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = function (e) {
      const content = e.target.result;
      setMutationFileContent(content);
      const rows = content.split('\n');

      const geneNames = ["ABO", "RHD", "RHCE", "KEL", "JK", "FY", "GYPA", "GYPB"];

      // New function to extract gene name from a given string
      const extractGeneName = (str) => {
        for (let gene of geneNames) {
          if (str.includes(gene)) {
            return gene;
          }
        }
        return "";  // Return an empty string if no gene is found
      };

      // Extract gene name from the row containing "Active Layer"
      let geneNameFromRow = "";
      for (let row of rows) {
        if (row.includes("Active Layer")) {
          geneNameFromRow = extractGeneName(row);
          break;
        }
      }

      const jsonData = [];
      for (let i = 1; i < rows.length; i++) {
        const columns = rows[i].split('\t');

        if (!columns[0].includes('MA') || !columns[1] || columns[1].trim() === "") {
          continue;
        }

        const dataObject = {
          Manumber: columns[0],
          BaseChange: columns[1],
          ROI: columns[2],
          Position: columns[3],
          Length: columns[4],
          Type: columns[5],
          QV: columns[6],
          Known: columns[7],
          Effect: columns[8],
          AaChange: columns[9],
          Description: columns[10],
          GeneName: geneNameFromRow,
          FileName: file.name
        };

        jsonData.push(dataObject);
      }

      console.log(jsonData);
      setJsonData(jsonData);

    };

    reader.readAsText(file);
  }

  //for sample test txt file upload
  function handleMutationSampleTestTxtUpload(event) {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async function (e) {  // <-- Notice the 'async' here

      const content = e.target.result;
      setSampleTestFileContent(content);
      const rows = content.split('\n');

      const geneNames = ["ABO", "RHD", "RHCE", "KEL", "JK", "FY", "GYPA", "GYPB"];

      // New function to extract gene name from a given string
      const extractGeneName = (str) => {
        for (let gene of geneNames) {
          if (str.includes(gene)) {
            return gene;
          }
        }
        return "";  // Return an empty string if no gene is found
      };

      // Extract gene name from the row containing "Active Layer"
      let geneNameFromRow = "";
      for (let row of rows) {
        if (row.includes("Active Layer")) {
          geneNameFromRow = extractGeneName(row);
          break;
        }
      }

      // Find the line index for "Sample Results"
      const sampleResultsIndex = rows.findIndex(row => row.includes("Sample Results"));
      if (sampleResultsIndex === -1) {
        console.error("Sample Results section not found!");
        return;
      }

      const jsonData = [];
      // Start processing rows after "Sample Results"
      for (let i = sampleResultsIndex + 2; i < rows.length; i++) {
        const columns = rows[i].split('\t');
        // Stop if the row is empty
        if (columns.length <= 1) break;

        if (!columns[0].includes('MA') || !columns[1] || columns[1].trim() === "") {
          continue;
        }

        const dataObject = {
          Manumber: columns[1],
          Segment: columns[2],
          Orientation: columns[3],
          Assembled: columns[4],
          ClearRange: columns[5],
          RangeOnReference: columns[6],
          SampleScore: columns[7],
          MixedBaseP: columns[8],
          GeneName: geneNameFromRow,
          FileName: file.name
        };

        jsonData.push(dataObject);
      }

      console.log(jsonData);
      setJsonData(jsonData);

    };

    reader.readAsText(file);
  }


  //for excel upload
  const fileHandler = event => {
    let fileObj = event.target.files[0];
    let fileName = fileObj.name.toLowerCase();
  
    if (fileName.includes('gen')) {
      setReportType(1);
    } else if (fileName.includes('phen')) {
      setReportType(2);
    } else {
      console.log("Filename does not contain 'gen' or 'phen'"); // Log to console
    }
    const reader = new FileReader();
    reader.onload = evt => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      let data = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: null
      });
  
      data[0] = data[0].map(colName => colName.replace(/\//g, '/'));
  
      const sampleColIndex = data[0].findIndex(col => col === 'Sample');
      if (sampleColIndex !== -1) {
        data[0].splice(sampleColIndex, 1, 'MANumber', 'Patient Name');
        let testNames = data[0].slice(sampleColIndex + 1);
        let tempStructuredData = [];
  
        for (let i = 1; i < data.length; i++) {
          let cellData = data[i][sampleColIndex] || ''; // Ensure cellData is a string
  
          // Regular Expression to match the MANumber format "MAxxxx-xx" or embedded within strings
          const maNumberRegex = /MA\d+-\d+/;
          const maNumberMatch = cellData.match(maNumberRegex);
          let maNumber = maNumberMatch ? maNumberMatch[0] : '';
          let remainingString = cellData.replace(maNumber, '').trim();
  
          // Ignore remaining numeric-only strings
          let patientName = remainingString.replace(/[\d-]+$/, '').trim();
  
          // If no MANumber found or remaining string is numeric-only, skip the current iteration
          if (!maNumber || /^\d+$/.test(remainingString)) {
            continue;
          }
  
          data[i].splice(sampleColIndex, 1, maNumber, patientName.length > 0 ? patientName : "N/A"); // Default patient name to "N/A" if empty
          let testStructuredData = [];
          testNames.forEach((testName, idx) => {
            let testResult = data[i][sampleColIndex + 1 + idx];
            if (testResult && testResult !== patientName) {
              testStructuredData.push({
                MANumber: maNumber,
                TestName: testName,
                TestResult: testResult
              });
            }
          });
  
          tempStructuredData.push(...testStructuredData);
        }
  
        // Set the structured data and log it
        setStructuredData(tempStructuredData);
      }
      setCols(data[0]);
      setRows(data.slice(1));
    };
    reader.readAsBinaryString(fileObj);
  };
  
  
  const closeAlert = () => {
    setAlertMessage(null);
    setRows([]);
    setCols([]);
    setStructuredData([]);
    setReportType(null);
    setIsLoading(false);
    setSampleTestFileContent(null);
    setMutationFileContent(null);
    setJsonData([]);

    setSelectedOption("");
    setSelectedSangerOption("");


    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };


  //save excel file
  const saveData = () => {
    setIsLoading(true); // Activate spinner
    orderService.saveTestResults(structuredData, reportType)
      .then(() => {
        setAlertMessage({
          type: 'success',
          message: 'Data saved successfully'
        });
        setIsLoading(false); // Deactivate spinner
      })
      .catch(err => {
        console.log(err);
        // Check if there's a response and a message in it
        const errorMessage = err.message;
        setAlertMessage({ type: 'danger', message: errorMessage });
        setIsLoading(false); // Deactivate spinner
      });
  };

  //save mutation txt file
  const saveMutationTxtUpload = () => {
    // Handle the text file upload logic here
    setIsLoading(true); // Activate spinner

    // Use jsonData here
    orderService.saveSangerMutationReport(jsonData)
      .then(() => {
        setAlertMessage({
          type: 'success',
          message: 'Data saved successfully'
        });
        setIsLoading(false); // Deactivate spinner
      })
      .catch(err => {
        console.log(err);
        const errorMessage = err.message;
        setAlertMessage({ type: 'danger', message: errorMessage });
        setIsLoading(false); // Deactivate spinner
      });
  };

  //save sample test txt file 
  const saveMutationSampleTestTxtUpload = () => {
    setIsLoading(true); // Activate spinner

    // Use jsonData here
    orderService.saveSangerSampleResult(jsonData)
      .then(() => {
        setAlertMessage({
          type: 'success',
          message: 'Data saved successfully'
        });
        setIsLoading(false); // Deactivate spinner
      })
      .catch(err => {
        console.log(err);
        const errorMessage = err.message;
        setAlertMessage({ type: 'danger', message: errorMessage });
        setIsLoading(false); // Deactivate spinner
      });
  }


  return (
    <>
      <Helmet>
        <title>Order Details</title>
      </Helmet>
      <PageHeader title="Order Details" className="mb-2" col="12">
        <Row>
          {alertMessage && (
            <Col md={12} className="mb-3">
              <div
                className={`alert alert-${alertMessage.type} d-flex justify-content-between align-items-center`}
                role="alert"
              >
                {alertMessage.message}
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeAlert}
                  aria-label="Close"
                ></button>
              </div>
            </Col>
          )}
          <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/orders/orders">Open Orders</Breadcrumb.Item>
              <Breadcrumb.Item active>Upload Test Results</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-end mt-n2">
            <PopoverComponent
              header="Enterprise Data Governance"
              body="Patient Detail"
            />
          </Col>
        </Row>
      </PageHeader>
      <Card className="card-body h-100">
        <Card.Body className="p-0 text-start mt-1">
          <Row>
            {selectedOption === "HEA Excel" && (
              <Col md={12}>
                <div className="alert alert-info">
                  Please upload the Genotype file first and the the Phenotype file for the full interpretation.
                </div>
              </Col>
            )}
            <Col md={3}>
              <Form.Group controlId="sectionDropdown" className="mb-3">
                <Form.Label>Select Section</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedOption}
                  onChange={e => {
                    setSelectedOption(e.target.value);
                    if (e.target.value !== 'Sanger') {
                      setSelectedSangerOption('');
                    }
                  }}
                >
                  <option value="">--Select--</option>
                  <option value="HEA Excel">HEA Excel</option>
                  <option value="Sanger">Sanger</option>
                </Form.Control>
              </Form.Group>
            </Col>
            {selectedOption === "Sanger" && (
              <Col md={3}>
                <Form.Group controlId="sangerDropdown" className="mb-3">
                  <Form.Label>Select Sanger Option</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedSangerOption}
                    onChange={e => setSelectedSangerOption(e.target.value)}
                  >
                    <option value="">--Select--</option>
                    <option value="Specimen Mutation">Specimen Mutation</option>
                    <option value="Specimen Sample Test">Specimen Sample Test</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
          </Row>

          {/* HEA Excel*/}
          {selectedOption === "HEA Excel" && (
            <>
              <Row>
                <Form.Label>Upload your Excel file</Form.Label>
              </Row>
              <Row>
                <Col md={8}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control
                      type="file"
                      onChange={fileHandler}
                      ref={fileInputRef}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <Col md={4} className="d-flex align-items-center">
                    <Button
                      onClick={saveData}
                      className="mb-3"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Processing...
                        </>
                      ) : (
                        'Upload Data'
                      )}
                    </Button>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="excelTable ExcelTable2007 table-container">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          {cols.map((col, idx) => (
                            <th key={idx}>{col}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, idx) => (
                          <tr key={idx}>
                            {row.map((cell, idx) => (
                              <td key={idx}>{cell}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {/* Specimen Mutation*/}
          {selectedSangerOption === "Specimen Mutation" && (
            <>
              <Row>
                <Form.Label>Upload Mutation .txt file</Form.Label>
              </Row>
              <Row>
                <Col md={8}>
                  <Form.Group controlId="txtFile" className="mb-3 d-flex">
                    <Form.Control
                      type="file"
                      accept=".txt"
                      onChange={handleMutationTxtUpload}
                      className="mr-2"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <Button
                    onClick={saveMutationTxtUpload}
                    className="mb-3"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Processing...
                      </>
                    ) : (
                      'Upload Mutation txt file Data'
                    )}
                  </Button>
                </Col>
              </Row>
              {mutationFileContent && (
                <Row className="mt-3">
                  <Col md={12}>
                    <Card>
                      <Card.Header>Mutation File Content</Card.Header>
                      <Card.Body>
                        <pre>{mutationFileContent}</pre>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}


          {/* Specimen Sample Test */}
          {selectedSangerOption === "Specimen Sample Test" && (
            <>
              <Row>
                <Form.Label>Upload Sample Test  .txt file</Form.Label>
              </Row>
              <Row>
                <Col md={8}>
                  <Form.Group controlId="txtFile" className="mb-3 d-flex">
                    <Form.Control
                      type="file"
                      accept=".txt"
                      onChange={handleMutationSampleTestTxtUpload}
                      className="mr-2"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <Button
                    onClick={saveMutationSampleTestTxtUpload}
                    className="mb-3"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Processing...
                      </>
                    ) : (
                      'Upload Mutation Sample Test txt file Data'
                    )}
                  </Button>
                </Col>
              </Row>
              {sampleTestFileContent && (
                <Row className="mt-3">
                  <Col md={12}>
                    <Card>
                      <Card.Header>Mutation File Content</Card.Header>
                      <Card.Body>
                        <pre>{sampleTestFileContent}</pre>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}




        </Card.Body>
      </Card>
    </>
  );
};

export default UploadTestResult;
