/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Breadcrumb, Spinner, Button, Modal, Form } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import { settings } from '../../config';
import { getItemFromStore } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';
import 'pages/patient/report-browser.scss';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import service from 'services/common-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';

const Orders = () => {
  const [patientsDetails, setPatientsDetails] = useState([]);
  const [informationModalVisible, setInformationModalVisible] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [institutionId, setInstitutionId] = useState(0);
  const [institutionName, setInstitutionName] = useState('');
  const [internalInstitutionName, setInternalInstitutionName] = useState('');
  const [bloodBank, setBloodBank] = useState('');
  const [attention, setAttention] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [code, setCode] = useState('');

  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  const [isLoading] = useState(false);
  const columns = [
 
    {
      accessor: 'institutionId',
      Header: 'Institution Id',
      headerProps: {
          className: 'text-start'
        },
        cellProps: {
          className: 'text-start'
        }
    },
    {
      accessor: 'internal',
      Header: 'Internal',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'institution1',
      Header: 'Institution',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'bloodBank',
      Header: 'Blood Bank',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start cell-description'
      }
    },
    {
      accessor: 'attention',
      Header: 'Attention',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start cell-description'
      }
    },
    {
      accessor: 'code',
      Header: 'Code',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start cell-description'
      }
    },
    {
      accessor: 'street',
      Header: 'Street',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start cell-description'
      }
    },
    {
      accessor: 'city',
      Header: 'City',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start cell-description'
      }
    },
    {
      accessor: 'Edit',
      Header: 'Edit',
      Cell: rowData => (
        // <Link to={`/release/editor/${rowData.row.original.releaseId}`}>
        //   <FontAwesomeIcon icon={faEdit} />
        // </Link>
        <Button type="button" variant="outline-primary" className="me-1 mb-1" size="sm"  
        onClick={() => onClickLaunch(
            rowData.row.original.institutionId,
            rowData.row.original.internal,
            rowData.row.original.institution1,
            rowData.row.original.street,
            rowData.row.original.city,
            rowData.row.original.bloodBank,
            rowData.row.original.attention,
            rowData.row.original.code
        )}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      )
    },
    {
      accessor: 'Delete',
      Header: '',
      Cell: rowData => (
        // <Link to={`/release/editor/${rowData.row.original.releaseId}`}>
        //   <FontAwesomeIcon icon={faEdit} />
        // </Link>
        <Button type="button" variant="outline-primary" className="me-1 mb-1" size="sm"  
        onClick={() => onDeleteClick(
            rowData.row.original.institutionId,
            rowData.row.original.institution1
        )}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      )
    }
  ];
  useEffect(() => {
    service.getInstitution()
    .then(orderList => {
        setPatientsDetails(orderList);
    })   
  }, []);

  function onDeleteClick(institutionId, institutionName) {
    setShowConfirm(true);
    setInstitutionId(institutionId);
    setInstitutionName(institutionName);
  }

  const onClickCancel = () => setShowConfirm(false);
  const onClickOk = () => { setShowConfirm(false); deleteInstitution(institutionId); }

  function deleteInstitution(institutionId) {
    service.deleteInstitution(institutionId)
    .then(institutionList => {
        setPatientsDetails(institutionList);
        toast(ConfirmationMessage("Institution Deleted", "The institution is deleted successfully!", "bg-success", "faCheckCircle"), {
          autoClose: true,
          //className: 'transparent',
          //style: {{background-color: transparent !important}},
          position: 'bottom-right',
          hideProgressBar: true,
          closeButton: false,
        });
    })  
  }

  function onClickAddInstitution() {
    setInformationModalVisible(true);
    //setKey(key);
     setInstitutionId(0);
     setInstitutionName('');
     setInternalInstitutionName('');
     setBloodBank('');
     setAttention('');
     setCity('');
     setStreet('');
     setCode('');
  }

  function clearData() {
    setInstitutionId(0);
    setInstitutionName('');
    setInternalInstitutionName('');
    setBloodBank('');
    setAttention('');
    setCity('');
    setStreet('');
    setCode('');
  }
 
  function onClickLaunch (
    institutionId,
    internal,
    institution1,
    street,
    city,
    bloodBank,
    attention,
    code
    ) {
    setInformationModalVisible(true);
   //setKey(key);
    setInstitutionId(institutionId);
    setInstitutionName(institution1);
    setInternalInstitutionName(internal);
    setBloodBank(bloodBank);
    setAttention(attention);
    setCity(city);
    setStreet(street);
    setCode(code);
  }

  function onSaveModal() {
   // e.preventDefault();

    const institution = {
      institutionId: institutionId,
      institutionName: institutionName,
      internalInstitution: internalInstitutionName,
      bloodBank: bloodBank,
      attention: attention,
      street: street,
      city: city,
      code: code
    }
    service.saveInstitutionDetails(institution)
        .then(institutionList => {
        setPatientsDetails(institutionList);
        setInformationModalVisible(false);
    });
    toast(ConfirmationMessage("Institution Saved", "The institution is saved successfully!", "bg-success", "faCheckCircle"), {
      autoClose: true,
      //className: 'transparent',
      //style: {{background-color: transparent !important}},
      position: 'bottom-right',
      hideProgressBar: true,
      closeButton: false,
    });   

    };
 

  const reportBrowserdesc = "Manage Institution";

  return (
    <>
      <Helmet>
        <title>Manage Institution</title>
      </Helmet>
      <PageHeader title="Manage Institution" className="mb-2" col="12">
      <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                Manage Institution
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                header = "Enterprise Data Governance"
                body = {reportBrowserdesc}
              />
          </Col>
          </Row>
      </PageHeader>
    
      <AdvanceTableWrapper
        columns={columns}
        data={patientsDetails}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">        
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
              <Button type="button" variant="outline-primary" className="me-1 mb-1" size="md"  
                onClick={() => onClickAddInstitution()}>
                     Add Institution
              </Button>
              </Col>
            </Row>
          </Card.Header>
          {
          isLoading ? <div className='text-center'><Spinner animation="border" /></div> :        
            <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            </Card.Body>
          }
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={patientsDetails.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
        <br />
      </AdvanceTableWrapper>

      <Modal size="lg" show={informationModalVisible}>
        <Modal.Header>
            Institution
          <FalconCloseButton onClick={() => setInformationModalVisible(false)} />
        </Modal.Header>
        <Modal.Body>
        <Form>
        <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={6} controlId="internalInstitutionName">
                <Form.Label>
                  Internal
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Internal"
                  value={internalInstitutionName}
                  name="internalInstitutionName"
                  onChange={({ target }) => setInternalInstitutionName(target.value)}
                />
            </Form.Group>
            <Form.Group as={Col} lg={6} controlId="institutionName">
                <Form.Label>
                  Institution Name
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Institution Name"
                  value={institutionName}
                  name="institutionName"
                  onChange={({ target }) => setInstitutionName(target.value)}
                />
            </Form.Group>
        </Row>
        <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={4} controlId="bloodBank">
                <Form.Label>
                  Blood Bank
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Blood Bank"
                  value={bloodBank}
                  name="bloodBank"
                  onChange={({ target }) => setBloodBank(target.value)}
                />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="attention">
                <Form.Label>
                  Attention
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Attention"
                  value={attention}
                  name="attention"
                  onChange={({ target }) => setAttention(target.value)}
                />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="code">
                <Form.Label>
                  Code
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="code"
                  value={code}
                  name="code"
                  onChange={({ target }) => setCode(target.value)}
                />
            </Form.Group>
        </Row>
        <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={6} controlId="street">
                <Form.Label>
                  Street
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Street"
                  value={street}
                  name="street"
                  onChange={({ target }) => setStreet(target.value)}
                />
            </Form.Group>
            <Form.Group as={Col} lg={6} controlId="city">
                <Form.Label>
                  City
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="city"
                  value={city}
                  name="city"
                  onChange={({ target }) => setCity(target.value)}
                />
            </Form.Group>
        </Row>
          <br/>
          </Form> 
        </Modal.Body>
        <Modal.Footer>
        <Button
            type="button"
            className="btn btn-primary"
            onClick={() => clearData()}
          >
            Clear
          </Button>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => onSaveModal()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirm} onHide={onClickCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Delete <span className="text-danger">{institutionName} </span></Modal.Title>
          <FalconCloseButton onClick={onClickCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete it?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickCancel} >Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Orders;