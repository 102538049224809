/* eslint-disable prettier/prettier */
import { faInfoCircle, faWrench, faUserCog, faPoll } from "@fortawesome/free-solid-svg-icons";
import AuthService from "services/auth-service";


export const orderRoutes = {
  label: 'Orders',
  icon: faPoll,
  children: [   
    {
      name: 'Orders',
      to: '/orders/orders',
      active: true,      
      permissions: [AuthService.claims.all]
    }
  ]
};

export const uploadTestResultRoutes = {
  label: 'Upload Test Result',
  icon: faPoll,
  children: [   
    {
      name: 'Upload Test Result',
      to: '/orders/uploadTestResult',
      active: true,      
      permissions: [AuthService.claims.all]
    }
  ]
};

export const sameDayHistory = {
  label: 'Recent Accessioned Records',
  icon: faPoll,
  children: [   
    {
      name: 'Recent Accessioned Records',
      to: '/orders/recentAccessionedRecords',
      active: true,      
      permissions: [AuthService.claims.all]
    }
  ]
};

export const dataEntryRoutes = {
  label: 'Data Entry',
  icon: faPoll,
  children: [   
    {
      name: 'Data Entry',
      to: '/dataEntry/dataEntryForm',
      active: true,      
      permissions: [AuthService.claims.all]
    }
  ]
};
export const reportsRoutes = {
  label: 'Sample Search',
  icon: faPoll,
  children: [   
    {
      name: 'Sample Search',
      to: '/patient/patientSearch',
      active: true,      
      permissions: [AuthService.claims.all]
    }
  ]
};

export const servicesRoutes = {
  label: 'Order Management',
  icon: faUserCog,
  
  children: [
    {
      name: 'A',
      // to:"/",
      active: true,      
      children: [
        {
          name: 'Input Orders', 
          to: '',
          active: true,
          permissions: [AuthService.claims.viewDataRefresh]
        } 
      ]
    },
    {
      name: 'O',
      to:"/",
      active: true,
      children: [
        {
          name: 'Order List',   
          to: '',
          active: true,          
          permissions: [AuthService.claims.viewDataRefresh]
        },
        {
          name: 'Upload Test Results',
          to: '',
          active: true,
          
          // permissions: [AuthService.claims.all]
          permissions: [AuthService.claims.viewDataRefresh]
        },
        {
          name: 'View Draft Report', 
          to: '',
          active: true,
          permissions: [AuthService.claims.viewDataRefresh]
        },
        {
          name: 'Manage Data Dictionary',
          to: '/kpi/kpi',
          active: true,
          permissions: [AuthService.claims.viewKpi]
        }  
      ]
    },
  ]
};


export const settingsRoutes = {
  label: 'Settings',
  icon: faWrench,
  children: [    
    {
      name: 'Manage Users',
      
      to: '/admin/UserBrowser',
      active: true,
      //permissions: [AuthService.claims.all]
      permissions: [AuthService.claims.fetchUsers, AuthService.claims.viewUserDetails]
    },
    {
      name: 'Manage Roles',
      
      to: '/admin/UserRoles',
      active: true,
      //permissions: [AuthService.claims.all]
      permissions: [AuthService.claims.fetchRoles] 
    },
    {
      name: 'Manage Institution',
      
      to: '/admin/ManageInstitution',
      active: true,
      permissions: [AuthService.claims.fetchRoles]
    },
   
  ]
};
export const orderManagementRoutes = {
  label: 'Order Management',
  icon: faInfoCircle,
  children: [    
    {
      name: 'Accessioning',
    
      to: '/dataEntry/dataEntryForm',
      active: true,
      permissions: [AuthService.claims.viewOrders]
    },
    {
      name: 'Open Orders',
    
      to: '/orders/orders',
      active: true,
      permissions: [AuthService.claims.viewOrders]
    },
    {
      name: 'Upload Test Results',
    
      to: '/orders/uploadTestResult',
      active: true,
      permissions: [AuthService.claims.viewOrders]
    },
    {
      name: 'Recent Accessioned Records',
    
      to: '/orders/recentAccessionedRecords',
      active: true,
      permissions: [AuthService.claims.viewOrders]
    },
    {
      name: 'Same Day History',
    
      to: '/orders/sameDayHistory',
      active: true,
      permissions: [AuthService.claims.viewOrders]
    }
  ]
};

export const reportManagementRoutes = {
  label: 'Report Management',
  icon: faInfoCircle,
  children: [  
    {
      name: 'HEA Draft Report',
    
      to: '/orders/draftReport',
      active: true,
      permissions: [AuthService.claims.viewReports]
    }, 
    {
      name: 'HEA Final Report',
    
      to: '/BoldReports/hea_genotype_report',
      active: true,
      permissions: [AuthService.claims.viewReports]
    },
    {
      name: 'HEA Reflex Draft Report',
    
      to: '/orders/reflexDraftReport',
      active: true,
      permissions: [AuthService.claims.viewReports]
    }, 
    {
      name: 'HEA Reflex Final Report',
    
      to: '/BoldReports/heaReflex_final_report',
      active: true,
      permissions: [AuthService.claims.viewReports]
    },
    {
      name: 'Sanger DNA Draft Report',
    
      to: '/sangerSequencing/sangerDraftReport',
      active: true,
      permissions: [AuthService.claims.viewReports]
    },
    {
      name: 'Sanger DNA Final Report',
    
      to: '/BoldReports/sanger_final_report',
      active: true,
      permissions: [AuthService.claims.viewReports]
    }
  ]
};

export const documentationRoutes = {
  label: 'documentation',
  icon: faInfoCircle,
  children: [    
    {
      name: 'FAQ',
      to: '/faq',
      active: true,
      permissions: [AuthService.claims.all]
    },
    {
      name: 'User Guide',
      externalLink: "https://nybc.sharepoint.com/:w:/r/sites/EADS/_layouts/15/Doc.aspx?sourcedoc=%7BF750848A-53FA-4DEC-A491-6E7646E85F8E%7D&file=User_Guide.docx&action=default&mobileredirect=true",
      active: true,
      permissions: [AuthService.claims.all]
    }
  ]
};


export default [
  reportsRoutes,
  orderRoutes,
  uploadTestResultRoutes,
  sameDayHistory,
  dataEntryRoutes,
  orderManagementRoutes,
  reportManagementRoutes,
  // dataGovernanceRoutes,
  servicesRoutes,
  settingsRoutes,
  documentationRoutes
];

