/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import service from 'services/dataEntry-service';
import PageHeader from 'components/common/PageHeader';
import 'pages/patient/report-browser.scss';
import PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
// import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import AuthService from 'services/auth-service';
import CommonService from 'services/common-service';
import orderService from 'services/order-service';

const profile = AuthService.getCurrentUserProfile();

const DataEntryForm = () => {
  const [formData, setFormData] = useState({
    bloodHubOrderId: '',
    customOrderId: 0,
    testRequested: '',
    referralReason2: '',
    referralReason3: '',
    patientFirstName: '',
    patientMiddleName: '',
    patientLastName: '',
    patientDOB: '',
    patientCode: 0,
    sampleNo: '',
    sampleDate: '',
    patientEthnicity: '',
    patientGender: '',
    patientABORH: '',
    requestingFacility: '',
    mrNumber: '',
    collectionDate: '',
    collectionTime: '',
    testCategoryId: '',
    bloodCenterNumber: '',
    aborh: '',
    comment: '',
    diagnosis: ''
  });
  var emailSplit = profile.email.split('@');

  const [testCategory, setTestCategory] = useState([]);
  const [bloodCenterCode, setBloodCenterCode] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ message: null, type: null });
  const [oneDivision] = useState(emailSplit[1].split('.')[0].toUpperCase());
  const [serviceCode, setServiceCode ] = useState('');
  const [ bloodCenterId, setBloodCenterId] = useState(0);
  const [institutions, setInstitutions] = useState([]);
  const [institutionsMain, setInstitutionsMain] = useState([]);
  const [ethnicity, setEthnicity] = useState([]);
  const [referralReason, setReferralReason] = useState([]);
  const [gender, setGender] = useState([
    {label: 'Male', value: 'Male'}, {label: 'Female', value: 'Female'}, {label: 'Not Provided', value: 'Not Provided'}, {label: 'Unknown', value: 'Unknown'}]);

  useEffect(() => {
    service.fetchBloodCenterCode().then((blCode) => {
      setBloodCenterCode(blCode);
      var bl = blCode.find(f=> f.label == oneDivision)
      var blCenterCode = bl.value;

      setBloodCenterId(blCenterCode);
    });

    service.fetchTestCategoryList().then((testCategory) => {
      setTestCategory(testCategory);
    });

    // CommonService.getInstitution()
    // .then(institutionList => {
    //   institutionList.forEach(item => {
    //     renameKey(item, 'institutionId', 'value');
    //     renameKey(item, 'institution1', 'label');
    //   });
    //     setInstitutions(institutionList);
    //     setInstitutionsMain(institutionList);
    // })

    var institutionPromise = CommonService.getInstitution();
    var ethnicityPromise = orderService.getEthnicity();
    var referralPromise = orderService.getReferralReason();

    Promise.all([institutionPromise, ethnicityPromise, referralPromise]).then(([institution, ethnic, referral])=>{

      institution.forEach(item => {
        renameKey(item, 'institutionId', 'value');
        renameKey(item, 'institution1', 'label');
      });
        setInstitutions(institution);
        setInstitutionsMain(institution);


    ethnic.forEach(item => {
      renameKey(item, 'ethnicityId', 'value');
      renameKey(item, 'ethnicity', 'label');
    });
      setEthnicity(ethnic);

    referral.forEach(item => {
      renameKey(item, 'ReferralId', 'value');
      renameKey(item, 'referralReason', 'label');
    });
      setReferralReason(referral);
      
      setGender(gender);

  });

  }, []);

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  const reportBrowserdesc = "Patient Search";

  function getBloodHubDetails(e) {
    service.getBloodHubDetails(e.target.value)
      .then(orderDetails => {
        if (orderDetails.order.service_code == 'DNA') {
          setServiceCode(2);
          const testRequested = orderDetails.order.response_package.response_pages[3].response_fields[0].value ? orderDetails.order.response_package.response_pages[3].response_fields[0].value + "; " : ""
                              + orderDetails.order.response_package.response_pages[3].response_fields[1].value ? orderDetails.order.response_package.response_pages[3].response_fields[1].value + "; "  : ""
                              + orderDetails.order.response_package.response_pages[3].response_fields[2].value ? orderDetails.order.response_package.response_pages[3].response_fields[2].value + "; "  : ""
                              + orderDetails.order.response_package.response_pages[3].response_fields[3].value ? orderDetails.order.response_package.response_pages[3].response_fields[3].value + "; "  : ""
                              + orderDetails.order.response_package.response_pages[3].response_fields[4].value ? orderDetails.order.response_package.response_pages[3].response_fields[4].value + "; "  : ""
                              + orderDetails.order.response_package.response_pages[3].response_fields[5].value ? orderDetails.order.response_package.response_pages[3].response_fields[5].value + "; "  : ""
        setFormData({
          bloodHubOrderId: orderDetails.order.id,
          testRequested: testRequested,
          patientFirstName: orderDetails.order.response_package.response_pages[1].response_fields[1].value,
          patientLastName: orderDetails.order.response_package.response_pages[1].response_fields[0].value,
          patientMiddleName: orderDetails.order.response_package.response_pages[1].response_fields[2].value,
          patientCode: orderDetails.order.response_package.response_pages[1].response_fields[3].value,
          patientGender: orderDetails.order.response_package.response_pages[1].response_fields[4].value,
          patientDOB: orderDetails.order.response_package.response_pages[1].response_fields[5].value,
          patientEthnicity: orderDetails.order.response_package.response_pages[1].response_fields[8].value,
          requestingFacility: orderDetails.order.customer_name,
          collectionDate: orderDetails.order.response_package.response_pages[0].response_fields[0].value,
        });
      } else {
        setServiceCode(1);
        setFormData({
          bloodHubOrderId: orderDetails.order.id,
          testRequested: orderDetails.order.service_name,
          patientFirstName: orderDetails.order.response_package.response_pages[0].response_fields[4].value,
          patientLastName: orderDetails.order.response_package.response_pages[0].response_fields[3].value,
          patientMiddleName: orderDetails.order.response_package.response_pages[0].response_fields[5].value,
          patientCode: orderDetails.order.response_package.response_pages[0].response_fields[8].value,
          patientGender: orderDetails.order.response_package.response_pages[0].response_fields[7].value,
          patientDOB: orderDetails.order.response_package.response_pages[0].response_fields[6].value,
          patientEthnicity: orderDetails.order.response_package.response_pages[0].response_fields[10].value,
          requestingFacility: orderDetails.order.customer_name,
          collectionDate: orderDetails.order.response_package.response_pages[0].response_fields[0].value,
          aborh: orderDetails.order.response_package.response_pages[2].response_fields[4].value,
          diagnosis: orderDetails.order.response_package.response_pages[0].response_fields[12].value,
        });
      }
      })
      .catch(error => {

        let errorMessage = 'An error occurred';
        if (error && error.error) {
          errorMessage = error.error;
        }

        setAlertMessage({
          message: errorMessage,
          type: 'danger'
        });
      });

  }

  function onClickSave(e) {
    e.preventDefault();
    const dob1 = formData.patientDOB.replace("/", "-");
    const dateOfBirth = dob1.replace("/", "-");

    const cdate = formData.collectionDate.replace("/", "-");
    const clDate = cdate.replace("/", "-");


    const order = {
      bloodHubOrderId: formData.bloodHubOrderId,
      customOrderId: formData.customOrderId,
      testRequest: formData.testRequested,
      patientFirstName: formData.patientFirstName,
      patientLastName: formData.patientLastName,
      patientMiddleName: formData.patientMiddleName,
      mrNumber: formData.patientCode,
      patientGender: formData.patientGender,
      patientDOB: dateOfBirth,
      patientEthnicity: formData.patientEthnicity,
     aborh: formData.aborh,
     collectionDate: clDate,
      bloodCenterId: bloodCenterId,
      testCategoryId: serviceCode,
      institution: formData.requestingFacility,
      diagnosis: formData.diagnosis,
      referralReason2: formData.referralReason2,
      referralReason3: formData.referralReason3,
      referralNotes: formData.comment
    }
    service.saveOrderDetails(order)
      .then(() => {
        location.href = `/orders/orders`;
      })

      .catch(err => {
        console.log(err);

        const errorMessage = err.response && err.response.data.message ? err.response.data.message : "Blood Center or Test Category fields are missing";
        setAlertMessage({
          message: errorMessage,
          type: 'danger'
        });
      });
  }

  function onInputChangeOwner(inputValue) {
    if (inputValue.length > 0) {
      // activeDirectoryService.searchPeople(inputValue).then(res => {
      //   const validList = res.value.filter(function(item) {return item.userPrincipalName !== null;});
      //   const owner = validList.map(user => { return { label: user.displayName, value: user.userPrincipalName, id: user.id } });            
      //   setInstitutions(owner);
      //   // console.log(formData.ownerEmail);
      // });

      setInstitutions(institutions.filter(ins => ins.label.includes(inputValue)));
    }
    else
    {
      setInstitutions(institutionsMain);
    }
  }

  const closeAlert = () => {
    setAlertMessage({ message: null, type: null });
  };


  function onChangeTestCategory(e) {
   setFormData({ ...formData, testCategoryId: e.value })
   setServiceCode(e.value);
  }

  function onChangeBloodCenter(e) {
    setFormData({ ...formData, bloodCenterNumber: e.value })
    setBloodCenterId(e.value);
  }

  return (
    <>
      <Helmet>
        <title>Accessioning</title>
      </Helmet>
      <PageHeader title="Accessioning" className="mb-2" col="12">
        <Row>
          <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>
                Accessioning
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-end mt-n2">
            <PopoverComponent
              header="Enterprise Data Governance"
              body={reportBrowserdesc}
            />
          </Col>
        </Row>
        <Row className="mb-2 g-2">
          <Form.Group as={Col} lg={12}>
            {alertMessage.message && (
              <Col md={12} className="mb-3">
                <div
                  className={`alert alert-${alertMessage.type} d-flex justify-content-between align-items-center`}
                  role="alert"
                >
                  {alertMessage.message}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeAlert}
                    aria-label="Close"
                  ></button>
                </div>
              </Col>
            )}
          </Form.Group>
        </Row>

      </PageHeader>
      <Card className="h-100">
        <Card.Header>
          <Form onSubmit={onClickSave}>

            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={6} controlId="bloodHubOrderId">
                <Form.Label>
                  BloodHubOrderId
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="BloodHub Order Id"
                  value={formData.bloodHubOrderId}
                  name="bloodHubOrderId"
                  onBlur={getBloodHubDetails}
                  onChange={(e) => { setFormData({ ...formData, bloodHubOrderId: e.target.value }) }}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback> */}
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="customOrderId">
                <Form.Label>
                  CustomOrderId
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Custom Order Id"
                  value={formData.customOrderId}
                  name="customOrderId"
                  onChange={(e) => { setFormData({ ...formData, customOrderId: e.target.value }) }}
                />

              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={6} controlId="bloodCenterCode">
                <Form.Label>
                  Blood Center
                </Form.Label>
                <Select
                  // className={validateSchedule?'':'validation-error'}
                  closeMenuOnSelect={true}
                  options={bloodCenterCode}
                  placeholder='Select...'
                  //classNamePrefix="react-select"
                  value={bloodCenterCode.find(item => item.label === oneDivision)}
                  onChange={onChangeBloodCenter}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6} controlId="testCategory">
                <Form.Label>
                  Test Category
                </Form.Label>
                <Select
                  // className={validateSchedule?'':'validation-error'}
                  closeMenuOnSelect={true}
                  options={testCategory}
                  placeholder='Select...'
                  //classNamePrefix="react-select"
                  value={testCategory.find(item => item.value === serviceCode)}
                  onChange={onChangeTestCategory}
                />
              </Form.Group>

            </Row>

            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4} controlId="testRequested">
                <Form.Label>
                  Test Requested<span className="text-danger">*</span>
                </Form.Label>
                {/* <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Test Requested"
                  value={formData.testRequested}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, testRequested: e.target.value }) }}
                /> */}
                <Select
                  // className={validateSchedule?'':'validation-error'}
                  closeMenuOnSelect={true}
                  options={referralReason}
                  placeholder='Select...'
                  //classNamePrefix="react-select"
                  value={referralReason.find(item => formData.testRequested && formData.testRequested.includes(item.label))}
                  onChange={(e) => { setFormData({ ...formData, testRequested: e.label }) }}
                />
              </Form.Group>
              
              <Form.Group as={Col} lg={4} controlId="testRequested">
                <Form.Label>
                  Referral Reason 2
                </Form.Label>
                {/* <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Referral Reason 2"
                  value={formData.referralReason2}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, referralReason2: e.target.value }) }}
                /> */}
                 <Select
                  // className={validateSchedule?'':'validation-error'}
                  closeMenuOnSelect={true}
                  options={referralReason}
                  placeholder='Select...'
                  //classNamePrefix="react-select"
                  value={referralReason.find(item => formData.referralReason2 && formData.referralReason2.includes(item.label))}
                  onChange={(e) => { setFormData({ ...formData, referralReason2: e.label }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="testRequested">
                <Form.Label>
                  Referral Reason 3
                </Form.Label>
                {/* <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Referral Reason 3"
                  value={formData.referralReason3}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, referralReason3: e.target.value }) }}
                /> */}
                 <Select
                  // className={validateSchedule?'':'validation-error'}
                  closeMenuOnSelect={true}
                  options={referralReason}
                  placeholder='Select...'
                  //classNamePrefix="react-select"
                  value={referralReason.find(item => formData.referralReason3 && formData.referralReason3.includes(item.label))}
                  onChange={(e) => { setFormData({ ...formData, referralReason3: e.label }) }}
                />
              </Form.Group>
              
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4} controlId="patientFirstName">
                <Form.Label>
                  Patient First Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Patient First Name"
                  value={formData.patientFirstName}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, patientFirstName: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="patientMiddleName">
                <Form.Label>
                  Patient Middle Name
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Middle Name"
                  value={formData.patientMiddleName}
                  name="patientLastName"
                  onChange={(e) => { setFormData({ ...formData, patientMiddleName: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="patientLastName">
                <Form.Label>
                  Patient Last Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Last Name"
                  value={formData.patientLastName}
                  name="patientLastName"
                  onChange={(e) => { setFormData({ ...formData, patientLastName: e.target.value }) }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={6} controlId="patientFirstName">
                <Form.Label>
                  Patient Date Of Birth<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Date Of Birth"
                  value={formData.patientDOB}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, patientDOB: e.target.value }) }}
                />
              </Form.Group>             
              <Form.Group as={Col} lg={6} controlId="patientLastName">
                <Form.Label>
                  Sample ID# 2
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Code"
                  value={formData.patientCode}
                  name="patientLastName"
                  onChange={(e) => { setFormData({ ...formData, patientCode: e.target.value }) }}
                />
              </Form.Group>
            </Row>

            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4} controlId="patientFirstName">
                <Form.Label>
                  Patient Gender
                </Form.Label>
                {/* <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Sample No"
                  value={formData.patientGender}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, patientGender: e.target.value }) }}
                /> */}
                 <Select
                  // className={validateSchedule?'':'validation-error'}
                  closeMenuOnSelect={true}
                  options={gender}
                  placeholder='Select...'
                  //classNamePrefix="react-select"
                  value={gender.find(item => item.label === formData.patientGender)}
                  onChange={(e) => { setFormData({ ...formData, patientGender: e.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="patientFirstName">
                <Form.Label>
                  Patient Ethnicity
                </Form.Label>
                {/* <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Ethnicity"
                  value={formData.patientEthnicity}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, patientEthnicity: e.target.value }) }}
                /> */}
                 <Select
                  // className={validateSchedule?'':'validation-error'}
                  closeMenuOnSelect={true}
                  options={ethnicity}
                  placeholder='Select...'
                  //classNamePrefix="react-select"
                  value={ethnicity.find(item => item.label === formData.patientEthnicity)}
                  onChange={(e) => { setFormData({ ...formData, patientEthnicity: e.label }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="patientLastName">
                <Form.Label>
                  ABORH
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="ABORH"
                  value={formData.aborh}
                  name="patientLastName"
                  onChange={(e) => { setFormData({ ...formData, aborh: e.target.value }) }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={6} controlId="requestingFacility">
                <Form.Label>
                  Requesting Facility
                </Form.Label>
                {/* <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Requesting Facility"
                  value={formData.requestingFacility}
                  name="requestingFacility"
                  onChange={(e) => { setFormData({ ...formData, requestingFacility: e.target.value }) }}
                /> */}
                <Select
                    controlId="selectOwner"
                    closeMenuOnSelect={true}
                    options={institutions}
                    placeholder='search...'
                    //className={ValidateOwnerEmail?'':'validation-error'}
                    value={institutions.find(item => item.label === formData.requestingFacility)}
                    onChange={e => {setFormData({...formData, requestingFacility: e.label, ownerName: e.label});}}
                    onInputChange={onInputChangeOwner}
                  />
              </Form.Group>
              <Form.Group as={Col} lg={3} controlId="diagnosis">
                <Form.Label>
                  Diagnosis
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="diagnosis"
                  value={formData.diagnosis}
                  name="diagnosis"
                  onChange={(e) => { setFormData({ ...formData, diagnosis: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={3} controlId="collectionDate">
                <Form.Label>
                  Collection Date
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="collectionDate"
                  value={formData.collectionDate}
                  name="collectionDate"
                  onChange={(e) => { setFormData({ ...formData, collectionDate: e.target.value }) }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={12} controlId="patientFirstName">
                <Form.Label>
                  Comments
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  as="textarea" rows="2"
                  placeholder="Comments"
                  onChange={(e) => { setFormData({ ...formData, comment: e.target.value }) }}
                  value={formData.comment}
                  name="comment"
                />
              </Form.Group>
            </Row>

            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                type="submit"
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
            </div>
          </Form>
        </Card.Header>
      </Card>

    </>
  );
};

export default DataEntryForm;
