/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';

const BloodLossSop = () => {

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    useEffect(() => {
        //console.log("open new url");
        openInNewTab('https://nybc.sharepoint.com/sites/NYBCeEADS/EADS%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FNYBCeEADS%2FEADS%20Documents%2FQA%2FHemetrics%20SOP%2FPortal%20PDF%2FEW%2DIT%2DSOP%2D%23%23%23%23%20NYBC%20Blood%20Loss%2Epdf&viewid=3d0f7dfa%2Db63c%2D4912%2D988b%2D710732adb098&parent=%2Fsites%2FNYBCeEADS%2FEADS%20Documents%2FQA%2FHemetrics%20SOP%2FPortal%20PDF')
        history.back();
    },[]);

    return (
        <></>
    );
};

export default BloodLossSop;