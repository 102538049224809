/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Breadcrumb } from 'react-bootstrap';
import userService from '../../services/user-service';
import catalogService from '../../services/catalog-service';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import PageHeader from 'components/common/PageHeader';
import notificationService from '../../services/notification-service';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import { Helmet } from 'react-helmet';

// const organizerOptions = [
//   { value: '', label: 'None', selected: true },
//   { value: 'auth', label: 'Auth' },
//   { value: 'ih', label: 'Immunohematology' },
//   { value: 'dg', label: 'Data Governance' },
//   { value: 'finance', label: 'Finance' }
// ];

const UserRoleEditor = () => {
  const profile = JSON.parse(localStorage.getItem('userProfile'));
  let params = useParams();
  var [roles, setRoles] = useState([]);
  const [assignedPermissions, setAssignedPermission] = useState([]);
  // const [value, setValue] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [disable, setDisable] = useState(true);
  const fillMsg = 'Please fill in this field';
  const [validateName, setValidateName] = useState(true);
  const [validateMsg, setValidateMsg] = useState(fillMsg);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // const [validateSubdomain, setValidateSubdomain] = useState(true);
  useEffect(() => {
    //loadUsers
    const selectedRolePromise = userService.getRole(params.roleId);
    //loadRoles
    const allPermissionsPromise = userService.fetchPermissions();
    const usersDropdownPromise = userService.getAllUsersDropdown();
    Promise.all([
      selectedRolePromise,
      allPermissionsPromise,
      usersDropdownPromise
    ]).then(
      ([selectedRole, allPermissions, usersDropdown]) => {
        allPermissions.forEach(item => {
          renameKey(item, 'permissionId', 'value');
          renameKey(item, 'title', 'label');
        });
        selectedRole.rolePermissions.forEach(item => {
          renameKey(item, 'permissionId', 'value');
          renameKey(item, 'title', 'label');
        });
      
        setRoles(selectedRole);
        // setSelectedDomain(selectedRole.domainId);
        setPermissions(allPermissions);
      //  setTempPermissions(allPermissions);
        setAssignedPermission(selectedRole.rolePermissions);
        setUsersDropdown(usersDropdown);
        
        if (!selectedRole.isDefault) {
          userService.getUserIdByRole(params.roleId).then(userId => {
            setSelectedUsers(userId);
        });

        }           
      }
    );
    setDisable(true);
  }, []);

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  const handleSubmit = e => {
    e.preventDefault();
    const validate = validateFields();
    if (validate === false) {
      return;
    }
    //console.log("Clicked");
    if (e.target.value == 'save') {
      assignedPermissions.forEach(item => {
        renameKey(item, 'value', 'permissionId');
        renameKey(item, 'label', 'permission');
        item['title'] = '';
        item['rolePermissions'] = [];
      });
      selectedUsers.forEach(item => {
        renameKey(item, 'value', 'id');
      });
      var role = { id: roles.roleId, name: roles.name, users: roles.isDefault? [] : selectedUsers };
      userService.updateRoles(role).then(() => {
        userService
          .AddRolePermissions(assignedPermissions, roles.roleId)
          .then(() => {
            // toast('Role(s) Permission Saved Successfully!', {
            //   autoClose: true,
            //   className: 'toast-container success'
            // });
            toast(ConfirmationMessage("Role(s) Saved", "Role(s) Permission Saved Successfully!", "bg-success", "faCheckCircle"), {
              autoClose: true,
              //className: 'transparent',
              //style: {{background-color: transparent !important}},
              position: 'bottom-right',
              hideProgressBar: true,
              closeButton: false,
            });
            assignedPermissions.forEach(item => {
              renameKey(item, 'permissionId', 'value');
              renameKey(item, 'permission', 'label');
            });
            setDisable(true);
            //window.location.href = '/admin/UserRoles';
          });
      });
    }

    if (e.target.value == 'delete') {
      // Delete User Functionality
      
          userService.fetchAllAssignedUsers(roles.id).then ((user) => {
            let reciepients = [];
            user.map(u => {reciepients.push(u.email);} )
            const messageBody = `This role has been removed: ${roles.name}.`;        
            const notificationRequest = { applicationName:"Hemetrics", type: "role", messageHeader: roles.name, messageContent: messageBody, createdBy:profile.email, recipientEmailList: reciepients, recipientEmailAddress: ""};
            userService.deleteRole(roles.id)
            .then(() => {
              // toast(`Roles Deleted Successfully!`, {
              //   autoClose: true,
              //   className: 'toast-container success'
              // });
              toast(ConfirmationMessage("Role(s) Deleted", "Role(s) Deleted Successfully!", "bg-success", "faCheckCircle"), {
                autoClose: true,
                //className: 'transparent',
                //style: {{background-color: transparent !important}},
                position: 'bottom-right',
                hideProgressBar: true,
                closeButton: false,
              });
              notificationService.AddNotificationToSpecificUser(notificationRequest)
              .then(() => {
                  catalogService.refreshCatalogCache()
                  .then(()=>{          
                  window.location.href = '/admin/UserRoles';
                  })              
              })
            });      
        })
    }
  };

  const handleChange = value => {
    setRoles({ ...roles, name: value });
    setDisable(false);
  };

  const [validateRoles, setValidateRoles] = useState(true);
  const validateFields = () => {
    if (roles.name) {
      setValidateName(true);
    } else {
      setValidateMsg(fillMsg);
      setValidateName(false);
      return false;
    }
    if (assignedPermissions.length > 0) {
      setValidateRoles(true);
    } else {
      setValidateRoles(false);
      return false;
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>Edit Roles</title>
      </Helmet>
      <Card>
        <PageHeader title="Edit Roles" className="mb-2">
        <Breadcrumb>  
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/userroles">Manage Roles</Breadcrumb.Item>
          <Breadcrumb.Item active>Edit</Breadcrumb.Item>  
        </Breadcrumb>
        </PageHeader>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={8} controlId="roleName">
                <Form.Label>
                  Role Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!validateName}
                  type="text"
                  placeholder="Role Name"
                  value={roles.name}
                  name="roleName"
                  onChange={({ target }) => handleChange(target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={12}>
              <Form.Label>
                Assign permissions<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  className={validateRoles ? '' : 'validation-error'}
                  closeMenuOnSelect={false}
                  options={permissions}
                  placeholder="Select"
                  isMulti
                  //classNamePrefix="react-select"
                  value={assignedPermissions}
                  onChange={value => {
                    setAssignedPermission(value);
                    setDisable(false);
                  }}
                />
                {!validateRoles && (
                  <span className="validation-error-msg">
                    Please fill out this field.
                  </span>
                )}
              </Form.Group>
            { (roles.isDefault !== true) && <Form.Group as={Col} lg={12}>
                  <Form.Label>
                  Users
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={usersDropdown}
                  placeholder="Select"
                  isMulti
                  //classNamePrefix="react-select"
                  value={selectedUsers}
                  onChange={value => {
                    setSelectedUsers(value);
                    setDisable(false);
                  }}
                />
            </Form.Group> }
          </Row>
            
            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="save"
                disabled={disable}
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>

              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="delete"
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserRoleEditor;
