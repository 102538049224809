/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import AuthService from '../../../services/auth-service';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { Helmet } from 'react-helmet';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userService from 'services/user-service';
const ProfileSettings = () => {
  const [formData, setFormData] = useState({
    firstName: 'Anthony',
    lastName: 'Hopkins',
    email: 'anthony@gmail.com',
    phone: '+44098098304',
    heading: 'Software Engineer',
    intro:
      'Dedicated, passionate, and accomplished Full Stack Developer with 9+ years of progressive experience working as an Independent Contractor for Google and developing and growing my educational social network that helps others learn programming, web design, game development, networking. I’ve acquired a wide depth of knowledge and expertise in using my technical skills in programming, computer science, software development, and mobile app development to developing solutions to help organizations increase productivity, and accelerate business performance. It’s great that we live in an age where we can share so much with technology but I’m but I’m ready for the next phase of my career, with a healthy balance between the virtual world and a workplace where I help others face-to-face. There’s always something new to learn, especially in IT-related fields. People like working with me because I can explain technology to everyone, from staff to executives who need me to tie together the details and the big picture. I can also implement the technologies that successful projects need.'
  });
  const userProfile = AuthService.getCurrentUserProfile();
  useEffect(()=>{
    userService.getSignature()
      .then(blob => {       
        if (blob) { 
          setFile(URL.createObjectURL(blob));
        }
    });
  }, []);
  const handleChange = e => {
    //console.log(userProfile);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  // const handleSubmit = e => {
  //   e.preventDefault();
  // };
  const [file, setFile] = useState();
  const [msg, setMsg] = useState();
  function handleFileChange(e) {
    const file = e.target.files[0];
    if(file) {
      if (file.size < 5*1024*1024) { // 5mg
        const formData = new FormData();
        formData.append('file', file);
        userService.addSignature(formData).then(() =>{
          setMsg(null);
          setFile(URL.createObjectURL(e.target.files[0]));
        });
      } else {
        setMsg('The file is too big.');
      }
    }
  }
  function handleRemove() {
    userService.removeSignature().then(()=>{
      setFile(null);
      setMsg(null);
    })
}

  return (
    <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>
      <Card>
        <FalconCardHeader title="Profile Settings" />
        <Card.Body className="bg-light">
          {/* <Form onSubmit={handleSubmit}> */}
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={4} controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={userProfile.firstName}
                  name="firstName"
                  onChange={handleChange}
                  disabled
                  readOnly
                />
              </Form.Group>

              <Form.Group as={Col} lg={4} controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={userProfile.lastName}
                  name="lastName"
                  onChange={handleChange}
                  disabled
                  readOnly
                />
              </Form.Group>
            
              <Form.Group as={Col} lg={4} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={userProfile.email}
                  name="email"
                  onChange={handleChange}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={4} controlId="fullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  value={userProfile.fullName}
                  name="fullName"
                  onChange={handleChange}
                  disabled
                  readOnly
                />
              </Form.Group>

              <Form.Group as={Col} lg={4} controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="title"
                  value={userProfile.title}
                  name="title"
                  onChange={handleChange}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              
            <Form.Group as={Col} lg={4} controlId="email">
                <Form.Label>Signature</Form.Label>
                {file && <br/>}
                {msg && <div>msg</div>}
                <img src={file}  style={{maxWidth: '400px', maxHeight: '150px'}} />
              </Form.Group>

            </Row>
            {!file && <input type="file" accept="image/jpeg" onChange={handleFileChange} /> }
            {file &&  <button  className="btn btn-outline-primary me-1 mb-1"
                onClick={handleRemove}
                value="Remove"
              > <FontAwesomeIcon icon={faTrashAlt} /> </button>}
          {/* </Form> */}
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileSettings;
