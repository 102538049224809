/* eslint-disable prettier/prettier */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import WelcomeCard from './WelcomeCard';
// import RecentActivityCard from './RecentActivityCard';
import { Helmet } from 'react-helmet';

const Home = () => {
 
  return (
    <>
    <Helmet>
      <title>ImmunoGenoApp Home</title>
    </Helmet>
     <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <WelcomeCard />
        </Col>
      </Row>  
     
      <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
           
          </Col>
        <Col md={6} xxl={6}>

        </Col>
      </Row>
    </>
  );
};

export default Home;
