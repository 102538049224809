/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
//import service from 'services/dataEntry-service';
import 'pages/patient/report-browser.scss';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import OrderService from 'services/order-service';
import CommonService from 'services/common-service';
import Select from 'react-select';
import Comments from 'pages/Comments';

const DataEntryForm = (prop) => {
  const [formData, setFormData] = useState({
    orderId: '',
    patientId: '',
    bloodHubOrderId: '',
    customOrderId: '',
    testRequest: '',
    patientFirstName: '',
    patientMiddleName: '',
    patientLastName: '',
    patientDOB: '',
    patientCode: 0,
    sampleNo: '',
    sampleDate: '',
    patientEthnicity: '',
    patientGender: '',
    patientABORH: '',
    requestingFacility: '',
    mrNumber: '',
    maNumber: '',
    collectionDate: '',
    collectionTime: '',
    institution: '',
    orderStatusId: '',
    testPerformed: '',
    referralReason2: '',
    referralReason3: '',
    diagnosis: ''
  });
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [commentList, setCommentList] = useState([]);
  
  useEffect(() => {
    OrderService.fetchOrderStatusList().then((orederList) => {
      setOrderStatusList(orederList);
    });

    OrderService.FetchOrderDetailsPageById(prop.orderId)
    .then((order) => {
         setFormData({
            orderId: order.orderId,
            patientId: order.patientId,
            bloodHubOrderId: order.bloodHubOrderId,
            customOrderId: order.customOrderId,
            institution: order.institution,
            testRequest: order.testRequest,
            testPerformed: order.testPerformed,
            sampleId: order.sampleId,
            mrNumber: order.mrNumber,
            collectionDate:order.collectionDate,
            orderStatusId: order.orderStatusId,
            maNumber: order.maNumber,
            patientFirstName: order.patientFirstName,
            patientMiddleName: order.patientMiddleName,
            patientLastName: order.patientLastName,
            patientDOB: order.patientDOB,
            patientGender: order.patientGender,
            patientEthnicity: order.patientEthnicity,
            aborh:order.aborh,
            requestingFacility:order.institution,
            referralReason2: order.referralReason2,
            referralReason3: order.referralReason3,
            diagnosis: order.diagnosis
      })
    });
    CommonService.getComments(prop.orderId)
    .then((commentList) => {
      setCommentList(commentList);
    })
  }, []);

  function onChangeOrderStatus (e) {
    setFormData({...formData, orderStatusId: e.value})
  }

  function onClickAddComment(e) {
    e.preventDefault();
    const comment = {
      commentId: 0,
      orderId: formData.orderId,
      comments: newComment
    }
    CommonService.addComment(comment)
    .then((commentList) => {
      setCommentList(commentList);
      setNewComment('');
    });
  }

  function onClickRemoveComment (commentId) {
    CommonService.deleteComment(commentId)
    .then((commentList) => {
      setCommentList(commentList);
      setNewComment('');
    });
  }

  // Save catalog
  function onClickSave(e) {
    e.preventDefault();

    const order = {
      orderId: formData.orderId,
      patientId: formData.patientId,
      testPerformed: formData.testPerformed,
      orderStatusId: formData.orderStatusId,
      testRequest: formData.testRequest,
      patientFirstName: formData.patientFirstName,
      patientLastName: formData.patientLastName,
      patientMiddleName: formData.patientMiddleName,
      mrNumber: formData.mrNumber,
      patientGender: formData.patientGender,
      patientDOB: formData.patientDOB,
      patientEthnicity: formData.patientEthnicity,
      aborh: formData.aborh,
      //collectionDate: clDate,
      institution: formData.requestingFacility,
      diagnosis: formData.diagnosis,
      referralReason2: formData.referralReason2,
      referralReason3: formData.referralReason3
    }

    // const order = {
    //   orderId: formData.orderId,
    //   testPerformed: formData.testPerformed,
    //   orderStatusId: formData.orderStatusId
    // }
    OrderService.updateOrderDetails(order).then(() => {
      toast(ConfirmationMessage("Order Saved", "The order is saved successfully!", "bg-success", "faCheckCircle"), {
        autoClose: true,
        //className: 'transparent',
        //style: {{background-color: transparent !important}},
        position: 'bottom-right',
        hideProgressBar: true,
        closeButton: false,
      });
    }).then(() => {
      location.href = `/orders/orders`;
    })
    .catch(err => console.log(err));
  }

  return (
    <>
     <Helmet>
        <title>Order Details</title>
      </Helmet>
        <Card className="h-100">
          <Card.Header>
      <Form onSubmit={onClickSave}>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={2} controlId="bloodHubOrderId">
                <Form.Label>
                BloodHubOrderId<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  disabled={true}
                  type="text"
                  placeholder="BloodHub Order Id"
                  value={formData.bloodHubOrderId}
                  name="bloodHubOrderId"
                 // onBlur= {getBloodHubDetails}
                //  onChange={(e)=>{setFormData({...formData, bloodHubOrderId: e.target.value})}}
                />
              </Form.Group>
              <Form.Group as={Col} lg={2} controlId="customOrderId">
                <Form.Label>
                CustomOrderId<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  disabled={true}
                  type="text"
                  placeholder="Custom Order Id"
                  value={formData.customOrderId}
                  name="customOrderId"
                  //onChange={({ target }) => handleChange(target.value)}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback> */}
              </Form.Group>
              <Form.Group as={Col} lg={3} controlId="patientFirstName">
                <Form.Label>
                Patient First Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient First Name"
                  value={formData.patientFirstName}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, patientFirstName: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={2} controlId="patientMiddleName">
                <Form.Label>
                Patient Middle Name
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Middle Name"
                  value={formData.patientMiddleName}
                  name="patientLastName"
                  onChange={(e) => { setFormData({ ...formData, patientMiddleName: e.target.value }) }}
                />
                </Form.Group>
              <Form.Group as={Col} lg={3} controlId="patientLastName">
                <Form.Label>
                Patient Last Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Last Name"
                  value={formData.patientLastName}
                  name="patientLastName"
                  onChange={(e) => { setFormData({ ...formData, patientLastName: e.target.value }) }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={2} controlId="patientDOB">
                <Form.Label>
                Patient Date Of Birth<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Date Of Birth"
                  value={formData.patientDOB}
                  name="patientDOB"
                  onChange={(e) => { setFormData({ ...formData, patientDOB: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={2} controlId="patientGender">
                <Form.Label>
                Patient Gender
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Sample No"
                  value={formData.patientGender}
                  name="patientGender"
                  onChange={(e) => { setFormData({ ...formData, patientGender: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={2} controlId="patientEthnicity">
                <Form.Label>
                Patient Ethnicity
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Ethnicity"
                  value={formData.patientEthnicity}
                  name="patientEthnicity"
                  onChange={(e) => { setFormData({ ...formData, patientEthnicity: e.target.value }) }}
                />
              </Form.Group>
             
              <Form.Group as={Col} lg={2} controlId="aborh">
                <Form.Label>
                ABORH
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="ABORH"
                  value={formData.aborh}
                  name="aborh"
                  onChange={(e) => { setFormData({ ...formData, aborh: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="requestingFacility">
                <Form.Label>
                Requesting Facility<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Requesting Facility"
                  value={formData.requestingFacility}
                  name="requestingFacility"
                  onChange={(e) => { setFormData({ ...formData, requestingFacility: e.target.value }) }}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback> */}
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4} controlId="testRequested">
                <Form.Label>
                Referral Reason 1 (Test Requested)<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                 // disabled={true}
                  type="text"
                  placeholder="Test Requested"
                  value={formData.testRequest}
                  name="patientFirstName"
                  onChange={(e) => { setFormData({ ...formData, testRequest: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="referralReason2">
                <Form.Label>
                Referral Reason 2
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                 // disabled={true}
                  type="text"
                  placeholder="Referral Reason2"
                  value={formData.referralReason2}
                  name="referralReason2"
                  onChange={(e) => { setFormData({ ...formData, referralReason2: e.target.value }) }}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="referralReason3">
                <Form.Label>
                Referral Reason 3
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  //disabled={true}
                  type="text"
                  placeholder="Referral Reason3"
                  value={formData.referralReason3}
                  name="referralReason3"
                  onChange={(e) => { setFormData({ ...formData, referralReason3: e.target.value }) }}
                />
              </Form.Group>
              </Row>
            <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={4} controlId="testPerformed">
                <Form.Label>
                Test Performed<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Test Performed"
                  value={formData.testPerformed}
                  name="testPerformed"
                  onChange={(e)=>{setFormData({...formData, testPerformed: e.target.value});}}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="mrNumber">
                <Form.Label>
                Sample Id# 2
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                 // disabled={true}
                  type="text"
                  placeholder="Patient Code"
                  value={formData.mrNumber}
                  name="mrNumber"
                  onChange={(e) => { setFormData({ ...formData, mrNumber: e.target.value }) }}
                />
              </Form.Group>

              <Form.Group as={Col} lg={2} controlId="maNumber">
                <Form.Label>
                MANumber
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  disabled={true}
                  type="text"
                  placeholder="MA Number"
                  value={formData.maNumber}
                  name="maNumber"
                  //onChange={({ target }) => handleChange(target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={2} controlId="orderStatus">
                <Form.Label>
                Order Status
                </Form.Label>
                <Select
                   // className={validateSchedule?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={orderStatusList}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={orderStatusList.find(item => item.value === formData.orderStatusId)}
                    onChange={onChangeOrderStatus}
                  />
              </Form.Group>
            </Row>
            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={onClickSave}
                //value="save"
                //disabled={disable}
                type="submit"
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
            </div>
            </Form>
            <br/>
            <hr/>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={12} controlId="patientFirstName">
                <Form.Label>
                  Comments
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  as="textarea" rows="2"
                  placeholder="Comments"
                  onChange={(e)=>{setNewComment(e.target.value);}}
                  value={newComment}
                  name="comment"
                />

              </Form.Group>
              <div className="text-start">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={onClickAddComment}
                //value="save"
                //disabled={disable}
                type="submit"
              >
                 Add
              </button>
              </div>
            </Row>
            <br/>
            <h3>Recent Comments</h3>
            <br/>
            {commentList && commentList.map(tr =>{
                   { if(tr.state !== "Delete"){
                    return ( <Comments
                    user = {tr.user}
                    comment = {tr.comments}
                    dateTime = {tr.date}
                    orderId = {tr.orderId}
                    commentId = {tr.commentId}
                    onClickRemoveComment = {onClickRemoveComment}
                    />
              )}}}
                )}
            

          </Card.Header>
        </Card>
     
    </>
  );
};

export default DataEntryForm;
