/* eslint-disable prettier/prettier */
import React from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
//import PageHeader from 'components/common/PageHeader';
import 'pages/patient/report-browser.scss';
import { Helmet } from 'react-helmet';

import vitalantPath from './vitalantPath.png';
import nybcPath from './NewYorkBloodCenterLogo.png';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Select from 'react-select';
//import DateFormat from '../../components/common/date-format';
//import DatePicker from 'react-datepicker';
// import { toast } from 'react-toastify';
// import ConfirmationMessage from 'components/common/ConfirmationMessage';
// import { faSave } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GenReport = ({ orderDetails, genTypeResults,
   comments, onCommentsChange, notes, onNotesChange,
           onPatientDOBChange, sampleId1, onSampleId1Change, onSampleId2Change, onRecieptDateChange,
           onRaceEthnicityChange, onTestRequestedChange, onTestPerformedChange,testRequested,testPerformed,
           orderStatus, divisions, selectedDivision, onChangeTestingDivision, validateDivision}) => {
    // const [comments, setComments] = useState();
  
  //useEffect(() => {
  //   var orderId = 48950;
  //   var orderPromise = OrderService.FetchOrderDetailsById(orderId);
  //   var testResultPromise = OrderService.fetchHEAgenTypeResults(orderId);
  //   Promise.all([orderPromise, testResultPromise]).then(([order, testResult]) => {
  //     setOrderDetails(order);
  //     setgenTypeResults(testResult.filter(a => a.reportTypeId == 1));
  //     setComments(testResult[0].comments);
  //     setNotes(testResult[0].notes);
  // });

      // setComments(defaultComments);
      // setNotes(defaultNotes);
 //}, []);

  // const onCommentsChange = (e) => {
  //   setComments(e.target.value)
  // };

  // const onClickSave= () => {
  //   //orderDetails.orderId
  //   var testResultTest = { OrderId : orderDetails.orderId, TestName: '', TestResult: '',  Comments : comments || genTypeResults[0].comments , Notes : notes || genTypeResults[0].notes};
  //   orderService.UpdateGenotypeTestReult(testResultTest).then(() => {      
  //     toast(ConfirmationMessage(`Genotype saved`, `Genotype data saved Successfully!`, "bg-success", "faCheckCircle"), {
  //       autoClose: true,
  //       //className: 'transparent',
  //       //style: {{background-color: transparent !important}},
  //       position: 'bottom-right',
  //       hideProgressBar: true,
  //       closeButton: false,
  //     });
  //   });
  // };


  return (
    <>
      <Helmet>
        <title>Draft Report</title>
      </Helmet>
      <Card className="h-100">
        <Card.Header>
          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="patientFirstName">
              {orderDetails.institution && orderDetails.institution.toLowerCase().includes("vitalant") ? (
                <img src={vitalantPath} alt="Logo" />
              ) : (
                <img src={nybcPath} alt="Logo" />
              )}
            </Form.Group>


            <Form.Group as={Col} lg={4} controlId="reportDate">
              <Form.Label>
                Report Date:
              </Form.Label>
              <Form.Control
                //isInvalid={!validateName}
                disabled={true}
                type="text"
                placeholder="Report Date"
                value={orderDetails.createdOn ? moment(orderDetails.createdOn).format('MM-DD-YYYY') : ''}
              //name="patientLastName"
              //onChange={(e) => onMiddleNameChange(e)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="patientFirstName">
              <Form.Label>
                Institution:
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Institution"
                value={orderDetails.institution}

              />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="collectionDate">
              <Form.Label>
                Collection Date:
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Collection Date"
                value={orderDetails.collectionDate ? moment(orderDetails.collectionDate).format('MM-DD-YYYY') : ''}

              />
            </Form.Group>
          </Row>
          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="address">
              <Form.Label>
                Address:
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Address"
                value={orderDetails.address}
              //name="patientFirstName"
              //onChange={(e) => onFirstNameChange(e)}
              />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="receiptDate">
              <Form.Label>
                Receipt Date:
              </Form.Label>
              <Form.Control
                //disabled={true}
                type="text"
                placeholder="MM-DD-YYYY"
                value={orderDetails.createdOn ? moment(orderDetails.createdOn).format('MM-DD-YYYY') : ''}
              //name="patientLastName"
              onChange={(e) => onRecieptDateChange(e)}
              />
              {/* <br/>
              <DatePicker
                  className="form-control"
                  placeholderText="MM-DD-YYYY"
                  dateFormat="MM-dd-yyyy"
                  //showTimeSelect
                  selected={orderDetails.createdOn ? moment(orderDetails.createdOn).format('MM-DD-YYYY') : new Date()}
                  onChange={(inputDate) => {
                    // const guessedTimezone = moment.tz.guess();
                    // let timezoneDateValue = moment(inputDate).tz(guessedTimezone).format(DATE_FORMAT);
                    // let dateValue = inputDate ? timezoneDateValue : '';
                    onRecieptDateChange(inputDate);
                  }}
                /> */}
            </Form.Group>
          </Row>

          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="sampleID1">
              <Form.Label>
                SampleID#1:
              </Form.Label>
              <Form.Control
                //disabled={true}
                type="text"
                placeholder="SampleID1"
                value={sampleId1}
              //name="patientFirstName"
              onChange={(e) => onSampleId1Change(e)}
              />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="order">
              <Form.Label>
                Order#:
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Order"
                value={orderDetails.bloodHubOrderId}
              //name="patientFirstName"
              //onChange={(e) => onFirstNameChange(e)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={4} controlId="sampleID2">
              <Form.Label>
                SampleID#2:
              </Form.Label>
              <Form.Control
                //disabled={true}
                type="text"
                placeholder="SampleID2"
                value={orderDetails.mrNumber}
              //name="patientLastName"
              onChange={(e) => onSampleId2Change(e)}
              />
            </Form.Group>
            <Form.Group as={Col} lg={4}>
                  <Form.Label>
                  Testing Division:
                  </Form.Label>
                  <Select
                    className={validateDivision?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={divisions}
                    placeholder='Select...'
                    //isDisabled={(orderStatus == 4 || orderStatus == 0) ? true : false}
                    //classNamePrefix="react-select"
                    value={selectedDivision}
                    onChange={e => onChangeTestingDivision(e)}
                  />
                  {!validateDivision && (
                    <span className="validation-error-msg">
                      Please select Division.
                    </span>
                  )}
                  </Form.Group>
            <Form.Group as={Col} lg={4} controlId="ma">
              <Form.Label>
                MA#:
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="MA"
                value={genTypeResults.length > 0 ? genTypeResults[0].manumber : 0}
              //name="patientFirstName"
              //onChange={(e) => onFirstNameChange(e)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={4} controlId="raceEthnicity">
              <Form.Label>
                Race/Ethnicity:
              </Form.Label>
              <Form.Control
                //disabled={true}
                type="text"
                placeholder="Race/Ethnicity"
                value={orderDetails.patientEthnicity}
              //name="patientFirstName"
              onChange={(e) => onRaceEthnicityChange(e)}
              />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="gender">
              <Form.Label>
                Gender:
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                placeholder="Gender"
                value={orderDetails.patientGender}
              //name="patientFirstName"
              //onChange={(e) => onFirstNameChange(e)}
              />
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId="dob">
              <Form.Label>
                DOB:
              </Form.Label>
               <Form.Control
                //disabled={true}
                type="text"
                placeholder="MM-DD-YYYY"
                value={orderDetails.patientDOB ? moment(orderDetails.patientDOB).format('MM-DD-YYYY') : ''}
              //name="patientFirstName"
              onChange={(e) => onPatientDOBChange(e)}
              />
            
            {/*<br/>
              <DatePicker
                  className="form-control"
                  placeholderText="MM-DD-YYYY"
                  dateFormat="MM-dd-yyyy"
                  //showTimeSelect
                  selected={orderDetails.patientDOB ? moment(orderDetails.patientDOB).format('MM-DD-YYYY') : new Date()}
                  onChange={(inputDate) => {
                    // const guessedTimezone = moment.tz.guess();
                    // let timezoneDateValue = moment(inputDate).tz(guessedTimezone).format(DATE_FORMAT);
                    // let dateValue = inputDate ? timezoneDateValue : '';
                    onPatientDOBChange(inputDate);
                  }}
                />*/}
                </Form.Group>
              </Row>

          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="testRequested">
              <Form.Label>
                Test Requested:
              </Form.Label>
              <Form.Control
                //disabled={true}
                type="text"
                placeholder="Test Requested"
                value={testRequested}
              //name="patientLastName"
              onChange={(e) => onTestRequestedChange(e)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2 g-2">
            <Form.Group as={Col} lg={8} controlId="testPerformed">
              <Form.Label>
                Test Performed:
              </Form.Label>
              <Form.Control
                //disabled={true}
                type="text"
                placeholder="Test Performed"
                value={testPerformed}
              //name="patientFirstName"
              onChange={(e) => onTestPerformedChange(e)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2 g-2">
            <Form.Label>Comments:</Form.Label>
            {/* <textarea
                value={comments}
                onChange={(e) => onCommentsChange(e)}
              /> */}
            <Form.Control
              //isInvalid={!validateName}
              type="textare"
              //placeholder="Test Performed"
              //defaultValue={genTypeResults.length > 0 ? genTypeResults[0].comments : ''}
              value={comments || genTypeResults.length > 0 ? comments || genTypeResults[0].finalComments : ''}
              //name="patientFirstName"
              disabled = {(orderStatus == 4 || orderStatus == 0) ? true : false}
              onChange={(e) => onCommentsChange(e)}
            />
          </Row>
          <Row className="mb-2 g-2">
            <Form.Label>Notes:</Form.Label>
            {/* <textarea
                value={notes}
                onChange={() => {onNotesChange}}
              /> */}
               <Form.Control
                  //isInvalid={!validateName}
                  type="textare"
                  //placeholder="Test Performed"
                  //defaultValue={genTypeResults.length > 0 ? genTypeResults[0].notes : ''}
                  value={notes || genTypeResults.length > 0 ? notes || genTypeResults[0].finalNotes : ''}
                  //name="patientFirstName"
                  disabled = {(orderStatus == 4 || orderStatus == 0) ? true : false}
                  onChange={(e) => onNotesChange(e)}
              />
            </Row>
            {/* <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={() => onClickSave()}
                value="Save"
                //type="submit"
                disabled={interpretation.approvedBy != null ? true : false}
              >
                <FontAwesomeIcon icon={faSave} /> Save Genotype Test Result
              </button>
            </div> */}
    </Card.Header>
</Card>

    </>
  );
};

GenReport.propTypes = {
  orderDetails: PropTypes.arrayOf(PropTypes.object),
  genTypeResults: PropTypes.arrayOf(PropTypes.object),
  //interpretation: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.arrayOf(PropTypes.object),
  onCommentsChange: PropTypes.arrayOf(PropTypes.object),
  notes: PropTypes.arrayOf(PropTypes.object),
  onNotesChange: PropTypes.arrayOf(PropTypes.object),
  orderStatus: PropTypes.arrayOf(PropTypes.object),
  onPatientDOBChange: PropTypes.arrayOf(PropTypes.object),
  onSampleId1Change: PropTypes.arrayOf(PropTypes.object),
  onSampleId2Change: PropTypes.arrayOf(PropTypes.object),
  onRecieptDateChange: PropTypes.arrayOf(PropTypes.object),
  onRaceEthnicityChange: PropTypes.arrayOf(PropTypes.object),
  onTestRequestedChange: PropTypes.arrayOf(PropTypes.object),
  onTestPerformedChange: PropTypes.arrayOf(PropTypes.object),
  sampleId1: PropTypes.arrayOf(PropTypes.object),
  divisions: PropTypes.arrayOf(PropTypes.object),
  selectedDivision: PropTypes.arrayOf(PropTypes.object),
  onChangeTestingDivision: PropTypes.arrayOf(PropTypes.object),
  testRequested: PropTypes.arrayOf(PropTypes.object),
  testPerformed: PropTypes.arrayOf(PropTypes.object),
  validateDivision: PropTypes.arrayOf(PropTypes.object),
};

export default GenReport;
