/* eslint-disable react/jsx-key */

/* eslint-disable prettier/prettier */

import React, {useState, useContext, useEffect } from 'react';

import NavbarDropdown from './NavbarDropdown';

// import catalogService from 'services/catalog-service';

import {

  reportsRoutes,

  // orderRoutes,

  // dataEntryRoutes,

  documentationRoutes,

  orderManagementRoutes,

  reportManagementRoutes,

  servicesRoutes,

  settingsRoutes

} from 'components/navbar/routes';

import { Dropdown } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import AppContext from 'context/Context';

import DocumentationDropdown from './DocumentationDropdown';

import ServicesDropdown from './ServicesDropdown';

import authService from 'services/auth-service';

import {setServicesRoutes, setSettingsRoutes, setOrderManagementRoutes, setReportManagementRoutes} from 'components/navbar/helper';

const NavbarTopDropDownMenus = () => {

 

  const [showServicesRoutes, setShowServicesRoutes] = useState([]);

  const [showSettingsRoutes, setShowSettingsRoutes] = useState([]);

  const [showOrderManagementRoutes, setShowOrderManagementRoutes] = useState([]);

  const [showReportManagementRoutes, setShowReportManagementRoutes] = useState([]);
 

  useEffect(() => {        

        setShowServicesRoutes(setServicesRoutes(servicesRoutes));

        setShowSettingsRoutes(setSettingsRoutes(settingsRoutes));

        setShowOrderManagementRoutes(setOrderManagementRoutes(orderManagementRoutes));

        setShowReportManagementRoutes(setReportManagementRoutes(reportManagementRoutes));
  }, []);

 

  const {

    config: { navbarCollapsed, showBurgerMenu },

    setConfig

  } = useContext(AppContext);

 

  const handleDropdownItemClick = () => {

    if (navbarCollapsed) {

      setConfig('navbarCollapsed', !navbarCollapsed);

    }

    if (showBurgerMenu) {

      setConfig('showBurgerMenu', !showBurgerMenu);

    }

  };  

 

  return (

    <>    

   

      {reportsRoutes &&

        <Dropdown.Item

        key={reportsRoutes.children[0].name}

        as={Link}

        className="nav-link fw-semi-bold"

        to={reportsRoutes.children[0].to}

        onClick={handleDropdownItemClick}

        >

        {reportsRoutes.children[0].name}

        </Dropdown.Item>}

 

        {/* {orderRoutes &&

        <Dropdown.Item

        key={orderRoutes.children[0].name}

        as={Link}

        className="nav-link fw-semi-bold"

        to={orderRoutes.children[0].to}

        onClick={handleDropdownItemClick}

        >

        {orderRoutes.children[0].name}

        </Dropdown.Item>} */}

 

        {/* {dataEntryRoutes &&

        <Dropdown.Item

        key={dataEntryRoutes.children[0].name}

        as={Link}

        className="nav-link fw-semi-bold"

        to={dataEntryRoutes.children[0].to}

        onClick={handleDropdownItemClick}

        >

        {dataEntryRoutes.children[0].name}

        </Dropdown.Item>} */}

     

 

      {(showServicesRoutes.length || showServicesRoutes.children) &&

      <NavbarDropdown title={servicesRoutes.label}>

        {showServicesRoutes.map(route =>  {        

           return (              

              <ServicesDropdown items={route} />        

             )

        })}

      </NavbarDropdown>}


      {(showOrderManagementRoutes.length || showOrderManagementRoutes.children) &&

<NavbarDropdown title={orderManagementRoutes.label}>

  {orderManagementRoutes.children.map(route =>  {        

  if ((!route.permissions && !route.to && route.children)

      || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')

      || (route.permissions && route.permissions.length && authService.hasOneOfPermissions(route.permissions))) { return (

    <Dropdown.Item

      key={route.name}

      as={Link}

      className={route.active ? 'link-600' : 'text-500'}

      to={route.to}

      onClick={handleDropdownItemClick}

    >

      {route.name}

    </Dropdown.Item>

      )}}

  )}

</NavbarDropdown>}


{(showReportManagementRoutes.length || showReportManagementRoutes.children) &&

<NavbarDropdown title={reportManagementRoutes.label}>

  {reportManagementRoutes.children.map(route =>  {        

  if ((!route.permissions && !route.to && route.children)

      || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')

      || (route.permissions && route.permissions.length && authService.hasOneOfPermissions(route.permissions))) { return (

    <Dropdown.Item

      key={route.name}

      as={Link}

      className={route.active ? 'link-600' : 'text-500'}

      to={route.to}

      onClick={handleDropdownItemClick}

    >

      {route.name}

    </Dropdown.Item>

      )}}

  )}

</NavbarDropdown>}
      
      {(showSettingsRoutes.length || showSettingsRoutes.children) &&

<NavbarDropdown title={settingsRoutes.label}>

  {settingsRoutes.children.map(route =>  {        

  if ((!route.permissions && !route.to && route.children)

      || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')

      || (route.permissions && route.permissions.length && authService.hasOneOfPermissions(route.permissions))) { return (

    <Dropdown.Item

      key={route.name}

      as={Link}

      className={route.active ? 'link-600' : 'text-500'}

      to={route.to}

      onClick={handleDropdownItemClick}

    >

      {route.name}

    </Dropdown.Item>

      )}}

  )}

</NavbarDropdown>}


     

      <NavbarDropdown title={documentationRoutes.label}>

        <DocumentationDropdown routes={documentationRoutes.children} />      

      </NavbarDropdown>

   </>

  );

};

 

export default NavbarTopDropDownMenus;