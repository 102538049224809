/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Card, Row, Col, Breadcrumb, Spinner, Form, Button } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
//import service from 'services/catalog-service';
import { settings } from '../../config';
import { getItemFromStore } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';
//import SoftBadge from 'components/common/SoftBadge';
import './report-browser.scss';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
//import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChartBar } from '@fortawesome/free-solid-svg-icons';
import PatientService from 'services/patient-service';
import DateFormat from '../../components/common/date-format';
import DatePicker from 'react-datepicker';
//import moment from 'moment-timezone';

//const DATE_FORMAT = 'MM-DD-YYYY';
const PatientSearch = () => {
  const [patientsDetails, setPatientsDetails] = useState([]);
  const [formData, setFormData] = useState({
    patientFirstName: null,
    //patientMiddleName: null,
    patientLastName: null,
    patientDOB:null,
    bloodHubOrderId: null,
    mrNumber: null
    // sampleDate: null,
    // patientEthnicity: null,
    // aborh: null   
    
  });
  const [validateFirstName, setValidateFirstName] = useState(true);
  const [validateLastName, setValidateLastName] = useState(true);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  const [isLoading, setIsLoading] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [selectedDOBDate, setSelectedDOBDate] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    if(formData.patientFirstName != null)
    {
      if(formData.patientFirstName.length < 3)
      {
        setValidateFirstName(false);
        return;
      }
      else
      {
        setValidateFirstName(true);
      }
    }
    
    if(formData.patientLastName != null)
    {
      if(formData.patientLastName.length < 3)
      {
        setValidateLastName(false);
        return;
      }
      else
      {
        setValidateLastName(true);
      }
    }    
    setValidateFirstName(true);
    setValidateLastName(true);
    setIsShow(true);
    setIsLoading(true);

    PatientService.fetchPatientDetails(formData)
      .then(res => {
        
        setPatientsDetails(res);
        setIsLoading(false);
      })
      .catch(err => {        
        setIsLoading(false);
        console.log(err);
      });
  };

  const onFirstNameChange = (e) => {
    setFormData({...formData, patientFirstName: e.target.value == "" ? null : e.target.value.toString().toLowerCase()});   
  };
  const onLastNameChange = (e) => {
    setFormData({...formData, patientLastName: e.target.value == "" ? null : e.target.value.toString().toLowerCase()});   
  };
  const onDateOfBirthChange = (date) => {
    setSelectedDOBDate(date)
    setFormData({ ...formData, patientDOB: date });
  };
  const onBloodHubOrderIdChange = (e) => {
    setFormData({...formData, bloodHubOrderId: e.target.value == "" ? null : e.target.value.toString().toLowerCase()});   
  };
  const onMRNumberChange = (e) => {
    setFormData({...formData, mrNumber:  e.target.value == "" ? null : e.target.value.toString().toLowerCase()});   
  };

  
  const reportBrowserdesc = "Sample Search";

  return (
    <>
      <Helmet>
        <title>Sample Search</title>
      </Helmet>
      <PageHeader title="Sample Search" className="mb-2" col="12">
      <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                Sample Search
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                header = "Sample Search"
                body = {reportBrowserdesc}
              />
          </Col>
          </Row>
      </PageHeader>
        <Card className="h-100">
          <Card.Header>
      <Form onSubmit={handleSubmit}>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4} controlId="patientFirstName">
                <Form.Label>
                Sample ID#1 First Name
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient First Name"
                  //value={roleName}
                  //name="patientFirstName"
                  onChange={(e) => onFirstNameChange(e)}
                />
                {!validateFirstName && (
                    <span className="validation-error-msg">
                      please enter 3 or more characters.
                    </span>
                  )}
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="patientLastName">
                <Form.Label>
                Sample ID#1 Last Name
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Patient Last Name"
                  //value={roleName}
                  //name="patientLastName"
                  onChange={(e) => onLastNameChange(e)}
                />
                {!validateLastName && (
                    <span className="validation-error-msg">
                      please enter 3 or more characters.
                    </span>
                  )}
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="patientDOB">
                <Form.Label>
                Sample ID#1 Date Of Birth
                </Form.Label><br/>
                <DatePicker
                  className="form-control"
                  placeholderText="MM-DD-YYYY"
                  dateFormat="MM-dd-yyyy"
                  //showTimeSelect
                  selected={selectedDOBDate}
                  onChange={(inputDate) => onDateOfBirthChange(inputDate)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4} controlId="bloodHubOrderId">
                <Form.Label>
                Blood Hub OrderId
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Blood Hub OrderId"
                  //value={roleName}
                  //name="patientFirstName"
                  onChange={(e) => onBloodHubOrderIdChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="mrNumber">
                <Form.Label>
                Sample ID#2 (MR Number)
                </Form.Label>
                <Form.Control
                  //isInvalid={!validateName}
                  type="text"
                  placeholder="Sample ID#2 (MR Number)"
                  //value={roleName}
                  //name="patientLastName"
                  onChange={(e) => onMRNumberChange(e)}
                />
              </Form.Group>
            </Row>
            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                //onClick={onClickSearch}
                value="Search"
                type="submit"
                //disabled={disable}
              >
                <FontAwesomeIcon icon={faSave} /> Search
              </button>
            </div>
          </Form>
          </Card.Header>
        </Card>
        { isShow ? 
          isLoading ? <div className='text-center'><Spinner animation="border" /></div> :
          <>
            <AdvanceTableWrapper
              columns={columns}
              data={patientsDetails}
              sortable
              pagination
              perPage={configState.gridRowsCount}
            >
              <Card className="h-100">
                <Card.Header>
                  <Row className="flex-between-center">
                    <Col xs={9} sm={6} lg={3}>
                      <AdvanceTableSearchBoxPlug table placeholder="Search" />
                    </Col>
                    <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                      
                    </Col>
                  </Row>
                </Card.Header>          
                  <Card.Body className="p-0">
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle"
                    tableProps={{
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                  </Card.Body>                
                <Card.Footer>
                  <AdvanceTableFooter
                    rowCount={patientsDetails.length}
                    table
                    rowInfo
                    navButtons
                  />
                </Card.Footer>                
              </Card>
              <br />
            </AdvanceTableWrapper>
          </>  : <div></div>
          }
    </>
  );
};

const columns = [
 
  {
    accessor: 'orderId',
    Header: 'Order Id',
    // Cell: rowData => (
    //   <a
    //     href={`${window.location.origin}/boldReports/${rowData.row.original.catalogId}`}
    //     className="text-primary fw-semi-bold"
    //   >
    //     {rowData.row.original.catalogName}
    //   </a>
    // )
  },
  {
    accessor: 'patientFirstName',
    Header: 'First Name',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'patientMiddleName',
    Header: 'Middle Name',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'patientLastName',
    Header: 'Last Name',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'bloodHubOrderId',
    Header: 'BloodHubOrderId',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start cell-description'
    }
  },
  {
    accessor: 'mrNumber',
    Header: 'MRNumber',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start cell-description'
    }
  },
  {
    accessor: 'patientEthnicity',
    Header: 'Ethnicity',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start cell-description'
    }
  },
  {
    accessor: 'testRequest',
    Header: 'Test Request',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start cell-description'
    }
  },
  {
    accessor: 'institution',
    Header: 'Facility',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start cell-description'
    }
  },
  {
    accessor: 'patientGender',
    Header: 'Gender',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start cell-description'
    }
  },
  {
    accessor: 'patientDOB',
    Header: 'DOB',
    Cell: rowData => (
      <DateFormat format="MM-DD-YYYY" date={rowData.row.original.patientDOB} />
    )
  },
  // {
  //   accessor: 'sampleId',
  //   Header: 'Sample No',
  //   headerProps: {
  //     className: 'text-start'
  //   },
  //   cellProps: {
  //     className: 'text-start'
  //   }
  // },
  {
    accessor: 'sampleDate',
    Header: 'Sample Date',
    Cell: rowData => (
      <DateFormat format="MM-DD-YYYY" date={rowData.row.original.sampleDate} />
    )
  },
  {
    accessor: 'none',
    Header: '',
    // eslint-disable-next-line
     Cell: rowData => (
          <Button type="button" variant="outline-primary" className="me-1 mb-1" size="sm"  
                  // onClick={() => onClickLaunch(
                  //   rowData.row.original.orderId
                  // )}
                >
                  <FontAwesomeIcon icon={faChartBar} />
                </Button>
     )
}  
];

export default PatientSearch;
