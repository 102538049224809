/* eslint-disable prettier/prettier */
// import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import Enum from 'common/enum';
import { useEffect } from 'react';
//import { useState, useEffect } from 'react';
import userService from 'services/user-service';

export const userProfile = () => {
  const { instance } = useMsal();
  //var { checkRoles, setCheckRoles } = useState();
  var profile = JSON.parse(localStorage.getItem(Enum.CACHED_USER_PROFILE));
  //var checkRoles = checkUserRolesPermission(profile);
  useEffect(() => {
  console.log("Check Roles");
  if(profile){
  userService.checkUserRolesPermission(profile.userId, profile.roles).then(result =>{
      
    if(!result) {
      const account = instance.getAllAccounts();
      const globalUserId = account[0]?.localAccountId;
      if (globalUserId) {
        userService.getUserProfile(globalUserId).then(x => {
          localStorage.removeItem(Enum.CACHED_USER_PROFILE);
          localStorage.setItem(
            Enum.CACHED_USER_PROFILE,
            JSON.stringify(x)
          );
          return x;
        });
      }
    }
    // else{
    //     return new Promise( (resolve) => { 
    //       resolve (profile);
    //     });
    // }
  });
}
  profile = JSON.parse(localStorage.getItem(Enum.CACHED_USER_PROFILE));
}, []);

    
  if (profile === null) {
    const account = instance.getAllAccounts();
    const globalUserId = account[0]?.localAccountId;
    if (globalUserId) {
      return userService.getUserProfile(globalUserId).then(x => {
        localStorage.removeItem(Enum.CACHED_USER_PROFILE);
        localStorage.setItem(
          Enum.CACHED_USER_PROFILE,
          JSON.stringify(x)
        );
        return x;
      });
    }
  } // end of if
  else{
    return new Promise( (resolve) => { 
      resolve (profile);
    });
  }
     

};

// export const  checkUserRolesPermission=(profile)=>{
   
//   return userService.checkUserRolesPermission(profile.userId, profile.roles);
// }

export const  refreshUserProfile=()=>{
  const userProfile = JSON.parse(localStorage.getItem(Enum.CACHED_USER_PROFILE));
  const globalUserId = userProfile.globalId;  
  return this.getUserProfile(globalUserId).then(x => {
      localStorage.removeItem(Enum.CACHED_USER_PROFILE);
      localStorage.setItem(Enum.CACHED_USER_PROFILE, JSON.stringify(x));
  });
}
