/* eslint-disable prettier/prettier */
import './globals'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './authConfig';
msalInstance
  .handleRedirectPromise()
  .catch(error => {
    console.error(error);
  }); // end of handleRedirectPromise
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Main>
        <App />
      </Main>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('main')
  
);
