/* eslint-disable prettier/prettier */
//import { Card } from 'react-bootstrap';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
//import { useParams } from 'react-router-dom';
import './BoldReport.css';
import IGAppBoldReport from './igapp_bold_report';

const HEABoldReports = () => {
  const [reportPathIGApp] = useState('/IGApp/IGApp_HEA_Report');
  return (
    <>
       <Helmet>
        <title>HEA Final Report</title>
      </Helmet>
      <IGAppBoldReport reportPathIGApp = {reportPathIGApp}/>
    </>
  );
};

export default HEABoldReports;
