/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import FalconCloseButton from 'components/common/FalconCloseButton';
//import Select from 'react-select';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

  const Comments = (prop) => {
    const [showConfirmTarget, setShowConfirmTarget] = useState(false);
    //const [validateTargetRange, setValidateTargetRange] = useState(false);
    //const [validateTargetEnd, setValidateTargetEnd] = useState(false);
    const [comments, setComments] = useState({
      serialId: 0,
      orderId: 0,
      user: '',
      dateTime: '',
      comment: '',
      state: '',
      commentId: 0
    });

    useEffect(() => {
        setComments ({...comments,
       serialId: prop.serialId,
       orderId: prop.orderId,
       user: prop.user,
       dateTime: prop.dateTime,
       comment: prop.comment,
       state: prop.state,
       commentId: prop.commentId
      });
    }, []);
    const onClickTargetCancel = () => setShowConfirmTarget(false);
    const onClickDeleteComment = () => { setShowConfirmTarget(false); deleteAssignedTarget(); }
  
  function onClickRemovePermission (e) {
    e.preventDefault();
    setShowConfirmTarget(true);
  }
  function deleteAssignedTarget() {
      prop.onClickRemoveComment(prop.commentId);
  }

    return (
      <>
            <Row>
                <Form.Group as={Col} lg={6} controlId="path">
                <Form.Label>{comments.comment}</Form.Label>
                 
                  
                  {/* <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} lg={3} controlId="path">
                <Form.Label>{comments.user}</Form.Label> <Form.Label>{comments.dateTime}</Form.Label>
                </Form.Group>
                {/* <Form.Group as={Col} lg={6} controlId="resource">
                </Form.Group> */}
                
                <Form.Group as={Col} lg={2} controlId="name" className='text-end'>
                {/* {showSave && (
                  <button className="btn btn-outline-primary me-1 mb-1 mt-2" type="button" onClick={onClickTargetSave} >
                  <FontAwesomeIcon icon={faSave} />
                  </button>)
                } */}
                <button className="btn btn-outline-primary" type="button"  onClick={onClickRemovePermission}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                </Form.Group>

            </Row>
            <br/>

        <Modal show={showConfirmTarget} onHide={onClickTargetCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Delete </Modal.Title>
          <FalconCloseButton onClick={onClickTargetCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the comment?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickDeleteComment}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickTargetCancel} >Cancel</Button> 
        </Modal.Footer>
        </Modal>

  </>
    )
}

  export default Comments;
