/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import {Row, Col, Breadcrumb, Tab, Tabs, Card } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { useParams } from "react-router-dom";
import OrderDetails from './orderDetails';
import DraftReport from './draftReport';

const OrderDeatilsTab = () => {
    let params = useParams();
    const orderId = params.orderId;

    useEffect(() => {

    }, []);
   return (
    <>
        <PageHeader title="Orders" className="mb-2" col="12">
            <Row>
              <Col className="text-start mt-1">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="/orders/orders">Open Orders</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                  Order Details
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
        </PageHeader>

        <Row className="mb-3 g-3">
            <Col md={12}>
                <Card>
                    <Card.Header>
                        <Tabs variant='pills' defaultActiveKey="order" id="uncontrolled-tab-example" className='mb-3'>
                            {/* <Tab eventKey="patient" title="Patient Details" className='border p-3'>
                                <PatientDetails orderId = {orderId}/>
                            </Tab> */}
                            <Tab eventKey="order" title="Order Details" className='border p-3'>
                                <OrderDetails orderId = {orderId}/>
                            </Tab>
                            <Tab eventKey="testResult" title="Test Result Details" className='border p-3'>
                              <DraftReport oid = {orderId}/>
                            </Tab>
                        </Tabs>
                    </Card.Header>
                </Card>
            </Col>
        </Row>
    </>
    );
 }
  
  export default OrderDeatilsTab;
  