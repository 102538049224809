/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Breadcrumb } from 'react-bootstrap';
import userService from '../../services/user-service';
//import catalogService from '../../services/catalog-service';
import Select from 'react-select';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import PageHeader from 'components/common/PageHeader';
//import notificationService from '../../services/notification-service';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import { Helmet } from 'react-helmet';
// const organizerOptions = [
//   { value: '', label: 'None', selected: true },
//   { value: 'auth', label: 'Auth' },
//   { value: 'ih', label: 'Immunohematology' },
//   { value: 'dg', label: 'Data Governance' },
//   { value: 'finance', label: 'Finance' }
// ];

const UserRoleNew = () => {
  //const profile = JSON.parse(localStorage.getItem('userProfile'));
  const [assignedPermissions, setAssignedPermission] = useState([]);
  const [roleName, setRoleName] = useState();
  // const [value, setValue] = useState([]);
  const [permissions, setPermissions] = useState([]);
 // const [tempPermissions, setTempPermissions] = useState([]);
  const [disable, setDisable] = useState(true);
  const fillMsg = 'Please fill in this field';
  const [validateName, setValidateName] = useState(true);
  const [validateMsg, setValidateMsg] = useState(fillMsg);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  useEffect(() => {
    const allPermissionsPromise = userService.fetchPermissions();
    const usersDropdownPromise = userService.getAllUsersDropdown();
    Promise.all([allPermissionsPromise, usersDropdownPromise]).then(
      ([allPermissions, allUsers]) => {
        allPermissions.forEach(item => {
          renameKey(item, 'permissionId', 'value');
          renameKey(item, 'title', 'label');
        });
        setUsersDropdown(allUsers);
        setPermissions(allPermissions);
      //  setTempPermissions(allPermissions);
      }
    );
    setDisable(true);
  }, []);

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  const handleSubmit = e => {
    e.preventDefault();
    const validate = validateFields();
    if (validate === false) {
      return;
    }
    if (e.target.value == 'save') {
      assignedPermissions.forEach(item => {
        renameKey(item, 'value', 'permissionId');
        renameKey(item, 'label', 'permission');
        //renameKey(item, 'permission1', 'permission')
        item['title'] = '';            //required for permisison model in API
        item['rolePermissions'] = [];  //required for Permission model in API
      });
      selectedUsers.forEach(item => {
        renameKey(item, 'value', 'id');
        // renameKey(item, 'label', 'name');
      });
      var role = { name: roleName, users: selectedUsers };
      userService.createRoles(role).then(rol => {
        userService.AddRolePermissions(assignedPermissions, rol.id).then(() => {
          // toast('Role(s) Permission Saved Successfully!', {
          //   autoClose: true,
          //   className: 'toast-container success'
          // });
          toast(ConfirmationMessage("Role(s) Saved", "Role(s) Permission Saved Successfully!", "bg-success", "faCheckCircle"), {
            autoClose: true,
            //className: 'transparent',
            //style: {{background-color: transparent !important}},
            position: 'bottom-right',
            hideProgressBar: true,
            closeButton: false,
          });
          assignedPermissions.forEach(item => {
              renameKey(item, 'permissionId', 'value');
              renameKey(item, 'permission', 'label');
            });
          setDisable(true);
          
          // userService.fetchAllAssignedUsers(rol.id).then ((user) => {
          //   let reciepients = [];
          //   user.map(u => {reciepients.push(u.email);} )
          //   const messageBody = `You has been assigned to this Hemetrics roles ${roleName}.`;        
          //   const notificationRequest = { applicationName:"Hemetrics", type: "role", messageHeader: roleName, messageContent: messageBody, createdBy:profile.email, recipientEmailList: reciepients, recipientEmailAddress: ""};
            
          //   notificationService.AddNotificationToSpecificUser(notificationRequest)
          //   .then(() => {
          //     catalogService.refreshCatalogCache()
          //     .then(()=>{          
          //         window.location.href = '/admin/UserRoles';
          //     })              
          //   })            
          // });          
        }).then(() => {
          window.location.href = '/admin/UserRoles';
        });
      });
    }
  };

 

  const handleChange = value => {
    setRoleName(value);
    setDisable(false);
  };
  const [validateRoles, setValidateRoles] = useState(true);
  const validateFields = () => {
    if (roleName) {
      setValidateName(true);
    } else {
      setValidateMsg(fillMsg);
      setValidateName(false);
      return false;
    }
    if (assignedPermissions.length > 0) {
      setValidateRoles(true);
    } else {
      setValidateRoles(false);
      return false;
    }
    return true;
  };
  return (
    <>
      <Helmet>
        <title>New Roles</title>
      </Helmet>
      <Card>
        <PageHeader title="Add Roles" className="mb-2">
        <Breadcrumb>  
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/userroles">Manage Roles</Breadcrumb.Item>
          <Breadcrumb.Item active>New</Breadcrumb.Item>  
        </Breadcrumb>
        </PageHeader>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={8} controlId="roleName">
                <Form.Label>
                  Role Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!validateName}
                  type="text"
                  placeholder="Role Name"
                  //value={roleName}
                  name="roleName"
                  onChange={({ target }) => handleChange(target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group>
                  <Form.Label>
                  Assign permissions<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  className={validateRoles ? '' : 'validation-error'}
                  closeMenuOnSelect={false}
                  options={permissions}
                  placeholder="Select"
                  isMulti
                  //classNamePrefix="react-select"
                  value={assignedPermissions}
                  onChange={value => {
                    setAssignedPermission(value);
                    setDisable(false);
                  }}
                />
                {!validateRoles && (
                  <span className="validation-error-msg">
                    Please fill out this field.
                  </span>
                )}
            </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
            <Form.Group>
                  <Form.Label>
                  Users
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={usersDropdown}
                  placeholder="Select"
                  isMulti
                  //classNamePrefix="react-select"
                  value={selectedUsers}
                  onChange={value => {
                    setSelectedUsers(value);
                    setDisable(false);
                  }}
                />
            </Form.Group>
            </Row>
            
            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="save"
                disabled={disable}
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserRoleNew;
