/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import {
  Breadcrumb,
  Col,
  Row,
  Card,
  Form,
  Spinner
} from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import OrderService from 'services/order-service';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import DateFormat from '../../components/common/date-format';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

const SameDayHistory = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedTestCategoryId, setSelectedTestCategoryId] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);





  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);

  };


  const testCategory = [
    { value: '', label: 'All' },
    { value: '1', label: 'Immunohematology' },
    { value: '2', label: 'Genomics' },
  ];


  const orderStatusOptions = [
    { value: '', label: 'All' },
    { value: 'Order Created', label: 'Order Created' },
    { value: 'Testing', label: 'Testing' },
    { value: 'Draft Report', label: 'Draft Report' },
    { value: 'Complete', label: 'Complete' },
  ];

  useEffect(() => {
    const fetchSameDayHistory = async () => {
      try {
        setIsLoading(true); // Start loading
        const response = await OrderService.getSameDayHistoryRecords();
        if (response) {
          setOrderDetails(response);
          setFilteredOrders(response);
        }
        setIsLoading(false); // End loading
      } catch (error) {
        console.error("Error fetching same day history:", error);
        setIsLoading(false); // End loading in case of error
        if (error.response) {
          console.error('Server data:', error.response.data);
          console.error('Server status:', error.response.status);
        }
      }
    };


    fetchSameDayHistory();
  }, []);


  useEffect(() => {
    if (startDate || endDate || selectedTestCategoryId || selectedOrderStatus) {
      const filtered = orderDetails.filter(order => {
        const collectionDate = new Date(order.collectionDate);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        const dateFilter =
          (!start || collectionDate >= start) && (!end || collectionDate <= end);

        const testCategoryIdFilter =
          !selectedTestCategoryId ||  // This will be true for an empty string
          order.testCategoryId === Number(selectedTestCategoryId);

        // Add the orderStatusFilter here
        const orderStatusFilter =
          !selectedOrderStatus ||
          order.orderStatus === selectedOrderStatus;

        // Update the return condition to include orderStatusFilter
        return dateFilter && testCategoryIdFilter && orderStatusFilter;
      });

      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orderDetails);
    }
  }, [startDate, endDate, selectedTestCategoryId, selectedOrderStatus, orderDetails]);



  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const headers = [
      "OP 1", "OP 2", "Name", "Date of Birth", "BloodHub ID", "Referral Reason 1",
      "Referral Reason 2", "Referral Reason 3", "Collection Date", "Test Category",
      "Order Status", "Receipt Date", "Sample ID 2", "MA Number", "Patient Ethnicity",
      "Institution", "Referral Notes", "Subject Type", "Patient Gender",
      "Diagnosis", "Specimen Type", "Analyte",
      "Genotype", "Prelim Report Date", "Final Report Date 1", "Final Report Date 2", "Bill code(s)", "Comments", "RBCs for RNA (mL)", "WB for CHOP (mL)",
      "To IRL - Freeze", "To IRL - Test", "From IRL - Returned"
    ];
    worksheet.columns = headers.map(header => ({ header, key: header, width: 15 }));

    // Style headers
    worksheet.getRow(1).eachCell(cell => {
      cell.font = {
        name: 'Arial',
        size: 8,
        bold: true,
        color: { argb: 'FFFFFFFF' } // White color
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0E86D4' }, // Blue background color
      };
    });


    // Populate data
    filteredOrders.forEach(order => {
      worksheet.addRow([
        order.operator1,
        order.operator2,
        order.name,
        order.patientDOB,
        order.bloodHubOrderId,
        order.referralReason1,
        order.referralReason2,
        order.referralReason3,
        order.collectionDate.split('T')[0],
        order.testCategoryId === 1 ? 'Immunohematology' : order.testCategoryId === 2 ? 'Genomics' : '',
        order.orderStatus,
        order.receiptDate,
        order.sampleId2,
        order.maNumber,
        order.patientEthnicity,
        order.institution,
        order.referralNotes,
        order.subjectType,
        order.patientGender,
        order.diagnosis,
        order.specimenType,
        order.analyte,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""

      ]);
    });

    // Adjust for font and size
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      row.eachCell(cell => {
        cell.font = {
          name: 'Arial',
          size: 8,
        };
      });
    });



    workbook.xlsx.writeBuffer().then(function (buffer) {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);

      const currentDate = new Date();
      const formatMonth = (month) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthNames[month];
      };

      const formattedDate = `${formatMonth(currentDate.getMonth())}${String(currentDate.getDate()).padStart(2, '0')}${currentDate.getFullYear()}`;
      const formattedTime = `${String(currentDate.getHours()).padStart(2, '0')}${String(currentDate.getMinutes()).padStart(2, '0')}${String(currentDate.getSeconds()).padStart(2, '0')}`;
      link.download = `Exported_data_${formattedDate}_${formattedTime}.xlsx`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });


  };


  return (
    <>
      <Helmet>
        <title>Same Day History</title>
      </Helmet>
      <PageHeader title="Same Day History" className="mb-2" col="12">
        <Row>
          <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/orders/orders">Open Orders</Breadcrumb.Item>
              <Breadcrumb.Item active>Same day History</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-end mt-n2">
            <PopoverComponent
              header="Enterprise Data Governance"
              body="Same Day History"
            />
          </Col>
        </Row>
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={filteredOrders}
        sortable
        pagination
        perPage={10}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={12} sm={6} lg={2}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>

              <Col xs={12} sm={6} lg={8} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>

                <div style={{ width: '25%' }}>
                  <Form.Group
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px', flex: '1', fontSize: '0.85rem' }}
                    controlId="testCategory"
                  >
                    <Form.Label style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>
                      Test Category:
                    </Form.Label>
                    <Select
                      styles={{
                        container: (provided) => ({ ...provided, fontSize: '0.85rem', width: '10rem' }),
                        control: (provided) => ({ ...provided, padding: '4px 8px' })
                      }}

                      closeMenuOnSelect={true}
                      options={testCategory}
                      placeholder="All"
                      value={testCategory.find(item => item.value === selectedTestCategoryId)}
                      onChange={e => setSelectedTestCategoryId(e.value)}
                    />
                  </Form.Group>
                </div>
                <div style={{ width: '20%' }}>
                  <Form.Group
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: '1', fontSize: '0.85rem' }}
                    controlId="orderStatus"
                  >
                    <Form.Label style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>
                      Order Status:
                    </Form.Label>
                    <Select

                      styles={{
                        container: (provided) => ({ ...provided, fontSize: '0.85rem', width: '8rem' }),
                        control: (provided) => ({ ...provided, padding: '4px 8px' })
                      }}
                      closeMenuOnSelect={true}
                      options={orderStatusOptions}
                      placeholder='All'
                      value={orderStatusOptions.find(option => option.value === selectedOrderStatus)}
                      onChange={option => setSelectedOrderStatus(option ? option.value : '')}
                    />
                  </Form.Group>
                </div>

                <div style={{ width: '16%' }}>
                  <Form.Group
                    style={{ display: 'flex', alignItems: 'center', flex: '0 0 auto', fontSize: '0.85rem', width: 'auto' }} // Set flex to '0 0 auto' and adjust width as needed
                    controlId="fromDate"
                  >
                    <Form.Label style={{ marginRight: '5px' }}>
                      From:
                    </Form.Label>
                    <DatePicker
                      className="form-control"
                      style={{ fontSize: '0.85rem', padding: '4px 8px' }} // Set flexBasis to control initial width, remove flexGrow
                      placeholderText="MM-DD-YYYY"
                      dateFormat="MM-dd-yyyy"
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                    />
                  </Form.Group>
                </div>
                <div style={{ width: '15%', marginLeft: '0.5rem' }}>
                  <Form.Group
                    style={{ display: 'flex', alignItems: 'center', flex: '0 0 auto', fontSize: '0.85rem', width: 'auto' }} // Adjust width as needed
                    controlId="toDate"
                  >
                    <Form.Label style={{ marginRight: '5px' }}>
                      To:
                    </Form.Label>
                    <DatePicker
                      className="form-control"
                      style={{ fontSize: '0.85rem', padding: '4px 8px' }} // Set flexBasis to control initial width
                      placeholderText="MM-DD-YYYY"
                      dateFormat="MM-dd-yyyy"
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                    />
                  </Form.Group>
                </div>
                <div>
                  <button
                    className="btn btn-outline-primary"
                    style={{ flexShrink: 0, fontSize: '0.85rem', padding: '4px 12px', whiteSpace: 'nowrap', marginLeft: '1rem' }}
                    type="button"
                    onClick={clearFilters}
                  >
                    Clear
                  </button>
                </div>



              </Col>

              <Col xs={12} sm={6} lg={2} className="text-end marginTop">
                <button className="btn btn-outline-primary me-1 mb-1" style={{ fontSize: '0.85rem', padding: '4px 12px' }} type="button" onClick={exportToExcel}>Export to Excel</button>
              </Col>


            </Row>
          </Card.Header>

          <Card.Body className="p-0">

            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle"
                tableProps={{
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            )}
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={filteredOrders.length}
              table
              rowInfo
              navButtons
            />

          </Card.Footer>
        </Card>
        <br />
      </AdvanceTableWrapper>
    </>
  );
};

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'maNumber',
    Header: 'MA Number',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'patientDOB',
    Header: 'Date of Birth',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (
      <DateFormat format="MM-DD-YYYY" date={rowData.row.original.patientDOB} />
    )
  },
  {
    accessor: 'bloodHubOrderId',
    Header: 'BloodHub ID',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'referralReason1',
    Header: 'Referral Reason 1',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'collectionDate',
    Header: 'Collection Date',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (
      <DateFormat format="MM-DD-YYYY" date={rowData.row.original.collectionDate} />
    )
  },
  {
    accessor: 'receiptDate',
    Header: 'Receipt Date',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (
      <DateFormat format="MM-DD-YYYY" date={rowData.row.original.receiptDate} />
    )
  },
  {
    accessor: 'testCategoryId',
    Header: 'Test Category',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    // Use a custom Cell function to display TestCategoryName based on TestCategoryId
    Cell: rowData => (
      <span>
        {rowData.row.original.testCategoryId === 1
          ? 'Immunohematology'
          : rowData.row.original.testCategoryId === 2
            ? 'Genomics'
            : ''}
      </span>
    )
  },
  {
    accessor: 'orderStatus',
    Header: 'Order Status',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  }
];

export default SameDayHistory;
