/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Row, Col } from 'react-bootstrap';
import NavbarNavLink from './NavbarNavLink';

const DocumentationDropdown = ({ routes }) => {
  return ( 
    <>
      <Row style={{width: '300px'}}>
        <Col>
          <Nav className="flex-column">
            <div className= "mx-2"> 
              {routes.slice(0, 5).map(route => (
                route.externalLink ? (
                  <a 
                    key={route.name}
                    href={route.externalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link"
                  >
                    {route.name}
                  </a>
                ) : (
                  <NavbarNavLink key={route.name} route={route} />
                )
              ))}        
            </div>
          </Nav>
        </Col>
      </Row>  
    </>
  );
};

DocumentationDropdown.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      name: PropTypes.string.isRequired,
      to: PropTypes.string,
      externalLink: PropTypes.string, // Add this line
      children: PropTypes.array
    }).isRequired
  )
};

export default DocumentationDropdown;

