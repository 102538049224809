/* eslint-disable prettier/prettier */
// import queryString from 'querystring';
import stringParser from '../common/string-parser';
import BaseService from './base-service';
import Enum from '../common/enum';
class UserService extends BaseService {

    getAllUsersDropdown() {
        return this._apiGet({
            path: '/users/getAllUsersDropdown'
        })
    }

    // fetchUsers = (skip, take, sortType, roleId) => {
    //     const query = stringParser.prepareQueryString({ skip, take, sortType, roleId });
    //     return this._apiGet({
    //         path: '/users' + query
    //     })
    // }
    addSignature = (signature) => {
        return this._apiPostFile({
            path: '/users/signature/add',
            body: signature,           
        })
    }
    getIGSignature = (userId) => {
        return this._apiGetFile({
            path: `/users/signature/${userId}`,                       
        })
    }
    getSignature = () => {
        return this._apiGetFile({
            path: '/users/signature',                       
        })
    }
    removeSignature = () => {
        return this._apiPost({
            path: '/users/signature/remove'
        })
    }
    getUserIdByRole(roleId) {
        return this._apiGet({
            path: `/users/role/${roleId}`
        })
    }
   
    fetchManageUsers = (roleId) => {
        const query = stringParser.prepareQueryString({ roleId });
        return this._apiGet({
            path: '/users/manageUsers' + query
        })
    }

    fetchActiveUsers = (skip, take, sortType, roleId, isActive) => {
        const query = stringParser.prepareQueryString({ skip, take, sortType, roleId, isActive });
        return this._apiGet({
            path: '/users/getActive' + query
        })
    }

    searchUsers = (skip, take, sortType, roleId, keywords, isActive) => {
        const query = stringParser.prepareQueryString({ skip, take, sortType, roleId, keywords, isActive });
        return this._apiGet({
            path: '/users/search' + query
        })
    }

    deleteUser = (user) => {
        const params = {
            path: `/users/inactivate/${user.userId}`,
            data: user
        }

        return this._apiPut(params);
    }

    getUser = (userId) => {
        return this._apiGet({
            path: `/users/${userId}`
        })
    }

    saveUser = (user) => {
        const params = {
            path: `/users/${user.id}`,
            data: user
        }

        return this._apiPut(params);
    }

    addUserRoles = (userRoles, userId) => {
        return this._apiPost({
            path: `/users/${userId}/roles`,
            data: userRoles
        })
    }

    removeUserRoles = (userRoles, userId) => {
        return this._apiDelete({
            path: `/users/${userId}/roles`,
            data: userRoles
        })
    }

    fetchUserRoles = (userId) => {
        return this._apiGet({
            path: `/users/${userId}/roles`
        })
    }

    /**
     * Returns profile of current authenticated user
     */
    getUserProfile = (globalUserId) => {
        return this._apiGet({
            path: `/users/${globalUserId}/profile`,
        });
    }

    checkUserRolesPermission = (userId, userRoles) => {
        return this._apiPost({
            path: `/users/checkUserRolesPermission/${userId}`,
            data: userRoles
        });
    }

    getAllActiveUsersAsync = () => {
        return this._apiGet({
            path: `/users/getAllActiveUsersAsync`
        });
    }

    /**
     * Update current user profile
     */
    saveUserProfile = (user, globalUserId) => {
        return this._apiPut({
            path: `/users/${globalUserId}/profile`,
            data: user
        });
    }
    fetchAllRole = () => {
        return this._apiGet({
            path: `/users/allrolechoices`
        })
    }



    fetchManageRoles = () => {
        return this._apiGet({
            path: '/users/manageRoles'
        })
    }

    fetchAllRoles = () => {
        return this._apiGet({
            path: '/users/allRoles'
        })
    }

    getRole = (roleId) => {
        return this._apiGet({
            path: `/users/roles/${roleId}`
        })
    }
    
    fetchAllAssignedUsers = (roleId) => {
        return this._apiGet({
            path: `/users/notificationUsers/${roleId}`
        })
    }
    // saveRole = (role) => {
    //     if (role.id) {
    //         const params = {
    //             data: role,
    //             path: `/users/roles/${role.id}`
    //         }

    //         return this._apiPut(params);
    //     }

    //     const params = {
    //         data: role,
    //         path: `/users/roles`
    //     }

    //     return this._apiPost(params);
    // }

    deleteRole = (roleId) => {
        return this._apiDelete({
            path: `/users/roles/${roleId}`
        })
    }

    createRoles = (role) => {
        return this._apiPost({
            path: `/users/roles/createRoles`,
            data: role
        })
    }
    updateRoles = (role) => {
        return this._apiPost({
            path: `/users/roles/updateRoles`,
            data: role
        })
    }

    AddRolePermissions = (rolePermissions, roleId) => {
        return this._apiPost({
            path: `/users/roles/${roleId}/permissions`,
            data: rolePermissions
        })
    }

    addNewRolePermissions = (rolePermissions) => {
        return this._apiPost({
            path: `/users/newRoles`,
            data: rolePermissions
        })
    }

    removeRolePermissions = (rolePermissions, roleId) => {
        return this._apiDelete({
            path: `/users/roles/${roleId}/permissions`,
            data: rolePermissions
        })
    }

    fetchPermissions = () => {
        return this._apiGet({
            path: '/users/permissions'
        })
    }

    fetchUserPermissions = (roleId) => {
        return this._apiGet({
            path: `/users/permissions/${roleId}`
        })
    }

    // Search Azure AD users
    searchAzureActiveDirectoryUsers = (keyword) => {
        return this._apiGet({
            path: `/users/searchActiveDirectoryUsers?keyword=${keyword}`
        })
    }

    // Get Azure AD user with given id
    getAzureActiveDirectoryUser = (id) => {
        return this._apiGet({
            path: `/users/activeDirectoryUser?id=${id}`
        })
    }

    fetchUsersWithPermissions = (permissions) => {
        return this._apiGet({
            path: `/users/filterByPermissions?permissions=${permissions}`
        })
    }

    GetUsersByEmailId = emailId => {
      return this._apiGet({
          path: `/users/notificationUsersByEmail?emailId=${emailId}`
      })        
    }  
    refreshUserProfileCache=()=>{
        const userProfile = JSON.parse(localStorage.getItem(Enum.CACHED_USER_PROFILE));
        const globalUserId = userProfile.globalId;  
        return this.getUserProfile(globalUserId).then(userProfile => {
            localStorage.removeItem(Enum.CACHED_USER_PROFILE);
            localStorage.setItem(Enum.CACHED_USER_PROFILE, JSON.stringify(userProfile));
        });
    }

    updateEnterpriseView = (user) => {
        const params = {
            path: `/users/enterpriseView`,
            data: user
        }
        return this._apiPut(params);
    }
}

export default new UserService();