/* eslint-disable prettier/prettier */
//Report Viewer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-designer.min';
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.compatibility.min.css';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reportdesigner.compatibility.min.css';

//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';

import boldReportService from '../../services/boldReport-service';
import catalogService from '../../services/catalog-service';
import authService from 'services/auth-service';
import activeDirectoryService from 'services/activeDirectory-service';
import { Card } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { setRecentlyBrowsed } from 'helpers/utils';
import './BoldReport.css';
import DashboardReportPageHeader from '../common/dashboard-report-pageheader';
import { Helmet } from 'react-helmet';

const BoldReports = () => {
  let params = useParams();
  const catalogId = params.id;
  const userProfile = authService.getCurrentUserProfile();

  const isFirstRender = useRef(true);
  const [boldReportToken, setBoldReportToken] = useState('');
  const [boldReport, setBoldReport] = useState([]);
  const [reportPath, setReportPath] = useState('');
  const [owner, setOwner] = useState('');
  const [isFavorite, setIsFavorite] = useState(false);
  const [reportStatusVariant, setReportStatusVariant] = useState('');
  const [isPad, setIsPad] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    if (window.innerWidth < 1050 && window.innerWidth > 700) {
      setIsPad(true);
    }
    if (window.innerWidth < 700) {
      setIsMobile(true);
    }
    const boldReportTokenPromise = boldReportService.fetchBoldReportToken();
    const fullReportPromise = catalogService.findUserReport(
      userProfile.id,
      catalogId
    );

    if (isFirstRender.current) {
      isFirstRender.current = false;
      Promise.all([boldReportTokenPromise, fullReportPromise])
        .then(([boldReportTokenResponse, fullReportResponse]) => {
          setBoldReportToken(boldReportTokenResponse.token);
          setBoldReport(fullReportResponse);
          if (window.innerWidth < 1050) {
            if (fullReportResponse.mobilePath.trim() != '') {
              setReportPath(fullReportResponse.mobilePath);
            } else {
              setReportPath(fullReportResponse.path);
            }           
          } else {
            setReportPath(fullReportResponse.path);
          }
          
          //setIsFavorite(fullReportResponse.isFavorite);

          if (fullReportResponse.isFavorite === 1) {
            setIsFavorite(true);
          } else {
            setIsFavorite(false);
          }

          setRecentlyBrowsed(
            'Report',
            fullReportResponse.catalogName,
            `/boldReports/${catalogId}`
          );
          if (fullReportResponse.catalogOwnerEmail) {
            activeDirectoryService
              .getUserByEmail(fullReportResponse.catalogOwnerEmail)
              .then(user => {
                setOwner(user.displayName);
              });
          }
          if (fullReportResponse.reportStatus == 'In Validation') {
            setReportStatusVariant('warning');
          } else {
            setReportStatusVariant('success');
          }
        })
        .catch(error => {
          console.error(error.status);
          // if (error.status !== 200) {
          window.location.href = '/errors/501x';
          // }
        });
    } else {
      window.location.reload();
    }
  }, [catalogId, location]);

  const reportServiceUrl =
    'https://hemetrics-report.azurewebsites.net/reporting/reportservice/api/Viewer';
  const reportServerUrl =
    'https://hemetrics-report.azurewebsites.net/reporting/api/site/hemprod';

  return (
    <>
      <Helmet>
        <title>{boldReport.catalogName}</title>
      </Helmet>
      <div>
        <DashboardReportPageHeader
          isMobile = {isMobile}
          isPad = {isPad}
          catalogName = {boldReport.catalogName}
          reportStatus = {boldReport.reportStatus}
          reportStatusVariant = {reportStatusVariant}
          owner = {owner}
          description = {boldReport.description}
          type = {boldReport.type}
          lastModified = {boldReport.lastModified}
          roles = {boldReport.roles}
          catalogId = {catalogId}
          favoriteFlag = {isFavorite}
          />
      
        {boldReportToken && reportPath && (
          <Card className="h-100">
            <Card.Body className="p-0">
              {/* eslint-disable-next-line */}
             <BoldReportViewerComponent
                className = "mainBoldReport"
                id="reportviewer-container"
                reportServiceUrl={reportServiceUrl}
                reportServerUrl={reportServerUrl}
                serviceAuthorizationToken={boldReportToken}
                reportPath={reportPath}
              />
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};

export default BoldReports;
