/* eslint-disable prettier/prettier */
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import activeDirectoryService from '../../../services/activeDirectory-service';
import { Link } from 'react-router-dom';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Avatar from '../../../components/common/Avatar';
import { useMsal } from "@azure/msal-react";
// import authService from '../../../services/auth-service';
import {userProfile as profile} from 'common/user';

const ProfileDropdown = () => {
  const userProfile = profile(); //authService.getCurrentUserProfile();

  const [photo, setPhoto] = useState(null);
  const [initial, setInitial] = useState(null);

  useEffect(() => {
    //if (userProfile) {
      userProfile.then(x=>{
        const initial =
        x.firstName[0].toUpperCase() +
        x.lastName[0].toUpperCase();
        setInitial(initial);

      });
    //}
    GetProfilePhoto();
  }, []);

  function GetProfilePhoto() {
    activeDirectoryService.getUserProfilePhoto().then(o => {
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(o.data);
      setPhoto(blobUrl);
    });
  }
  const {instance} = useMsal();

  function onClickLogout() {
    
    sessionStorage.clear();
    // instance.logout();

    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    }).then(() => {
      console.log("Logout successful");
    });
  }

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <OverlayTrigger
          placement={'left'}
          overlay={<Tooltip id="myProfile">My Profile & Settings</Tooltip>}
        >
          
          <div className="theme-control-toggle-label">
        <Avatar src={photo} name={initial} />   
        </div>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item> */}

          <Dropdown.Item as={Link} to="/user/profile">
            My Profile 
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={onClickLogout}
            as={Link}
            to="/authentication/card/logout"
          > 
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
